import axios from '../../../axios-auth'
import axiosGlobal from 'axios'
import * as types from '../../types'
import router from '../../../router/index'
import axiosRefresh from '../../../axios-refresh'
import AWSSES from '../../../aws-ses'
import config from '../../../config'
// AWSSES.emailing(userData, 'accountDeleted')


export default {

  [types.SIGN_UP]: ({commit, dispatch}, userData) => {
    // console.log('SIGNUP')
    commit(types.MUT_AUTH_USER,{
      idToken: userData.idToken,
      userId: userData.localId,
    })
    // console.log('createUser')
    userData.userId = userData.localId
    // console.log('USERDATA: ')
    // console.log(userData)
    let localStorageInfo = {
      userId: userData.localId,
      idToken: userData.idToken,
      refreshToken: userData.refreshToken,
      keepLogged: userData.keepLogged,
      dateAdded: new Date(),
      lastModified: new Date(),
      lastLogin: new Date(),
      sendChinese:userData.sendChinese
    }
    if (!userData.keepLogged) {
      localStorageInfo.expiresIn = userData.expiresIn
    }
    // console.log("localStorageInfo : \n"+localStorageInfo)
    dispatch('auth/SET_LOCAL_STORAGE', localStorageInfo)
    dispatch('auth/SET_LOG_OUT_TIMER', {
      expiration: userData.expiresIn,
      idToken: userData.idToken
    })
    const storedUser = userData
    dispatch('auth/STORE_USER', storedUser)

  },
  [types.STORE_USER]: ({commit, dispatch, state}, userData) => {
    // console.log('STORE_USER')
    if (!state.idToken) {
      return
    }
    axiosGlobal.post('/users.json' + '?auth=' + state.idToken , userData)
    .then((res) => {
      // console.log('store user action')
      // console.log(res)
      dispatch('auth/FETCH_USER', userData.email)
    }).catch((err) => {
      // console.log(err)
      alert(err)
    })
  },

  [types.LOG_IN]: ({commit, dispatch}, userData) => {

    // console.log('LOG_IN')
    let cookie=""
    const cname="nextPath"
    var name = cname + "=";
    var ca = document.cookie.split(';');
    // console.log(ca, 'are all the cookies:D')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        // console.log(c.substring(name.length, c.length))
        cookie=c.substring(name.length, c.length);
        if (cookie.includes('"')){
          cookie=JSON.parse(cookie)
        }

      }
    }
    // console.log(cookie, "this is your cookie, were at login Action")

      commit(types.MUT_AUTH_USER,{
        idToken: userData.idToken,
        userId: userData.localId,
      })
      let localStorageInfo = {
        userId: userData.localId,
        idToken: userData.idToken,
        refreshToken: userData.refreshToken,
        keepLogged: userData.keepLogged,
        lastLogin: new Date()
      }
      if (!userData.keepLogged) {
        localStorageInfo.expiresIn = userData.expiresIn
      }
      dispatch('auth/SET_LOCAL_STORAGE', localStorageInfo)
      dispatch('auth/SET_LOG_OUT_TIMER', {
        expiration: userData.expiresIn,
        idToken: userData.idToken
      })
      dispatch('auth/FETCH_USER', {email:userData.email})

  },

  [types.FETCH_USER]: ({commit, state}, payload) => {
    // console.log('FETCH_USER')
    if (!state.idToken) {
      return
    }
    // console.log(payload);
    // axiosGlobal.get('users/'++ )
    axiosGlobal.get('/users.json' + '?auth=' + state.idToken+'&orderBy="userId"&equalTo="' + state.userId + '"')
    .then((res) => {

      // console.log('FETCH_USER action')
      // console.log(res.data)
      const data = res.data
      const users = []

      for (let key in data){
        const user = data[key]
        user.id = key
        users.push(user)
        if (state.userId === user.userId) {
          if (payload!=undefined && payload.email && user.email !== payload.email){
            axiosGlobal.patch('/users/'+ user.id +'.json'+ '?auth=' + state.idToken, {email:payload.email})
            user.email=payload.email
          }
          if (!user.lastLogin || new Date().getTime()-new Date(user.lastLogin).getTime() > 86400000){
            // console.log('post the lastLogin')
            axiosGlobal.patch('/users/'+ user.id +'.json'+ '?auth=' + state.idToken, {lastLogin:new Date()})
            .catch(err =>{
              console.log(err)
            })
          }
          commit(types.MUT_STORE_USER, user)
          if (user.id!=undefined){
            localStorage.setItem('user', user.id)
            localStorage.setItem('roleValue',user.roleValue)
          }
          let recentSeen=localStorage.getItem('recentSeen')
          // console.log('recentSeen');
          if (recentSeen!=undefined ){
            recentSeen=JSON.parse(recentSeen)
            for (var i = 0; i < recentSeen.data.length; i++) {
              if (user.roleValue<20){
                axiosGlobal.patch('/rSeen/'+recentSeen.data[i]+'.json',{user:user.id})
                .then(()=>{
                  console.log('patched the seen');
                })
              } else {
                axiosGlobal.delete('/rSeen/'+recentSeen.data[i]+'.json')
                .then(()=>{
                  console.log('deleted the rSeens');
                })
              }
            }
            localStorage.removeItem('recentSeen')
          }
        }
      }
      let cookie=""
      const cname="nextPath"
      var name = cname + "=";
      var ca = document.cookie.split(';');
      // console.log(ca)
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          // console.log(c.substring(name.length, c.length))
          cookie=c.substring(name.length, c.length);
          if (cookie.includes('"')){
            cookie=JSON.parse(cookie)
          }
        }
      }
      // console.log(cookie, 'this is the cookie from the fetch user');
      console.log('ROUTER:',router.currentRoute.query);
      if (router.currentRoute.query.next!=undefined){
        console.log('push to ',router.currentRoute.query.next);
        router.push(router.currentRoute.query.next)
      }
      if (cookie != ""){
        // console.log(cookie)
        // console.log('push to the cookie');

        router.push(cookie)

      } else {
        // console.log('there is no cookie found in the fetchuser action')
        router.push({name:'homepage'})
      }
        document.cookie = "nextPath=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    })
    .catch((err) => {
      console.log(err)

    })
  },
  [types.LOG_OUT]: ({commit,state}) => {
    // console.log('LOG OUT ACTION')
    localStorage.removeItem('idToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('expirationDate')
    localStorage.removeItem('roleValue')
    localStorage.removeItem('user')
    localStorage.setItem('keepLogged', false)
    commit(types.MUT_LOG_OUT)
    router.go()
  },
  [types.SET_LOG_OUT_TIMER]: ({commit, state, dispatch}, payload) => {
    // console.log('SET_LOG_OUT_TIMER')
    setTimeout(() => {
      const refreshToken = localStorage.getItem('refreshToken')
      if (refreshToken==null || refreshToken==undefined) {
        return
      }
      const keepLogged = localStorage.getItem('keepLogged')
        if (keepLogged == "true") {
          dispatch('auth/REFRESH_TOKEN', localStorage.getItem('refreshToken'))
        } else {
          const payloadToken = payload.idToken
          if (localStorage.getItem('idToken') === payloadToken){
            dispatch('auth/LOG_OUT')

          } else {
            const now = new Date()
            const expirationDate= new Date(localStorage.getItem('expirationDate'))
            const timeToNextCheck = Math.abs(expirationDate.getTime() - now.getTime())/1000
            dispatch('auth/SET_LOG_OUT_TIMER', {
              idToken: localStorage.getItem ('idToken'),
              expiration: timeToNextCheck
            })
          }
        }
    }, payload.expiration*1000) //
  },
  [types.REFRESH_TOKEN]: ({commit, dispatch}) => {
    // console.log('REFRESH_TOKEN: ')
    axiosRefresh.post('/token?key='+config.FIREBASE_API_KEY, {
      grant_type:  'refresh_token',
      refresh_token: localStorage.getItem('refreshToken')
    })
    .then((res)=> {
      // console.log('refreshTOKenRes:')
      // console.log(res)
      commit(types.MUT_REFRESH_USER,{
        idToken: res.data.id_token,
      })
      console.log('DO SET_LOCAL_STORAGE');
      dispatch('auth/SET_LOCAL_STORAGE', {
        idToken: res.data.id_token,
        expiresIn: res.data.expires_in,
        refreshToken: res.data.refresh_token
      })
      // dispatch('auth/SET_LOG_OUT_TIMER', {
      //   expiration: res.data.expires_in,
      //   idToken: res.data.id_token
      // })
      // console.log(res)
    })
    .catch((err)=>{ console.log (err)})
  },
  [types.SET_LOCAL_STORAGE]: ({commit}, payload) => {
    // console.log('SET_LOCAL_STORAGE')
    localStorage.setItem('idToken', payload.idToken)
    const now = new Date()
    let expirationDate = null
    if (payload.expiresIn) {
      expirationDate = new Date(now.getTime() + payload.expiresIn*1000)
      localStorage.setItem('expirationDate', expirationDate)
    }
    if (payload.refreshToken){
      localStorage.setItem('refreshToken', payload.refreshToken)
    }
    if (payload.keepLogged != undefined){
      localStorage.setItem('keepLogged', payload.keepLogged)
    }

  },
  [types.AUTO_LOG_IN]: ({commit,dispatch}     )   =>{
    console.log('AUTO_LOG_IN')
    const refreshToken = localStorage.getItem('refreshToken')
    if (!refreshToken) {
      // console.log('NO REFERSHTOKEN')
      return
    }
    const expirationDate = localStorage.getItem('expirationDate')
    if (expirationDate) {
      const now = new Date()
      if (now >= expirationDate){
        return
      }
    }
    axiosRefresh.post('/token?key='+config.FIREBASE_API_KEY, {
      grant_type:  'refresh_token',
      refresh_token: localStorage.getItem('refreshToken')
    }).then ((res)=>{
      // console.log('refreshToken:')
      // console.log(res.data)
      commit(types.MUT_AUTH_USER,{
        idToken: res.data.id_token,
        userId: res.data.user_id,
      })
      let localStorageInfo = {
        idToken: res.data.id_token,
        lastLogin: new Date(),
      }
      dispatch('auth/SET_LOCAL_STORAGE', localStorageInfo)
      dispatch('auth/SET_LOG_OUT_TIMER', {
        expiration: res.data.expires_in,
        idToken: res.data.id_token
      })
      dispatch('auth/FETCH_USER',{email:res.data.email})
    })
    .catch((err)=> {
      console.log(err)
    })
  },
  [types.SET_STORE]: ({commit, dispatch}, userData) => {
    // console.log('SIGNUP')
    commit(types.MUT_AUTH_USER,{
      idToken: userData.idToken,
      userId: userData.localId,
    })
  }
}
