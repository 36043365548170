import vue from 'vue'
import vueRouter from 'vue-router'
import moment from 'moment'
const Homepage = ()=> import ('../components/Homepage.vue')
const LogIn = ()=> import (/* webpackChunkName: "Auth-group" */ '../components/authentication/LogIn.vue')
const SignUp = ()=> import (/* webpackChunkName: "Auth-group" */ '../components/authentication/SignUp.vue')
const Reset = ()=> import (/* webpackChunkName: "Auth-group" */ '../components/authentication/Reset.vue')

const Blank = ()=> import('../components/Blank.vue')
//Booking elements for tenants
const Booking = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/Booking.vue')
const BookingStart = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/BookingStart.vue')
const CurrentInvoice = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/CurrentInvoice.vue')
const Issues = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/Issues.vue')
const PastPayments = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/PastPayments.vue')
const DepositPayment = ()=> import (/* webpackChunkName: "booking-group" */ '../components/tenantBooking/DepositPayment.vue')
const Pay = ()=> import (/* webpackChunkName: "booking-group" */ '../components/pay/Pay.vue')


const Visits =()=> import('../components/visits/Visits.vue')

const Unsubscribe =()=> import('../components/unsubscribe/Unsubscribe.vue')

const AboutUs =()=> import('../components/blogPages/AboutUs.vue')

//Policies
const Terms = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/Terms.vue')
const Policies = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/Policies.vue')
const PoliciesStart = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/PoliciesStart.vue')
const Refund = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/Refund.vue')
const Privacy = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/Privacy.vue')
const Cookies = ()=> import (/* webpackChunkName: "policies-group" */ '../components/blogPages/policies/Cookies.vue')

//HOW IT WORKS
const HIW = ()=> import (/* webpackChunkName: "hiw-group" */ '../components/blogPages/hiw/HIW.vue')
const hiwStart = ()=> import (/* webpackChunkName: "hiw-group" */ '../components/blogPages/hiw/hiwStart.vue')
const hiwLandlords = ()=> import (/* webpackChunkName: "hiw-group" */ '../components/blogPages/hiw/hiwLandlords.vue')
const hiwTenants = ()=> import (/* webpackChunkName: "hiw-group" */ '../components/blogPages/hiw/hiwTenants.vue')

//FAQ
const faq = ()=> import (/* webpackChunkName: "faq-group" */ '../components/blogPages/faq/faq.vue')
const faqStart = ()=> import (/* webpackChunkName: "faq-group" */ '../components/blogPages/faq/faqStart.vue')
const faqLandlords = ()=> import (/* webpackChunkName: "faq-group" */ '../components/blogPages/faq/faqLandlords.vue')
const faqTenants = ()=> import (/* webpackChunkName: "faq-group" */ '../components/blogPages/faq/faqTenants.vue')

//admin elements
const Admin = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/Admin.vue')
const AdminStart = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminStart.vue')
const AdminBookingRequests = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminBookingRequests.vue')
const AdminUsers = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminUsers.vue')
const AdminLandlords = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminLandlords.vue')
const AdminCompletedRequests = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminCompletedRequests.vue')
const AdminSEO = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminSEO.vue')
const AdminApartments = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminApartments.vue')
const AdminTransfTBM = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminTransfTBM.vue')
const AdminRents = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminRents.vue')
const AdminPayments = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminPayments.vue')
const AdminVisits = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminVisits.vue')
const Rankings = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/Rankings.vue')
const AdminRetargetting = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/Retargetting.vue')
const AdminPayIn = ()=> import (/* webpackChunkName: "admin-group" */ '../components/admin/AdminPayIn.vue')
const AdminLandlordDashboard = ()=> import (/* webpackChunkName: "admin-group" */'../components/landlordDashboard/AdminLandlordDashboard.vue')
const AdminTickets = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminTickets.vue')
const AdminChangeRoom = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminChangeRoom.vue')
const AdminCoupons = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminCoupons.vue')
const Analysis = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/Analysis.vue')
const AdminInvoices = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminInvoices.vue')
const AdminEmployees = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminEmployees.vue')
const AdminDeposits = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminDeposits.vue')
const AdminIssues  = ()=> import (/* webpackChunkName: "admin-group" */'../components/admin/AdminIssues.vue')
const AdminLineGroups = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminLineGroups.vue')
const AdminLineFollows = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminLineFollows.vue')
const AdminListings = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminListings.vue')
const AdminCancellations = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminCancellations.vue')
const AdminVisitPayments = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminVisitPayments.vue')
const AdminBookings = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminBookings.vue')
const AdminAddedTenantsConf = () => import (/* webpackChunkName: "admin-group" */'../components/admin/AdminAddedTenantsConf.vue')


const Ratings =()=> import (/* webpackChunkName: "rating-group" */'../components/ratings/Ratings.vue')
//Add a listing group
const AddListing = ()=> import (/* webpackChunkName: "add-listing-group" */ '../components/addListing/AddListing.vue')
const WholeApartment = ()=> import (/* webpackChunkName: "add-listing-group" */ '../components/addListing/WholeApartment.vue')
const NewApartment = ()=> import (/* webpackChunkName: "add-listing-group" */ '../components/addListing/NewApartment.vue')
const Studio = ()=> import (/* webpackChunkName: "add-listing-group" */ '../components/addListing/Studio.vue')
const Room = ()=> import (/* webpackChunkName: "add-listing-group" */ '../components/addListing/PrivateRoom.vue')

//Details
const Details = ()=> import (/* webpackChunkName: "details-group" */ '../components/roomDetails/Details.vue')
const StudioDetails = ()=> import (/* webpackChunkName: "details-group" */ '../components/roomDetails/StudioDetails.vue')
const ApartmentDetails = ()=> import (/* webpackChunkName: "details-group" */ '../components/roomDetails/ApartmentDetails.vue')

import store from '../store/store'

//taxes
const Tax = ()=> import ('../components/tax/Tax.vue')

//invoice
const InvoicePage = ()=> import ('../components/invoices/InvoicePage')
const AllInvoices= ()=> import ('../components/invoices/AllInvoices')

// careers:
const Careers = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/Careers.vue')
const CareersStart = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/CareersStart.vue')
const BrandAmbassador = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/jobPosts/BrandAmbassador.vue')
const CTO = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/jobPosts/CTO.vue')
const JPSupplyManager = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/jobPosts/JPSupplyManager.vue')
const TWSupplyManager = ()=> import (/* webpackChunkName: "Careers-group" */ '../components/blogPages/jobPosts/TWSupplyManager.vue')


//tenantsPage
const Tenants = ()=> import ('../components/tenantsPage/Tenants.vue')
const LandlordDashboard = ()=> import ('../components/landlordDashboard/LandlordDashboard.vue')

//LLPayouts
const LLPayments = ()=> import ('../components/llPayments/LLPayments.vue')
// import LLPayments from '../components/llPayments/LLPayments.vue'

//PROFILE
const ProfilePage = ()=> import (/* webpackChunkName: "profile-group"*/ '../components/profile/ProfilePage.vue')
const ProfileEdit = ()=> import (/* webpackChunkName: "profile-group"*/ '../components/profile/ProfileEdit.vue')
const Favorites = ()=> import (/* webpackChunkName: "profile-group"*/ '../components/favorites/Favorites.vue')

//TESTING
const MapTest = ()=> import('../components/results2/SearchResult')
const Home2 = ()=> import('../components/Homepage2')



const SearchResult2 = ()=> import('../components/results2/SearchResult2')
const SearchResultApts= ()=> import('../components/results2/SearchResultApts')

// import Inbox from '../components/inbox/Inbox.vue'
const BookingRequest = ()=> import('../components/bookingRequest/BookingRequest.vue')
const RequestsInbox = ()=> import('../components/requestsPage/RequestsInbox.vue')
const PaymentPage =()=> import('../components/userRequest/PaymentPage.vue')
const UserRequest =()=> import('../components/userRequest/userRequest.vue')

//Events
const AddEvent = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/addEvent/AddEvent.vue')
const AllEvents = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/allEvents/AllEvents.vue')
const EventMgmt = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/eventMgmt/EventMgmt.vue')
const EventPaymentPage = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/paymentPage/EventPaymentPage.vue')
const EventPage = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/eventPage/EventPage.vue')
const MyTickets = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/myTickets/MyTickets.vue')
const TicketDetails = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/myTickets/TicketDetails.vue')
const CheckTicket = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/checkTicket/CheckTicket.vue')
const Attendees = ()=> import (/* webpackChunkName: "events-group"*/ '../components/events/attendees/Attendees.vue')

const Affiliate =()=> import ('../components/affiliate/Affiliate.vue')
const AffiliateEv =()=> import ('../components/affiliate/AffiliateEv.vue')
//Referral
const Program = ()=> import (/* webpackChunkName: "referral-group"*/ '../components/referral/Program.vue')
const ReferralTerms = ()=> import (/* webpackChunkName: "referral-group"*/ '../components/referral/ReferralTerms.vue')

const FbLogin = ()=> import (/* webpackChunkName: "other-group"*/ '../components/fb/FbLogin.vue')
const FbLogin2 = ()=> import (/* webpackChunkName: "other-group"*/ '../components/fb/FbLogin2.vue')
const FbLogin3 = ()=> import (/* webpackChunkName: "other-group"*/ '../components/fb/FbLogin3.vue')

const SalesRoomTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesRoomTaipei.vue')
const SalesCouponRoom10 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesCouponRoom10.vue')
const SalesAptTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesAptTaipei.vue')
const SalesCouponApt10 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesCouponApt10.vue')
const SalesStudioTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesStudioTaipei.vue')
const SalesCouponStudio10 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesCouponStudio10.vue')
const SalesAccommodationTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesAccommodationTaipei.vue')
const SalesCouponAccommodation10 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/salesPages/SalesCouponAccommodation10.vue')
const TaiwanZH = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/zh/TaiwanZH.vue')
const TaiwanZH2 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/zh/TaiwanZH2.vue')

const SURBRoomTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SURBRoomTaipei.vue')
const SUFormRoomTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUFormRoomTaipei.vue')
const SUCouponRoomTaipei5 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUCouponRoomTaipei5.vue')
const SURBAptTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SURBAptTaipei.vue')
const SUFormAptTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUFormAptTaipei.vue')
const SUCouponAptTaipei5 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUCouponAptTaipei5.vue')
const SURBStudioTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SURBStudioTaipei.vue')
const SUFormStudioTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUFormStudioTaipei.vue')
const SUCouponStudioTaipei5 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUCouponStudioTaipei5.vue')
const SURBAccommodationTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SURBAccommodationTaipei.vue')
const SUFormAccommodationTaipei = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUFormAccommodationTaipei.vue')
const SUCouponAccommodationTaipei5 = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/leadsPages/SUCouponAccommodationTaipei5.vue')

const TStarterGuide = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/traffic/TStarterGuide.vue')
const TReviews = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/traffic/TReviews.vue')
const TBestRooms = ()=> import (/* webpackChunkName: "lp-group"*/ '../components/landingPages/traffic/TBestRooms.vue')


import Meta from 'vue-meta'

vue.use(Meta)
vue.use(vueRouter)


const routes = [

    {
      name: 'homepage',
      path: '/',
      component: Homepage
    }, {
      name: 'MapTest',
      path: '/MapTest',
      component: MapTest
    },{
      name: 'home2',
      path: '/home2',
      component: Home2
    },{
      name: 'aboutUs',
      path: '/aboutUs',
      component: AboutUs
    },{
      path: '/ratings',
      component: Ratings,
      meta: {
        minUserRole: 1
      },
    }, {
      path: '/invoice/:id',
      component: InvoicePage,
      meta: {
        minUserRole: 1
      },
    },{
      path: '/unsubscribe',
      component: Unsubscribe,
      meta: {
        minUserRole: 1
      },
    },{
      path: '/myInvoices',
      component: AllInvoices,
      meta: {
        minUserRole: 1
      },
      },  {
        name: 'fbLogin',
        path: '/fblogin',
        component: FbLogin
      },  {
        name: 'fbLogin2',
        path: '/fblogin2',
        component: FbLogin2
      },{
        name: 'fbLogin3',
        path: '/fblogin3',
        component: FbLogin3
      },{
        name: 'favorites',
        path: '/favorites',
        component: Favorites,
        meta:{
          minUserRole: 1
        }
      },{
      path: '/MyBooking',
      component: Booking,
      meta: {
        minUserRole: 1
      },
      children:[
        {
          name:'bookingStart',
          path:'/',
          component:BookingStart,
          meta: {
            minUserRole: 1
          }
        },{
          name:'currentInvoice',
          path:'rent',
          component:CurrentInvoice,
          meta: {
            minUserRole: 1
          }
        },{
          name:'issues',
          path:'issues',
          component:Issues,
          meta: {
            minUserRole: 1
          }
        },{
          name:'pastPayments',
          path:'payments',
          component:PastPayments,
          meta: {
            minUserRole: 1
          }
        },{
          name:'DepositPayment',
          path:'deposit',
          component:DepositPayment,
          meta: {
            minUserRole: 1
          }
        },
      ]
    }, {
      path: '/hiw',
      component: HIW,
      children:[
        {
          name:'howItWorks',
          path:'/',
          component:hiwStart,
        },
        {
          name:'hiwTenants',
          path:'tenants',
          component:hiwTenants
        },
        {
          name:'hiwLandlords',
          path:'landlords',
          component:hiwLandlords
        },
      ]
    }, {
      path: '/careers',
      component: Careers,
      children:[
        {
          name:'careers',
          path:'/',
          component:CareersStart,
        },
        {
          name:'brandAmbassador',
          path:'brandAmbassador',
          component:BrandAmbassador
        },
        {
          name:'developer',
          path:'developer',
          component:CTO,
        },{
          name:'JPSupply',
          path:'jpSupply',
          component:JPSupplyManager
        },{
          name:'TWSupply',
          path:'twsupply',
          component:TWSupplyManager
        }
      ]
    }, {
      path: '/faq',
      component: faq,
      children:[
        {
          name:'faq',
          path:'',
          component:faqStart,
        },
        {
          name:'faqTenants',
          path:'tenants',
          component:faqTenants,
        },
        {
          name:'faqLandlords',
          path:'landlords',
          component:faqLandlords,
        },
      ]
    }, {
      path: '/policies',
      component: Policies,
      children:[
        {
          name:'policies',
          path:'',
          component:PoliciesStart,
        },
        {
          name:'terms',
          path:'terms',
          component:Terms,
        },
        {
          name:'refund',
          path:'refund',
          component:Refund
        },
        {
          name:"privacy",
          path:"privacy",
          component:Privacy,
        },
        {
          name:"cookies",
          path:"cookies",
          component:Cookies
        }
      ]
    }, {
      name: 'room',
      path: '/Room/:apartmentId',
      component: Room,
      meta: {
        minUserRole: 19
      }

    }, {
      name: 'login',
      path: '/login',
      component: LogIn
    }, {
      name: 'signup',
      path: '/signup',
      component: SignUp
    }, {
      name: 'details',
      path: '/details/:roomId',
      component: Details
    }, {
      name: 'studioDetails',
      path: '/studioDetails/:studioId',
      component: StudioDetails
    }, {
      name: 'apartmentDetails',
      path: '/apartmentDetails/:aptId/:type',
      component: ApartmentDetails
    }, {
      name: 'roomSearch',
      path: '/search',
      component: SearchResult2
    }, {
      name: 'aptSearch',
      path: '/aptSearch',
      component: SearchResultApts
    }, {
      name: 'apartment',
      path: '/apartment',
      component: WholeApartment,
      meta: {
        minUserRole: 19
      }
    },{
      name: 'newApartment',
      path: '/newApartment',
      component: NewApartment,
      meta: {
        minUserRole: 19
      }
    }, {
      name: 'tenats',
      path: '/tenants',
      component: Tenants,
      meta: {
        minUserRole: 19
      }
    }, {
      path: '/admin',
      component: Admin,
      meta: {
        minUserRole: 79
      },
      children: [
        {
          name: 'adminBookings',
          path: 'bookings',
          component: AdminBookings,
          meta: {
            minUserRole: 79
          }
        },{
          name: 'AdminAddedTenantsConf',
          path: 'AddedTenants',
          component: AdminAddedTenantsConf,
          meta: {
            minUserRole: 79
          }
        },{
          name: 'adminBookingRequests',
          path: 'bookingRequests',
          component: AdminBookingRequests,
          meta: {
            minUserRole: 79
          }
        }, {
          name: 'adminListings',
          path: 'listings',
          component: AdminListings,
          meta: {
            minUserRole: 79
          }
        },{
          name: 'adminStart',
          path: '',
          component: AdminStart,
          meta: {
            minUserRole: 79
          }
        }, {
          name: 'adminLandlords',
          path: 'landlords',
          component: AdminLandlords,
          meta: {
            minUserRole: 79
          }
        }, {
        name : 'adminCompletedRequests',
        path: 'completedRequests',
        component: AdminCompletedRequests,
        meta: {
          minUserRole: 79
        }
      }, {
        name: 'adminUsers',
        path: 'users',
        component: AdminUsers,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminSeo',
        path: 'seo',
        component: AdminSEO,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'adminRankings',
        path: 'rankings',
        component: Rankings,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'adminApartments',
        path: 'apartments',
        component: AdminApartments,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminTransfTBM',
        path: 'transfersTBM',
        component: AdminTransfTBM,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'Adminrents',
        path: 'rent',
        component: AdminRents,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'Admindeposits',
        path: 'deposit',
        component: AdminDeposits,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminPayments',
        path: 'payments',
        component: AdminPayments,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminPayIn',
        path: 'PayIn',
        component: AdminPayIn,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminVisits',
        path: 'visits',
        component: AdminVisits,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminRetargetting',
        path: 'FuckYouSeb',
        component: AdminRetargetting,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminTickets',
        path: 'tickets',
        component: AdminTickets,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminChangeRoom',
        path: 'changeRoom/:orderId',
        component: AdminChangeRoom,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminCoupons',
        path: 'coupons',
        component: AdminCoupons,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminAnalysis',
        path: 'analysis',
        component: Analysis,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminInvoices',
        path: 'invoices',
        component: AdminInvoices,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminEmployees',
        path: 'employees',
        component: AdminEmployees,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'adminIssues',
        path: 'issues',
        component: AdminIssues,
        meta: {
          minUserRole: 79
        }
      },
      {
        name: 'AdminLineGroups',
        path: 'lineGroups',
        component: AdminLineGroups,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'AdminLineFollows',
        path: 'lineFollows',
        component: AdminLineFollows,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'AdminCancellations',
        path: 'cancel',
        component: AdminCancellations,
        meta: {
          minUserRole: 79
        }
      },{
        name: 'AdminVisitPayments',
        path: 'visitPayments',
        component: AdminVisitPayments,
        meta: {
          minUserRole: 79
        }
      },


    ]
  }, {
    name: 'adminLandlordDashboard',
    path: '/landlordDashboard/:id',
    component: AdminLandlordDashboard,
    meta: {
      minUserRole: 79
    }
  },{
    name: 'LandlordDashboard',
    path: '/dashboard',
    component: LandlordDashboard,
    meta: {
      minUserRole: 19
    }
  }, {
    name: 'reset',
    path: '/reset',
    component: Reset
  }, {
    name: 'profilePage',
    path: '/profile',
    component: ProfilePage,
    meta: {
      minUserRole: 1
    }
  }, {
    name: "profileEdit",
    path: '/profile/edit',
    component: ProfileEdit,
    meta: {
      minUserRole: 1
    }
  }, {
    name: 'userRequest',
    path: '/userRequest',
    component: UserRequest,
    meta: {
      minUserRole: 1
    }
  }, {
    name: 'visits',
    path: '/visits',
    component: Visits,
    meta: {
      minUserRole: 1
    }
  }, {
    path: '/payment/:orderId',
    name: 'paymentPage',
    component: PaymentPage,
    meta: {
      minUserRole: 1
    }
  }, {
    path: '/Pay/:payId',
    name: 'payPage',
    component: Pay,
    meta: {
      minUserRole: 1
    }
  }, {
    name: 'addListing',
    path: '/addListing',
    component: AddListing,
    meta: {
      minUserRole: 19
    }
  }, {
    name: 'studio',
    path: '/studio',
    component: Studio,
    meta: {
      minUserRole: 19
    }
  }, {
    name: 'llPayments',
    path: '/payouts',
    component: LLPayments,
    meta: {
      minUserRole: 19
    }
  }, {
    name: 'bookingRequest',
    path: '/bookingRequest/:id/:mid/:mod/:type',
    component: BookingRequest,
    meta: {
      minUserRole: 1
    }
  }, {
    name: 'requestsInbox',
    path: '/RequestsInbox',
    component: RequestsInbox,
    meta: {
      minUserRole: 19
    }
  },
  // event routes
  {
    name: 'addEvent',
    path: '/addEvent',
    component: AddEvent,
    meta: {
      minUserRole: 79
    }
  },
  {
    name: 'eventMgmt',
    path: '/eventMgmt/:id',
    component: EventMgmt,
    meta: {
      minUserRole: 79
    }
  },
  {
    name: 'events',
    path: '/events',
    component: AllEvents,
  },
  {
    name: 'eventPage',
    path: '/event/:id',
    component: EventPage,
  },
  {
    name: 'eventPayment',
    path: '/EventSummary/:eventId/:ticketId',
    component: EventPaymentPage,
    meta: {
      minUserRole: 1
    }
  },{
    name: 'myTickets',
    path: '/myTickets',
    component: MyTickets,
    meta: {
      minUserRole: 1
    }
  },{
    name: 'ticketDetails',
    path: '/ticketDetails/:id',
    component: TicketDetails,
    meta: {
      minUserRole: 1
    }
  },{
    name: 'checkTicket',
    path: '/checking/:eventId',
    component: CheckTicket,
    meta: {
      minUserRole: 22
    }
  },{
    name: 'attendees',
    path: '/attendees/:eventId',
    component: Attendees,
    meta: {
      minUserRole: 22
    }
  }, {
    name: 'tax',
    path: '/notax',
    component: Tax,
    meta: {
      minUserRole: 40
    }
  }, {
    name: 'affiliate',
    path: '/affiliate',
    component: Affiliate,
    meta: {
      minUserRole: 14
    }
  }, {
    name: 'affiliateEvents',
    path: '/affiliateEvents',
    component: AffiliateEv,
    meta: {
      minUserRole: 11
    }
  },{
    name: 'referral',
    path: '/referral',
    component: Program,
  },
  {
    name: 'referralTerms',
    path: '/ReferralTerms',
    component: ReferralTerms,

  },{
    name: 'salesRoomTaipei',
    path:'/s/Taiwan/Rooms',
    component: SalesRoomTaipei
  },{
    name: 'salesAccommodationTaipei',
    path:'/s/Taiwan/Accommodation',
    component: SalesAccommodationTaipei
  },{
    name: 'salesStudioTaipei',
    path:'/s/Taiwan/Studios',
    component: SalesStudioTaipei
  },{
    name: 'salesAptTaipei',
    path:'/s/Taiwan/Apartments',
    component: SalesAptTaipei
  },{
    name: 'TaiwanZH',
    path:'/zh/lp',
    component: TaiwanZH
  },{
    name: 'TaiwanZH2',
    path:'/zh/lp2',
    component: TaiwanZH2
  },{
    name: 'salesRoomCouponTaipei',
    path:'/s/Taiwan/Rooms/coupon',
    component: SalesCouponRoom10
  },{
    name: 'salesStudioCouponTaipei',
    path:'/s/Taiwan/Studios/coupon',
    component: SalesCouponStudio10
  },{
    name: 'salesApartmentsCouponTaipei',
    path:'/s/Taiwan/Apartments/coupon',
    component: SalesCouponApt10
  },{
    name: 'salesAccommodationCouponTaipei',
    path:'/s/Taiwan/Accommodation/coupon',
    component: SalesCouponAccommodation10
  },{
    name: 'SURBRoomTaipei',
    path:'/l/Taiwan/Room/Roombook',
    component: SURBRoomTaipei
  },{
    name: 'SUFormRoomTaipei',
    path:'/l/Taiwan/Room/Experts',
    component: SUFormRoomTaipei
  },{
    name: 'SUFormRoomTaipei',
    path:'/l/Taiwan/Room/coupon',
    component: SUCouponRoomTaipei5
  },{
    name: 'SURBAccommodationTaipei',
    path:'/l/Taiwan/Accommodation/Roombook',
    component: SURBAccommodationTaipei
  },{
    name: 'SUFormAccommodationTaipei',
    path:'/l/Taiwan/Accommodation/Experts',
    component: SUFormAccommodationTaipei
  },{
    name: 'SUFormAccommodationTaipei',
    path:'/l/Taiwan/Accommodation/coupon',
    component: SUCouponAccommodationTaipei5
  },{
    name: 'SURBAptTaipei',
    path:'/l/Taiwan/Apartment/Roombook',
    component: SURBAptTaipei
  },{
    name: 'SUFormAptTaipei',
    path:'/l/Taiwan/Apartment/Experts',
    component: SUFormAptTaipei
  },{
    name: 'SUFormAptTaipei',
    path:'/l/Taiwan/Apartment/coupon',
    component: SUCouponAptTaipei5
  },{
    name: 'SURBStudioTaipei',
    path:'/l/Taiwan/Studio/Roombook',
    component: SURBStudioTaipei
  },{
    name: 'SUFormStudioTaipei',
    path:'/l/Taiwan/Studio/Experts',
    component: SUFormStudioTaipei
  },{
    name: 'SUFormStudioTaipei',
    path:'/l/Taiwan/Studio/coupon',
    component: SUCouponStudioTaipei5
  },{
    name: 'TStarterGuide',
    path:'/t/Taiwan/StarterGuide',
    component: TStarterGuide
  },
  {
    name: 'TReviews',
    path:'/t/Taiwan/reviews',
    component: TReviews
  },
  {
    name: 'TBestRooms',
    path:'/t/Taiwan/bestRooms',
    component: TBestRooms
  },
  {
    name: 'TStarterGuide',
    path:'/t/Taiwan/StarterGuide',
    component: TStarterGuide
  },

  {
    name: 'Taiwan',
    path: "/Taiwan",
    component:Blank,
    children:[
      {
        name:'TaiwanStart',
        path:'*',
        component: SalesRoomTaipei
      },
    ]
  },
  { path: '*', redirect: '/' }
  ]

const router = new vueRouter({
  routes,
  mode: 'history',
  scrollBehavior: function(to, from, savedPosition) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return {x: 0, y: 0}
    }
  }
})

router.beforeEach((to, from, next) => {
  // retrieve the meta from the routes
  // console.log(to.path,'this is the to path')
  // console.log()
  const keepLogged= localStorage.getItem('keepLogged')
  const expirationDate = localStorage.getItem('expirationDate')
  const roleValue=localStorage.getItem('roleValue')
  console.log('keepLogged: ',keepLogged, ' exp: ',moment(expirationDate).format("L"))
  const minUserRole = to.meta.minUserRole
  if (to.fullPath.includes('sign')||to.fullPath.includes('log')){
    // console.log('this is a redirect sign up page!, dont recreate the cookie');
  } else {
    let now = new Date
    now.setTime(now.getTime() + (10 * 60 * 1000)) //expires in 60 min
    let expires = "expires=" + now.toUTCString();
    document.cookie = 'nextPath' + "=" + JSON.stringify(to.fullPath) + ";" + expires + ";path=/";
    // let next=JSON.stringify(to.fullPath)
    // console.log('next Path will be from before each :',to, JSON.stringify(to.fullPath))
  }

  if (store.state.auth.isLogged || keepLogged==true || moment().isBefore(moment(expirationDate))) {
    store.dispatch('auth/REFRESH_TOKEN')
  }
  if (!minUserRole) {
    // console.log(" No min user role required ==> so maybe it is login, dont do anything except going there");
    next()
  } else {

    if (minUserRole && !store.state.auth.isLogged) {
      // console.log('not logged so lets put a cookie for later');

      // console.log("YOU HAVE TO LOG IN!!!")
      // let cookie=""
      // const cname="nextPath"
      // var name = cname + "=";
      // var ca = document.cookie.split(';');
      // console.log(ca, 'are all the cookies:D')
      // for (var i = 0; i < ca.length; i++) {
      //   var c = ca[i];
      //   while (c.charAt(0) == ' ') {
      //     c = c.substring(1);
      //   }
      //   if (c.indexOf(name) == 0) {
      //     // console.log(c.substring(name.length, c.length))
      //     cookie=JSON.parse(c.substring(name.length, c.length));
      //   }
      // }
      // console.log(cookie, "this is your cookie")
      // console.log('to:', to.fullPath);
      router.replace({name: 'login', query:{next:to.fullPath}})
    } else {
      // console.log('logged')
           // console.log(to.name)
      if (store.state.auth.user.roleValue > minUserRole) {
        // console.log("you're important enoug");
         next()
      } else {
        // console.log("you're defo not important enough");
         next({name: 'homepage'})
      }
    }
  }





})

export default router;
