import mutations from './mutations'
import actions from './actions'
import  getters from './getters'

const state = {
  isLogged: false,
  user: {},
  idToken: null,
  userId: null,
}

export default {
    state,
    mutations,
    actions,
    getters
}
