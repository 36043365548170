<template>
<v-app>

  <v-content id="myroomabroad">

    <!-- <Contact v-if="showFB"></Contact> -->
    <router-view id="router">
    </router-view>
    <app-footer id="footer"></app-footer>

  </v-content>
</v-app>
</template>

<script>
import settings from './config'
import * as types from './store/types'
import moment from 'moment-timezone'
import {
  mapGetters,
  mapActions
} from 'vuex'
export default {
  name: 'app',
  data: () => ({
    fab:false,
    showSnackbar: false,
    showFB: true,
    langs:['en','zh','ja','fr','nl'],
  }),
  methods: {
    ...mapActions({
      changeFbDisplay: types.CHANGE_FBDISPLAY
    }),
    acceptCookies() {
      document.cookie = "cookiesCookie=" + JSON.stringify('accepted_cookies')
      this.showSnackbar = false
      // this.changeFbDispay(true)
    },
    getCookie(cname) {
      let cookie = ""
      var name = cname + "="
      var decodedCookie = decodeURIComponent(document.cookie)
      var ca = decodedCookie.split(';')
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        // console.log('COOKIE NUMBER :' + i)
        // console.log(c)
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          cookie = c.substring(name.length, c.length)
          // console.log(c, "the c in this story", cookie, "the cookie which needs to be parsed");
        }
      }
      if (cookie != "" && cookie.includes('"')) {
        cookie = JSON.parse(cookie)
      }
      return cookie
    },
    checkQuery(){
      console.log('route: ', this.$route, "router: ",this.$router);
      // if (this.$route.query!=undefined && this.$route.query.rts!=undefined && this.$route.query.id!=undefined){
      //   console.log('post the query RTS: .',this.$route.query.rts, "+ID: ",this.$route.query.id);
      // } else {
      //   console.log("no query worth noting" ,this.$route.query);
      //
      // }
    }
  },
  computed: {
    ...mapGetters({
      fbDisplay: types.GET_FBDISPLAY
    }),
  },
  watch: {
    '$route'(to, from) {
      // console.log('route to:', to);
      // console.log(to.query.lang, 'changed route', this.langs.includes(to.query.lang));
      if (to.query.lang!=undefined && this.langs.includes(to.query.lang)){
        this.$i18n.locale = to.query.lang
        let d = new Date()
        d.setTime(d.getTime() + (90 * 24 * 60 * 60 * 1000))
        let expires = "expires=" + d.toUTCString()
        document.cookie = 'lang' + "=" + JSON.stringify(to.query.lang) + ";" + expires + ";path=/"
      }
    }
  },
  created() {
    console.log('start app',this.$route.query);
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    // Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    // console.log(isIE, isEdge, 'checker');
    if (isIE || isEdge ){
      alert('The website is known to misbehave on IE and Edge. We recommend using Google Chrome for browsing My Room Abroad')
    }
    // console.log('the path in app.vue: ',/ this.$route.fullPath);
    // let pathCookie = this.getCookie('nextPath')
    // if (!pathCookie) {
    //   let now = new Date
    //   now.setTime(now.getTime() + (10 * 60 * 1000)) //expires in 10min
    //   let expires = "expires=" + now.toUTCString();
    //   document.cookie = 'nextPath' + "=" + this.$route.fullPath + ";" + expires + ";path=/";
    // }
    this.$store.dispatch('auth/AUTO_LOG_IN')
    let langCookie = this.getCookie('lang')
    if (!langCookie) {
        this.$i18n.locale = 'en'
    } else {
      this.$i18n.locale = langCookie
      let lang = ""
      switch (langCookie) {
        case 'zh':
          lang = 'zh-tw'
          break;
        default:
          lang = 'en'
      }
      moment.locale(lang)
      // console.log(moment.locale(),'moment, locale:');
      this.$vuetify.lang.current = langCookie
    }
    let cookiesCookie = this.getCookie('cookiesCookie')
    if (!cookiesCookie) {
      this.showSnackbar = true
      // this.changeFbDispay(false)
    }

  },
  metaInfo() {
    return {
      title: settings.TITLE, // set a title
      titleTemplate: '%s', // title is now "My Example App - Yay!"
      meta: [{
          charset: 'utf-8'
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1'
        },
        {
          vmid: 'description',
          name: 'description',
          content: settings.TAGLINE
        },
        {
          vmid: 'identifier-url',
          name: 'identifier-url',
          content: 'https://myroomabroad.com/'
        },
        {
          name: 'publisher',
          content: settings.SITENAME
        },
        {
          name: 'copyright',
          content: settings.SITENAME
        },
        {
          property: 'og:locale',
          content: 'en_US'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:site_name',
          content: settings.SITENAME
        },
        {
          vmid: 'og:url',
          property: 'og:url',
          content: 'https://myroomabroad.com' + this.$route.path
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: settings.TITLE
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: settings.TAGLINE
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: "https://res.cloudinary.com/my-room-abroad/image/upload/c_lpad,h_900,w_1400,f_auto,q_auto/v1545459923/Logo/Logo_square_LARGE_TXT_no_bg.jpg"
        },
        {
          vmid: 'og:image:type',
          property: 'og:image:type',
          content: 'image/png'
        },
        {
          vmid: 'og:image:width',
          property: 'og:image:width',
          content: '3000'
        },
        {
          vmid: 'og:image:height',
          property: 'og:image:height',
          content: '3000'
        },
        {
          name: 'twitter:card',
          content: "summary"
        },
        {
          name: 'twitter:site',
          content: settings.TWITTERACCOUNT
        },
        {
          name: 'twitter:creator',
          content: settings.TWITTERACCOUNT
        },
        {
          vmid: 'twitter:image',
          name: 'twitter:image',
          content: "https://res.cloudinary.com/my-room-abroad/image/upload/v1537411386/homepage/Logo_square.png"
        },
        {
          vmid: 'twitter:title',
          name: 'twitter:title',
          content: settings.TITLE
        },
        {
          vmid: 'twitter:url',
          name: 'twitter:url',
          content: 'https://myroomabroad.com' + this.$route.path
        },
        {
          vmid: 'twitter:description',
          name: 'twitter:description',
          content: settings.TAGLINE
        },

      ]

    }
  },
  components:{
  }
}
</script>

<style lang="scss" >
// @import '@/assets/main.scss';
@import './assets/newicons.css';
@font-face {
  font-family: 'Roboto', 'Open Sans', 'Sans-serif';
  font-display: auto;
}
.v-input--selection-controls__input i {
  pointer-events: none!important;
}
// .v-input--selection-controls__input input {
//   margin-left: 75%!important;
//   height: 24px!important;
//   width: 24px!important;
// }
// .v-input--selection-controls__ripple{
//
//   left:-75%!important;
//   top:-75%!important;
// }
.v-input--selection-controls{
  margin-left: 1em!important;
}
#myroomabroad {
    display: grid;
    grid-template: 1fr / 1fr;
}
#router {
    grid-column: 1;
}
#footer {
    grid-column: 1;
}
.theme--light.application {
    background: #ffffff;
}

#vue-toggle .btn-group .btn-default {
    border: 1px solid rgb(216, 216, 216)!important;
}
.v-card__text, .v-card__title {
  word-break: normal!important;
}

.v-btn::before{
  background-color:#fff;
}
.theme--dark.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
  background-color: transparent;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
  background-color: transparent;
}
.snackbar-span {
    padding-left: 1rem;
}
.v-tooltip{
  display:inherit!important
}
// hack for allowing grid items to have their own space instead of auto filling
.flex-0 {
    flex: 0 1 auto!important;
}
.v-card__title {
    font-size: 1.25rem;
    padding: 1rem;
}
.labelTitle {
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 8px;
    color: black;
}
.ma-auto {
    margin: auto;
}
.no-pa-top {
    padding-top: 0!important;
}
.no-ma-sides {
    margin-left: 0 !important;
    margin-right: 0 !important;
}
.no-ma-bottom {
    margin-bottom: 0 !important;
}
.no-pa-sides {
    padding-left: 0px!important;
    padding-right: 0px!important;
}
.no-box-shadow {
    box-shadow: none !important;
}
.v-carousel.rules-carousel .v-btn {
    color: #00BFFF;
}
.v-carousel__controls__item .v-icon {
    opacity: 0.3;
}
.v-carousel__controls__item--active .v-icon {
    opacity: 1;
}
.caption .vuejs-countdown .digit {
    font-size: 2em;
}
.caption .vuejs-countdown li::after {
    font-size: 2em;
}
.capitalize {
    text-transform: capitalize;
}
.range-picker .v-date-picker-table .v-btn {
    z-index: 2!important;
}
.range-picker .v-date-picker-table__events {
    height: 32px !important;
    width: 32px !important;
    // left: 2px !important;
    bottom: 0 !important;
    transform: none !important;
    z-index: 0 !important;
    /* background-color:none!importn */
}
.v-date-picker-table__events>div{

    height: 32px !important;
    width: 32px !important;
    // left: 0px !important;
    bottom: 0 !important;
    transform: none !important;
    z-index: 0 !important;
    /* background-color:none!importn */

}

.snackbar-btn-left {
    margin-left: 0 !important;
    margin-right: 42px !important;
}

.uppercase {
    text-transform: uppercase;
}
.lang-select {
    .v-select__selections {
        direction: rtl;
    }
    .v-input__slot {
        border-style: none!important;
    }
    .v-input__slot:before {
        border-style: none!important;
    }

}
// .v-text-field--outline {
//   .v-input__slot{
//     border-width: 1px!important;
//   }
// }
.transparent .v-icon {
    color: #fff;
}
</style>
