import * as types from '../../types'
export default{
  [types.MUT_LOG_OUT] (state) {
    state.user = null
    state.isLogged = false
    state.idToken = null
    state.userId = null
  },
  [types.MUT_AUTH_USER] (state, userData){
    state.idToken = userData.idToken
    state.userId = userData.userId
    state.isLogged = true
  },
  [types.MUT_STORE_USER] (state, user){
    state.user = user
  },
  [types.MUT_REFRESH_USER] (state, userData){
    state.idToken = userData.idToken
  }
}
