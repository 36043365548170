//  MUTATIONS
export const MUT_LOG_IN = 'auth/MUT_LOG_IN'
export const MUT_LOG_OUT = 'auth/MUT_LOG_OUT'
export const MUT_AUTH_USER = 'auth/MUT_AUTH_USER'
export const MUT_STORE_USER = 'auth/MUT_STORE_USER'
export const MUT_REFRESH_USER = 'auth/MUT_REFRESH_USER'
export const MUT_FBDISPLAY = 'MUT_FBDISPLAY'

// ACTIONS
export const LOG_IN = 'auth/LOG_IN'
export const LOG_OUT = 'auth/LOG_OUT'
export const SIGN_UP = 'auth/SIGN_UP'
export const FETCH_USER = 'auth/FETCH_USER'
export const STORE_USER = 'auth/STORE_USER'
export const SET_LOG_OUT_TIMER = 'auth/SET_LOG_OUT_TIMER'
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN'
export const SET_LOCAL_STORAGE = 'auth/SET_LOCAL_STORAGE'
export const AUTO_LOG_IN = 'auth/AUTO_LOG_IN'
export const CHANGE_FBDISPLAY = 'CHANGE_FBDISPLAY'
export const SET_STORE="auth/SET_STORE"
// GETTERS
export const GET_USER = 'auth/GET_USER'
export const IS_LOGGED = 'auth/IS_LOGGED'
export const GET_FBDISPLAY= 'GET_FBDISPLAY'
