<template lang="html">
  <v-btn color="primary" dark fixed bottom right fab href="https://m.me/myroomabroad" target="_blank">
   <v-icon id="fbContactButton">forum</v-icon>
 </v-btn>
</template>

<script>
export default {

  data: () => ({
    fab: false,
  })
}
</script>

<style lang="scss" scoped>
</style>
