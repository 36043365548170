import AWS from 'aws-sdk'
import config from './config'

export default {
  sendRent(user, info,template){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <hello@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"name\":\"'+user.userName+'\", \"table\":\"'+info.table+'\", \"due\":\"'+info.due+'\"}',
      /* required */
    }

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    // Handle promise's fulfilled/rejected states
    sendPromise.then(function(data) {
      console.log('SENT: ',data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  sendPlainText(user,info){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <hello@myroomabroad.com>',
      /* required */
      Template: 'blank',
      /* required */
      TemplateData: '{ \"subject\":\"'+info.subject+'\",\"text\":\"'+info.text+'\"}',
      /* required */
    }

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    // Handle promise's fulfilled/rejected states
    sendPromise.then(function() {
      // console.log(data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  sendIssue(user, info, template){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"subject\":\"'+info.subject+'\",\"name\":\"'+user.userName+'\",\"tenantName\":\"'+info.tenantName+'\",\"issueText\":\"'+info.issueText+'\"}',
      /* required */
    }

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    // Handle promise's fulfilled/rejected states
    sendPromise.then(function() {
      // console.log(data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  emailWithButtons(user, link1, link2,template){

    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"name\":\"'+user.userName+'\", \"link1\":\"'+link1+'\",\"link2\":\"'+link2+'\"}',
      /* required */
    }

    // Create the promise and SES service object
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    // Handle promise's fulfilled/rejected states
    sendPromise.then(function() {
      // console.log(data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })

  },
  sendCoupon(user,info, template){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })

    // Create sendTemplatedEmail params
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    console.log(user, info, template);
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      ReplyToAddresses:['hello@myroomabroad.com'],
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"name\":\"'+user.userName+'\",\"coupon\":\"'+info.coupon+'\",\"val\":\"'+info.val+'\"}',
      /* required */
    }
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    sendPromise.then(function(data) {
      console.log('SENT',data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  emailingInfo(user, info, template){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })

    // Create sendTemplatedEmail params
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"name\":\"'+user.userName+'\", \"room\":\"'+info.room+'\",\"tenant\":\"'+info.tenantName+'\",\"landlord\":\"'+info.landlord+'\",\"amount\":\"'+info.amount+'\",\"comment\":\"'+info.comment+'\",\"mid\":\"'+info.mid+'\",\"mod\":\"'+info.mod+'\",\"url\":\"'+info.url+'\", \"listing\":\"'+info.listing+'\",\"dateAvailable\":\"'+info.dateAvailable+'\",\"ListingName\":\"'+info.listingName+'\",\"Price\":\"'+info.price+'\",\"stopEmail\":\"'+info.stopEmails+'\",\"NumberNewListings\":\"'+info.NumberNewListings+'\"}',
      /* required */
    }

    // Create the promise and SES service object
    // Handle promise's fulfilled/rejected states
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    sendPromise.then(function(data) {
      console.log('SENT',data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  emailing(user, template) {
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })

    // Create sendTemplatedEmail params
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ]
      },
      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"name\":\"'+user.userName+'\" }',
      /* required */
    }
    // Create the promise and SES service object
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    // Handle promise's fulfilled/rejected states
    sendPromise.then(function() {
      // console.log(data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  },
  adminNotif(user,info, template){
    let credentials = new AWS.Credentials({
      accessKeyId: config.AWS_ACCESS_KEY_ID,
      secretAccessKey: config.AWS_SECRET_ACCESS_KEY
    })

    // Create sendTemplatedEmail params
    if(user.userName==undefined){
      if(user.name!=undefined){
        user.userName=user.name
      } else{
        user.userName='User'
      }
    }
    console.log(user, info, template);
    var params = {
      Destination: {/* required */
        ToAddresses: [
          user.email,
          /* more To email addresses */
        ],
        CcAddresses:[
          'vincent@myroomabroad.com'
        ]
      },

      Source: 'My Room Abroad <noreply@myroomabroad.com>',
      /* required */
      Template: template,
      /* required */
      TemplateData: '{ \"admin\":\"'+info.admin+'\",\"function\":\"'+info.function+'\",\"date\":\"'+info.date+'\",\"crId\":\"'+info.crId+'\",\"before\":\"'+info.before+'\",\"after\":\"'+info.after+'\",\"room\":\"'+info.room+'\",\"tenant\":\"'+info.tenant+'\"}'
      /* required */
    }
    console.log("PARAMS:",params.TemplateData);
    // console.log("Cred:",{credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'});
    var sendPromise = new AWS.SES({credentials: credentials, region:'eu-west-1', apiVersion: '2010-12-01'}).sendTemplatedEmail(params).promise()
    sendPromise.then(function(data) {
      console.log('SENT',data)
    }).catch(function(err) {
      console.error(err, err.stack)
    })
  }
}
