<template>
  <v-navigation-drawer v-model="open"
      absolute
      temporary>
    <v-toolbar flat color="primary" dark>
      <v-list>
        <v-list-tile>
          <v-list-tile-title class="title" v-if="!auth">
            {{$t('navbar.hi')}}
          </v-list-tile-title>
          <v-list-tile avatar v-if="auth">
            {{$t('navbar.hi')}} {{user.userName}}
          </v-list-tile>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-divider></v-divider>
    <v-list dense class="pt-0" v-if="!auth">
      <v-list-tile href='/signup'>
        <v-list-tile-title>{{$t('navbar.signup')}}</v-list-tile-title>
      </v-list-tile>
      <v-list-tile href='/login'>
        <v-list-tile-title>{{$t('navbar.login')}}</v-list-tile-title>
      </v-list-tile>

    </v-list>
    <v-list dense class="pt-0" v-if="auth">
      <v-list-tile href='/admin' v-if="user.roleValue>79" class="no-show-large">
        <v-list-tile-title>admin</v-list-tile-title>
      </v-list-tile>
      <v-list-tile href='/checking/0' v-if="user.roleValue>79" class="no-show-large">
        <v-list-tile-title>Check Tickets</v-list-tile-title>
      </v-list-tile>
      <v-list-tile href='/events' class="no-show-large">
        <v-list-tile-title>Events</v-list-tile-title>
      </v-list-tile>

        <v-list-tile href='/tenants' v-if="user.roleValue>11" class="no-show-large">
          <v-list-tile-title>{{$t('navbar.myTenants')}}</v-list-tile-title>
        </v-list-tile>

        <v-list-tile href='/addListing' v-if="user.roleValue>11" class="no-show-large">
          <v-list-tile-title>{{$t('navbar.newListing')}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile :href="'/'+userRequestPresent" v-if="userRequestPresent!=null" >
            <v-list-tile-title>{{$t('navbar.myBooking')}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile :href="'/visits'" v-if="user.visits!=undefined" >
            <v-list-tile-title>My Visits</v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/requestsInbox' v-if="user.roleValue>11">
          <v-list-tile-title >{{$t('navbar.incomingReqs')}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/dashboard' v-if="user.roleValue>11">
          <v-list-tile-title >{{$t('navbar.myListings')}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/payouts' v-if="user.roleValue>11">
          <v-list-tile-title >My Payments</v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/favorites' v-if="user.favs!=undefined">
          <v-list-tile-title >My Favorites <v-icon color='red'>favorite</v-icon> </v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/profile'>
            <v-list-tile-title >{{$t('navbar.profile')}}</v-list-tile-title>
        </v-list-tile>
        <v-list-tile href='/myTickets' v-if="user.hasTicket">
            <v-list-tile-title >My Tickets & Events</v-list-tile-title>
        </v-list-tile>

        <v-list-tile @click="logout" color="error">
          <v-list-tile-title >{{$t('navbar.logout')}}</v-list-tile-title>
        </v-list-tile>

    </v-list>
    <v-select
    :items="langs"
    append-icon="translate"
    v-model="$i18n.locale"
    class="lang-select ml-4"
    flat
    @change="setLanguage"
    >
    <template
    slot="selection"
    slot-scope="{ item, index }"
    >
    <span class="">{{ item.text }}</span>
  </template>
</v-select>
  </v-navigation-drawer>
</template>
<script>
import moment from 'moment'
import {
  mapActions
} from 'vuex'
import * as types from '../../store/types'

export default {
  props:['auth','userRequestPresent','user','open'],
  data: () => ({
    langs:[
        {
          text:"EN",
          value:"en",
        },
        {
          text:'中文',
          value:"zh",
        },
        {
          text:'한국어',
          value:'ko',
        },{
          text:"日本語",
          value:'ja'
        },
        {
          text:'FR',
          value:"fr"
        },
        {
          text:'ES',
          value:"es",
        },
        {
          text:'IT',
          value:"it",
        },
        {
          text:'NL',
          value:"nl",
        },
        {
          text:'DE',
          value:"de",
        },


      ]
    }),
  methods: {
    ...mapActions({
      logOutAction: types.LOG_OUT
    }),
    logout() {
      try {
        this.$Tawk.$endChat()
      } catch (e) {
        console.log(e);
      }
      this.logOutAction()
    },
    setLanguage(payload){
      // console.log(payload,'payload');
      if(payload==""){
        // console.log('yes');
        payload='en'
        this.$i18n.locale='en'
      }
      // console.log("set langu",payload);
      let lang=""
      switch (payload) {
        case 'zh':
          lang='zh-tw'
          break;
        default:
          lang=payload
      }
      moment.locale(lang)
      this.setCookie('lang', payload, 90)
      this.$vuetify.lang.current = payload
      // console.log(moment.locale())
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      let expires = "expires=" + d.toUTCString()
      document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/"
    },
  },

  computed:{
    drawer: {
      get:function(){
        return this.open
      },
      set: function(val){
        this.$emit('changeOpen',val)
      }

    }
  }

}
</script>
<style lang="scss" scoped>
.lang-select{
  width:3.8em;
  border-style:none !important;
  border-width: 0px;
  .v-select__selection{
  }
  .transparent{
    color:#ffffff;
    .v-select__selection{
      color:#ffffff;
    }
  }
}

</style>
