import Vue from 'vue'
import Vuetify from 'vuetify'
import axios from './axios'


import router from './router/index'
import store from  './store/store'

import config from './config'

import Vuelidate from 'vuelidate'
import VueAffix from 'vue-affix'
import Vuefire from 'vuefire'
import VueLazyload from 'vue-lazyload'
import VueCloudinary from 'vue-cloudinary'
import VueMq from 'vue-mq'

//OPEN MAPS
import { LMap, LTileLayer, LControl, LFeatureGroup, LMarker, LPopup, LIcon, LCircleMarker } from 'vue2-leaflet';


import { Icon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: config.GOOGLE_MAPS_API_KEY, // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
});
// })

import Tawk from 'vue-tawk'


Vue.component('l-control', LControl);
Vue.component('l-feature-group', LFeatureGroup);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-popup', LPopup);
Vue.component('l-icon',LIcon)
Vue.component('l-circle-marker',LCircleMarker)


delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});
// End Open MAPS



Vue.config.productionTip = false
import Meta from 'vue-meta'

Vue.use(Meta,{
  tagIDKeyName: 'vmid' // the property name that vue-meta uses to determine whether to overwrite or append a tag
})

import zh_vue from 'vuetify/es5/locale/zh-Hant'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify, {
  lang:{
    locales:{ zh_vue }
  },
  theme: {
    primary: '#00bfff',
    secondary: '#b0bec5',
    accent: '#00bFFF',
    error: '#ff0000',
    light: '#ffffff'
  },
})

Vue.use(Vuefire)



Vue.use(VueMq)
// Vue.use(VueMq, {
//   breakpoints: { // default breakpoints - customize this
//     sm: 450,
//     md: 1250,
//     lg: Infinity,
//   }
// });

Vue.use(VueCloudinary, {
  "cloud_name": "my-room-abroad",
  "api_key": "648241524479494",
  "secure":true,
});

Vue.use(VueLazyload,{
  preLoad: 2.5,
  attempts:2,
})
Vue.use(VueAffix)
Vue.use(Vuelidate)

import Navbar from './components/shared/Navbar.vue'
import Contact from './components/shared/Contact.vue'
import App from './App.vue'
import Footer from './components/shared/Footer.vue'

Vue.component('Contact', Contact)
Vue.component('Navbar',Navbar);
Vue.component('app-footer', Footer);
//internationalization - translation
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
import en from './texts/en'
import ja from './texts/ja'
import ko from './texts/ko'
import es from './texts/es'
import it from './texts/it'
import de from './texts/de'
import nl from './texts/nl'
import fr from './texts/fr'
import zh from './texts/zh'
// import './registerServiceWorker'
let messages={}
messages.en=en
messages.zh=zh
messages.ja=ja
messages.ko=ko
messages.es=es
messages.it=it
messages.de=de
messages.nl=nl
messages.fr=fr

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages,
})
const root=new Vue ({
  el:"#app",
  i18n,
  router,
  store,
  render: h => h(App),
  mounted () {
     // You'll need this for renderAfterDocumentEvent.
     document.dispatchEvent(new Event('render-event'))
     Vue.use(Tawk, {
         tawkSrc: 'https://embed.tawk.to/5e6041298d24fc226585d641/default'
     })

   }
})
document.addEventListener('DOMContentLoaded', function () {
  root.$mount('#app')
})
