export default{

    homepage: {
      title: 'Encontrar un hogar en las mejores ciudades de Asia',
      enterLocation: 'Introduzca una ubicación',
      search: 'BUSCAR',
      trust: 'la confianza de',
      ntnu: 'NTNU',
      ntust: 'NTUST',
      nccu: 'NCCU',
      taipeiTech:"Taipei Tech",
      reachToTeach: 'Alcanzar para enseñar',
      whyBook: '¿Por qué reservar con My Room Abroad',
      whyBookText: 'Encontrar y reservar su habitación para su semestre de intercambio en Taipei es una gran cosa. Hemos estado allí también! Por eso nos centramos en la construcción de una solución en línea innovadora que hace que este proceso seguro y fácil para ti, y que te permite navegar a través de miles de habitaciones verificados en las mejores zonas de Taipei.',
      whyBookStep1: 'Busca entre miles de habitaciones y solicitar su favorito',
      whyBookStep2: 'Recibir confirmación por parte del propietario dentro de 48h',
      whyBookStep3: '¡Hecho! La ocupación del apartamento y disfrutar!',
      moreThan: 'Mas que',
      students: '250 estudiantes',
      fromOver: 'Desde arriba',
      countries: '20 países',
      book: 'Reserve una de',
      places: '2000 lugares verificados',
      teamTitle: 'Un equipo impresionante a su servicio',
      teamText: 'Si nos necesita, nuestro equipo está dispuesto a ayudar 7 días a la semana.',
      about: 'Sobre nosotros',
      landlordTitle: '¿Eres un propietario en busca de los estudiantes?',
      landlordText: 'Cada año millones de estudiantes están buscando un nuevo hogar a través de Asia. Ellos están buscando a mediados estancias de larga duración y los grandes propietarios como usted. ¿Tiene un lugar para alquilar? Nuestros estudiantes están buscando.',
      landlordBtn: 'Crea Tu Perfil'
    },
    dropzone: {
      upload: 'Subir',
      drag: 'Arrastrar y soltar para ordenar orden de visualización.',
      uploadMore: '+ Subir más fotos',
      remove: 'retirar',
      dropText: 'Coloca aquí las fotos.',
      removeText: 'Retire foto',
      selectRoom: 'Seleccionar habitación',
      commonArea: 'Áreas comunes',
      kitchen: 'Cocina',
      wet: 'Mojado',
      dry: 'Seco'
    },
    userRequest: {
      reqText: 'El propietario tiene 48 horas para aceptar su solicitud. Una vez que se acepta su solicitud de la sala es suya y se le cobrará.',
      cancel: 'Cancelar petición',
      pastText: 'Pruebe otra reserva o seleccione otra habitación',
      acceptText: 'Tu petición ha sido aceptada. Por favor proceda al pago.',
      invoice: 'Mirar la factura',
      paidText: 'Aquí está toda la información necesaria para contactar con el propietario:',
      llName: 'Nombre del propietario:',
      llEmail: 'Correo electrónico de propietario:',
      declTitle: 'Su solicitud ha sido rechazada.',
      declText2: 'Esto pasa.',
      declText3: 'Trate de reservar otra habitación.',
      declText4: 'O enviar un mensaje de mensajero si necesita ayuda para reservar su habitación',
      declText5: 'Por desgracia alguien más reservado antes.',
      declText6: 'El dueño pensó que puede gustarte este cuarto lugar:',
      declBtn1: 'Echale un vistazo',
      declBtn2: 'Buscar otra habitación',
      cancelTitle: 'Cancelar su solicitud?',
      cancelText: '¿Estás realmente seguro de que desea cancelar su solicitud? / N Este es un paso no reversible',
      cancelKeep: 'Mantener la solicitud',
      minLength: 'Longitud mínima del contrato:',
      contractType: 'Tipo de contrato',
      contractDates: 'Fechas de contrato',
      mid: 'Mudarse',
      mod: 'Mudarse',
      midError: 'Por favor, introduzca un movimiento en la fecha',
      modError: 'Por favor, introduzca un movimiento fuera la fecha',
      genderError: 'Por favor, seleccione un género',
      payment: 'Detalles del pago',
      toMRA: 'El pago se efectúa a ARM',
      firstRent: 'Pago del primer alquiler',
      couplesSurcharge: 'Pareja de pago:',
      fee: 'cuota de reserva de una sola vez',
      feeHelp1: 'My Room Abroad cobra un cargo por servicio de una sola vez para todas las reservas. El cargo por servicio se basa en la renta mensual.',
      feeHelp2: 'Esto incluye las comisiones bancarias, los costos de transacción y hace posible que podamos para apoyarlos y para darle una protección extra:',
      feeHelp3: 'Garantía de 24 horas para informarnos si su habitación no coincide con la descripción y ser reembolsado.',
      feeHelp4: 'Atención al cliente y servicio de mediación 7 días a la semana.',
      discount: 'Descuento',
      total: 'Total',
      charge: 'Sólo se le cobrará una vez que su solicitud es aceptada por el propietario.',
      reqStatus: 'A la espera de que el propietario para confirmar',
      declStatus: 'El propietario ha rechazado su solicitud de reserva',
      acceptStatus: 'El propietario ha confirmado su reserva! :RE',
      paidStatus: 'Hemos recibido su pago.',
      pastStatus: 'El arrendador no respondió dentro de las 48 horas.',
      couple: 'Pareja',
      single: 'Soltero',
      directions: 'Obtener direcciones en Google Maps',
      invoice: 'Mirar la factura'
    },
    paymentPage: {
      invoice: 'Factura',
      invoiceFor: 'Factura para:',
      dueDate: 'Fecha de vencimiento',
      payableTo: 'A nombre de',
      mra: 'My Room Abroad',
      accountHolder: 'Titular de la cuenta',
      accountNumber: 'Número de cuenta',
      accountName: "Account Name",
      branchCode: "Branch Code",
      description: 'Descripción',
      firstRent: '1er mes de alquiler',
      fee: 'Cargo por servicio de costos de transacción + de una sola vez',
      amount: 'Cantidad',
      discount: 'Descuento',
      subtotal: 'Total parcial',
      currencyTotal: 'Mi total de monedas:',
      totalToPay: 'Total a pagar'
    },
    tenants: {
      tenants: 'Los inquilinos',
      search: 'Buscar',
      noResults: 'Su búsqueda para la búsqueda {} no se encontraron resultados.',
      room: 'Habitación',
      name: 'Nombre',
      email: 'Email',
      nationality: 'Nacionalidad',
      mid: 'movimiento real en',
      mod: 'movimiento real a cabo',
      rent: 'En primer mes de alquiler',
      cmid: 'inicio del contrato',
      cmod: 'finalización del contrato',
      waiting: 'A la espera del pago',
      paid: 'Pago recibido'
    },
    navbar: {
      signup: 'Regístrate',
      login: 'Iniciar sesión',
      hi: 'Hola,',
      myTenants: 'Mis inquilinos',
      newListing: '+ Nuevo Anuncio',
      myBooking: 'Mi reserva',
      incomingReqs: 'Las solicitudes entrantes',
      myListings: 'Mis listados',
      profile: 'Perfil',
      logout: 'Cerrar sesión',
      menu: 'Menú'
    },
    loading: {
      loading: 'Cargando'
    },
    datePicker: {
      clear: 'Claro',
      cancel: 'Cancelar',
      confirm: 'De acuerdo'
    },
    trust: {
      verification: 'Verificación',
      verificationText: 'ARM verifica la identidad de todos sus propietarios y asignar un nivel de confianza a cada uno de los listados. Hacemos esto para asegurar que su nuevo hogar es como se describe en el anuncio y su nuevo propietario es quien dice ser. Estos son los cuatro niveles de verificación:',
      exclHead: 'exclusivo propietario',
      exclTitle: 'Socio exclusivo de My Room Abroad',
      exclText: 'Este anuncio es una de nuestras principales recomendaciones. Hemos seleccionado este patrón a ser nuestro socio exclusivo local. Esta lista sólo está disponible en ARM',
      visitHead: 'Visitado habitación',
      visitTitle: 'Confiamos en este lugar',
      visitText: "Hemos visitado personalmente este lugar y se ha ganado el Visitada por My Room Abroad insignia.",
      verifHead: 'Verified propietario',
      verifTitle: 'Este es un propietario verificado',
      verifText: 'Hemos comprobado este patrón. Esto significa que al menos hemos tenido una llamada telefónica con él para comprobar sus datos personales.',
      naHead: 'todavía no verificados',
      naTitle: 'Todavía tenemos que verificar este patrón.',
      naText: 'Todavía estamos validación de la identidad personal del arrendador. Tan pronto como lo hemos hecho esto, aparecerá como verificada.'
    },
    uploads: {
      studio: 'Estudio',
      room: 'Habitación',
      apartment: 'Apartamento',
      info: 'información',
      details: 'detalles',
      features: 'Caracteristicas',
      billing: 'Facturación',
      contract: 'Contrato',
      tenants: 'Los inquilinos',
      photos: 'fotos',
      save: 'Guardar Continuar',
      unlist: 'no listados',
      unlistTitle: '¿Seguro que deseas no listados de este anuncio?',
      unlistText: 'Esto significa que la lista suele aparecer en las búsquedas. Sin embargo reservas anteriores aún se pueden ver. Puede poner en venta por el ahorro de nuevo.',
      keep: 'Quédatelo',
      waiting: 'A la espera de cambios en este anuncio ...',
      back: 'Espalda',
      close: 'Cerrar',
      finish: 'Terminar',
      snackbarMessage: 'El listado actualizado correctamente',
      newTenant: 'nuevo inquilino',
      startTyping: 'Empieza a escribir',
      select: 'Seleccionar la ubicación del menú desplegable.',
      ping: 'silbido'
    },
    generalInfo: {
      apartmentsTitle: 'Apartamento Título',
      aptTitleHint: 'Esto aparecerá en su perfil en las búsquedas de los inquilinos de un piso entero.',
      studioTitle: 'estudio Título',
      roomTitle: 'La sala de títulos',
      titleHint: 'Esto aparecerá en su perfil, que sea breve y atractiva',
      studioName: 'Nombre del estudio',
      nameHint: 'P.EJ. JJ123, esto es para su referencia personal para identificar el anuncio.',
      apartmentName: 'Nombre del apartamento',
      roomNumber: 'Número de habitación',
      numberHint: 'P.EJ. 1, este se añadirá a la Casa Nombre.',
      nextAv: 'Próxima fecha disponible',
      successBookings: 'Las reservas de éxito',
      successBookingsHint: '¿Cuántas veces ha sido reservado para este listado antes?',
      description: 'Descripción',
      descriptionHint: 'Dará una breve introducción a su perfil. Sea breve y conciso',
      floor: 'Piso',
      floorHint: '¿En qué piso es su perfil?',
      tenantsText: 'Mostrar inquilino que ha vivido aquí antes, que genera confianza y genera reservas.',
      currentTenants: 'Los inquilinos actuales',
      nameErrReq: 'Se requiere el nombre',
      descriptionErrMax: 'Restringirse a 500 caracteres',
      descriptionErrReq: 'Descripción Se requiere',
      titleErrReq: 'Se requiere título.',
      titleErrMin: 'El título debe tener al menos {número} caracteres.',
      numberErrReq: 'Por favor proporcione un número de la lista.',
      dateErrReq: 'Por favor, proporcione una fecha de disponibilidad.'
    },
    features: {
      addBathroom: '+ Añadir baño',
      bedType: 'bedtype',
      bedTypeOptionNone: 'Ninguna',
      bedTypeOptionSingle: 'Soltero',
      bedTypeOptionDouble: 'Doble',
      bedTypeHint: '¿Qué tipo de cama está allí en este listado?',
      size: 'Tamaño de venta',
      sizeHint: '¿Cómo clasificaría el tamaño de este anuncio?',
      kitchen: 'tipo de cocina',
      girlsOnly: 'chicas onl',
      furniture: 'Mueble',
      commonArea: 'características del área común',
      bedrooms: 'Numero de habitaciones',
      bedroomsHint: '¿Cuántas habitaciones diferentes hay en este listado?',
      features: 'Caracteristicas',
      bedsheets: 'hojas',
      ac: 'C.A',
      fan: 'Ventilador',
      curtains: 'cortinas',
      mattress: 'Colchón',
      pillow: 'Almohada',
      sheets: 'hojas',
      desk: 'Escritorio',
      chair: 'Silla',
      wardrobe: 'guardarropa',
      openHanger: 'Percha abierto',
      shelve: 'Dejar de lado',
      washingMachine: 'Lavadora',
      tv: 'televisión',
      couch: 'Sofá',
      coffeeTable: 'Mesa de café',
      privateBathroom: 'Baño privado',
      outsideWindow: 'fuera de la ventana',
      innerWindow: 'En el interior de la ventana',
      petsAllowed: 'Mascotas permitidas',
      wifi: 'Internet',
      balcony: 'Balcón',
      rooftop: 'azotea',
      elevator: 'Ascensor',
      security: 'Seguridad',
      cleaningService: 'Servicio de limpieza',
      trashService: 'Servicio de basura',
      kitchenOptions: {
        none: 'Ninguna',
        basic: 'instalaciones básicas de cocina',
        full: 'Cocina totalmente equipada'
      }
    },
    billing: {
      rent: 'Alquilar',
      rentHint: '¿Cuál es el alquiler mensual de este anuncio?',
      deposit: 'Depositar',
      depositHint: '¿Cuál es el depósito de este anuncio?',
      utilities: 'Utilidades',
      rentIncluding: 'Alquiler, servicios incluidos',
      utilitiesOptions: {
        included: 'Incluido en el alquiler',
        fixed: 'cantidad fija mensual',
        split: 'Dividir por los inquilinos',
        other: 'Otro'
      },
      utilitiesHint: 'Los inquilinos suelen preferir los servicios públicos fijos incluidos en el precio.',
      utilitiesAmount: 'cantidad Utilidades',
      utilitiesComment: 'Utilidades Comentarios:',
      utilitiesCommentHint: 'Explicar aquí lo que se incluye y lo que no. O explicar cómo se calculan las utilidades.',
      couplesAllowed: 'parejas admitidas',
      couplesExtraUtilities: 'utilidades extra para las parejas',
      couplesExtraUtilitiesHint: 'utilidades extra / alquiler si una pareja reside en el anuncio.',
      priceErrReq: 'Por favor, introduzca una cantidad mensual de alquiler.',
      depositErrReq: 'Por favor, introduzca una cantidad de depósito.'
    },
    contractDetails: {
      minLength: 'longitud mínima del contrato',
      minLengthHint: 'La aceptación de contratos a corto plazo le dará más inquilinos.',
      contractFlexibility: 'flexibilidad contrato',
      contractFlexibilityHint: 'contratos más flexibles que pone mayor en nuestro ranking de búsqueda.',
      contractLanguage: 'el lenguaje del contrato',
      contractLanguageHint: 'Ofreciendo contratos en inglés le pone mayor en nuestro ranking. Tenemos contratos de plantillas disponibles para usted.',
      rules: 'Reglas de casa',
      rulesTexts: {
        noSmoking: 'No Fumar',
        noPets: 'Sin mascotas',
        girlsOnly: 'Sólo niñas',
        noParties: 'No hay partes - la política estricta de ruido',
        noGuests: 'No se permiten invitados durante la noche',
        extensiveIntro: 'Extensa introducción requiere',
        noSublease: 'No se permitió subarrendamiento'
      },
      otherRules: 'otras reglas',
      otherRulesHint: 'Por favor, especifique cualquier otra reglas especiales o cláusulas contractuales que podrían aplicarse para su anuncio.',
      minLengthOptions: {
        one: '1 mes',
        three: '3 meses',
        five: '5 meses',
        twelve: '12 meses',
        open: 'Contrato abierto'
      },
      contractFlexibilityOptions: {
        any: 'Comienza y termina un día cualquiera',
        oneMonth: 'Empezar cualquier día durante un mes',
        firstOr: 'Se inicia en la 1ª o 15 del mes',
        first: 'Se inicia siempre en la primera'
      },
      contractLanguageOptions: {
        english: 'Inglés',
        both: 'Inglés y Chino',
        chinese: 'Sólo chino'
      }
    },
    visual: {
      showTenants: 'Mostrar los inquilinos lo que este listado se parece.',
      addVideo: 'Añadir un vídeo a su perfil es muy importante!',
      video: 'Youtube enlace de vídeo',
      videoHint: 'Adición de un video a aumentar sus posibilidades de alquilar tu piso en un 200%.'
    },
    details: {
      rooms: 'Habitaciones',
      sharedBathrooms: 'Los baños compartidos',
      doubleBed: 'Cama matrimonial',
      singleBed: 'Cama individual',
      noBed: 'No hay cama',
      cancelPol: 'condiciones de cancelación del arrendador',
      studio: 'Estudio',
      contract: 'contrato',
      videoTour: 'TOUR DE VIDEO',
      featureTitle: 'CARACTERISTICAS',
      small: 'Pequeña',
      average: 'Promedio',
      large: 'Grande',
      bathroom: 'Baño',
      kitchen: 'Cocina',
      basicKitchen: 'Esto significa que no hay cocina real, cualquier cosa necesaria para calentar las cosas, no es adecuado para la cocina real. Consulte las imágenes para obtener más detalles.',
      noKitchen: 'No se preocupe, esto es muy común en Taiwán, es la mayor de las veces más baratos para comer fuera que cocinar usted mismo.',
      minLength: 'Longitud mínima del contrato:',
      minLengthLong: 'Por lo general en contratos a largo plazo que pueden subarrendar el contrato cuando va a salir. Hacer ver los detalles de las reglas de abajo para asegurarse.',
      contractType: 'flexibilidad contrato',
      contractLanguage: 'el lenguaje del contrato',
      contractInfo: 'información del contrato',
      bathroomWet: 'Cuarto de baño mojado',
      bathroomDry: 'Baño seco',
      wetBathroom: 'No hay separación entre la ducha y el resto del baño. Esto puede resultar sorprendente a primera.',
      dryBathroom: 'La separación entre la ducha y el resto del baño',
      rentIncluding: 'Alquiler incluyendo los servicios públicos',
      utilitiesSplit: 'Las empresas de servicios públicos deben ser pagados al final de cada mes después de recibir la factura. Va a dividir la factura con los otros inquilinos.',
      couplesNotAllowed: 'Parejas no admitidas',
      moreRules: 'más reglas',
      ruleClean: 'Cada inquilino es responsable de mantener las áreas comunes limpias!',
      ruleDocs: 'Documentos necesarios: pasaporte / ID',
      ruleSchedule: 'Los inquilinos deben seguir un programa de limpieza semanal',
      otherTenants: 'Otros inquilinos de este piso',
      smokingAllowed: 'Fumar está permitido',
      futureTenants: 'compañeros de piso futuras',
      noDateSet: 'Fijar una fecha en su búsqueda para desbloquear esta característica!',
      viewRoom: 'vista de la habitación'
    },
    otherTenants: {
      student: 'Estudiante',
      worker: 'Obrero'
    },
    bottomBar: {
      detailsPrice: 'Los detalles sobre los precios',
      paytoMRA: 'Usted tendrá que pagar a ARM',
      perMonth: '/mes',
      book: 'Libro',
      bookingDates: 'Fechas de la reserva'
    },
    resultsPage: {
      now: 'Ahora',
      noData1: 'Lo sentimos, no podemos encontrar ningún resultado con estos filtros.',
      noData2: 'Trate de eliminar los filtros o mover el mapa para encontrar algunas habitaciones impresionantes',
      removeFilters: 'Borrar filtros',
      dates: 'fechas',
      budget: 'Presupuesto',
      yourBudget: 'su presupuesto',
      budgetHint: 'Seleccione su presupuesto',
      propertyType: 'tipo de propiedad',
      filters: 'filtros',
      orderBy: 'Por fin',
      orderByOptions: {
        oldest: 'Los más viejos primero',
        newest: 'El más reciente primero',
        closestToMid: 'Más cercano a moverse en la fecha',
        distance: 'Distancia al SearchResult',
        priceLtH: 'Precios de barato a caro)',
        priceHtL: 'Precio (de mayor a menor)'
      },
      any: 'alguna',
      propertyTypes: {
        room: 'Habitación en piso compartido',
        studio: 'Estudio'
      },
      min: 'min',
      seeRooms: 'buscar habitaciones',
      seeApartments: 'pisos de búsqueda',
      numberOfRooms: '# habitaciones'
    },
    requestsInbox: {
      bookingRequests: 'Las solicitudes de reserva',
      addListing: '+ Añadir otra lista',
      noCurrentTitle: 'Usted no tiene ningún reservas actuales',
      noCurrentText1: 'Buen trabajo, se han procesado todas las solicitudes de reserva!',
      noCurrentText2: 'Le enviaremos un correo electrónico si tenemos nuevas solicitudes de reservas usted!',
      noCurrentText3: 'Aclamaciones',
      noAcceptedTitle: 'Usted no tiene ninguna reserva aceptada',
      noAcceptedText1: 'No ha aceptado ninguna reserva por el momento',
      noPastTitle: 'Usted no tiene ninguna reserva últimos',
      noPastText1: 'Impresionante / nHas respondió a tiendas de comida para sus reservas en el tiempo!',
      noPastText2: 'Gracias por ser tan sensible!',
      noDeclinedTitle: 'No es necesario que todas las reservas se redujeron',
      noDeclinedText1: 'No ha disminuido las reservas por el momento',
      noCanceledTitle: 'Usted no tiene ningún reservas canceladas',
      noCanceledText1: 'Por lo general, los inquilinos no cancelan una reserva, así que esto es normal.',
      declineTitle: 'Rechazar la solicitud',
      declineReasonLabel: 'Seleccionar por qué tiene que rechazar esta solicitud',
      setNextAv: 'Establecer la próxima disponibilidad',
      setNextAvHint: 'Por favor, introduzca cuando el contrato terminará',
      suggest: 'Sugerir otra de sus habitaciones',
      from: 'desde',
      confirm: 'Confirmar',
      reasonBooked: 'Alguien ya reservó la habitación',
      reasonTenant: 'El inquilino no es adecuado para esta sala',
      onePerson: '1 persona',
      accept: 'Aceptar',
      decline: 'Disminución',
      details: 'detalles',
      realDates: 'movimiento real en las fechas y se van:',
      aboutTenant: 'Sobre el arrendatario:',
      current: 'Corriente',
      accepted: 'Aceptado',
      past: 'Pasado',
      declined: 'disminuido',
      canceled: 'Cancelado',
      warning:"WARNING",
      warnText:"You already have an accepted booking overlapping the dates of this request. Are you sure you want to accept this booking too?",
      acceptBooking:"Accept booking",
    },
    profile: {
      username: 'Nombre de usuario',
      currency: 'preferrence moneda',
      name: 'Nombre',
      nameHint: 'Por favor utilice su nombre completo, como en su pasaporte.',
      birthday: 'Cumpleaños',
      gender: 'Género',
      male: 'Masculino',
      female: 'Hembra',
      passport: 'Número de pasaporte',
      occupation: 'Ocupación',
      occupationHint: '¿Es usted un estudiante o un trabajador?',
      school: 'la escuela de destino / empresa',
      schoolHint: '¿Dónde vas a trabajar o estudiar?',
      about: 'Presentarse al propietario',
      aboutHint: 'Cuanta más información se proporcione, más probable es que obtendrá la habitación!',
      basicProfile: 'perfil básico',
      changeEmail: 'Cambiar e-mail',
      newEmail: 'Nuevo Email',
      save: 'Salvar',
      currency: 'Moneda',
      officialInfo: 'La información oficial',
      personalDescription: 'Descripción personal',
      aboutYou: 'Acerca de ti',
      changeEmailText1: '¿Seguro que quiere cambiar, se envía por correo electrónico {} newemail',
      changeEmailText2: 'Este es un paso no reversible.',
      yourProfile: 'Tu perfil',
      editInfo: 'Editar información',
      deleteAccount: 'Borrar cuenta',
      editProfile: 'Editar perfil',
      cancelPolicy: 'Su nivel de la política de cancelación',
      policies: 'políticas',
      cancelPolHint: 'Vea nuestra página poilicy cancelación o condiciones de uso 12.3 para obtener más información sobre las políticas de cancelación.',
      verified: 'Buen trabajo, que ya se verifican',
      verificationRequested: 'Se ha solicitado la verificación. Nos pondremos en contacto pronto!',
      getVerified: 'Verifícate',
      address: 'Dirección',
      communicationInfo: 'comunicación de la información',
      phone: 'Teléfono',
      line: 'Identificación de línea',
      wechat: 'Identificación WeChat',
      facebook: 'Facebook Enlace',
      preferredMethod: 'método de comunicación preferido',
      preferredMethodHint: '¿Cuál es su método de comunicación preferido?',
      bankInfo: 'información bancaria',
      swift: 'Código SWIFT',
      bankName: 'Nombre del banco',
      bankCode: 'codigo bancario',
      bankAddress: 'dirección del banco',
      flexible: 'Flexible',
      moderate: 'Moderar',
      strict: 'Estricto',
      noRefund: 'No reembolsable',
      emailError: 'Por favor introduce un correo electrónico válido',
      password: 'Contraseña',
      changePassword: 'Cambia la contraseña',
      passwordError: 'La contraseña debe tener al menos 6 caracteres',
      whatsapp: 'WhatsApp',
      lineApp: 'Línea',
      wechatApp: 'WeChat',
      facebookApp: 'Facebook',
      companyId:"Company ID",
      companyName:"Company Name",
      companyInv:"I want a company invoice",

    },
    landlordDashboard: {
      apartmentsTitle: 'sus apartamentos',
      noAptTitle: 'Vamos a crear su primer apartamento',
      noAptText: 'Presiona el botón de abajo para iniciar.',
      addApt: '+ Añadir Apartamento',
      studiosTitle: 'sus estudios',
      noStudioTitle: 'Vamos a crear su primer estudio',
      addStudio: '+ Añadir Estudio',
      unnamedRoom: 'Sin nombre habitaciones',
      edit: 'Editar',
      launch: 'Lanzamiento',
      noTitle: 'Sin embargo título establece',
      manageStudio: 'director del Studio',
      manageApt: 'Manejo de Apartamento',
      addRoom: 'Añadir habitación'
    },
    bookingRequest: {
      bookingSummary: 'Resumen de reserva',
      midDiffText: 'El propietario ha establecido reglas sobre sus fechas de inicio del contrato.',
      dateDiffText1: 'Para ello hemos tenido que cambiar el movimiento en la fecha que aparecerá en el contrato.',
      dateDiffText2: 'No dude en contactar con nosotros si tiene alguna pregunta sobre esto.',
      modDiffText: 'el propietario ha establecido una duración mínima y las reglas con respecto a sus fechas de finalización del contrato.',
      completeRequest: 'Complete su solicitud de reserva',
      importantInfo: '¡Información importante!',
      checkRules: 'Echa un vistazo a todas las reglas de la casa!',
      process: 'proceso de reserva',
      nextSteps: 'Próximos pasos',
      timeline1: 'El propietario tiene',
      timelineBold: '48H',
      timeline2: 'para responder a su petición.',
      afterPayment: 'Una vez que el pago ha sido procesado, la reserva se ha completado y la habitación es suya!',
      whenArriving: 'Al llegar',
      guarantee: 'Garantía My Room Abroad',
      refundText1: 'Tienes',
      refundBold: '24H',
      refundText2: 'para informarnos si su habitación no coincide con la descripción y se devolverá',
      arrival1: 'Cuando se mueve en la habitación, tendrá que',
      arrival2: 'Pagar el depósito',
      arrival3: 'Firmar el contrato de arrendamiento',
      departureTitle: 'Al final de su estancia',
      departure1: 'El arrendador reembolsará el depósito si se mantenía su habitación perfecto y cumplido todos los acuerdos del contrato.',
      departure2: 'Si deja anteriormente, el propietario podría mantener una parte del depósito.',
      rules1: 'Acerca de esta casa',
      agree1: 'Entiendo y estoy de acuerdo',
      agree2: 'estoy de acuerdo',
      agreeToRulesAbove: 'Por favor, estar de acuerdo con las houserules en la última pantalla antes de seguir adelante',
      continue: 'Continuar',
      personalInfo: 'Informacion personal',
      coupon: 'Cupón',
      validateCoupon: 'Validar cupón',
      payment1: 'No tendrá que pagar nada en este momento.',
      payment2: 'Nosotros sólo le cobrará después de su reserva es aceptada.',
      payment3: 'Actualmente estamos implementando nuestro increíble sistema de pago en línea. Pronto estará listo y aún más fácil de pagar su alquiler. En su parte todavía aceptamos transferencias electrónicas en las siguientes monedas. Por favor, seleccione la moneda que desea pagar en.',
      payment4: '¿En qué moneda le gustaría pagar?',
      payment4Hint: 'Los tipos de conversión que obtendrá mediante el pago en otra moneda será diferente de la que se ve en Google.',
      payment5: 'Quiero pagar todas las rentas en esta moneda.',
      confirmBooking: 'Confirmar mi reserva',
      diffDates1: 'Este propietario ha establecido un contrato de duración mínima, que es más largo que su estancia real. <br/> Sus fechas de contrato serán de la siguiente manera: a partir de mediados {} hasta {mod}',
      diffDates2: 'Por favor confirmar su reserva',
      sameDates: 'Sus fechas que están disponibles. Usted contrato será a partir de mediados {} hasta {mod}',
      coupleAsk: '¿Necesita una habitación para 1 persona o para una pareja?'
    },
    auth: {
      confirmPassword: 'Confirmar contraseña',
      resetPassword: 'Restablecer la contraseña',
      accept: 'acepto',
      terms: 'Términos y Condiciones',
      landlord: 'Soy un propietario',
      keepLogged: 'Mantenme conectado',
      createUser: 'Crear usuario',
      usernameErr: 'Introduzca un nombre de usuario',
      emailErrExists: 'Esta direccion de correo electronico ya existe',
      noPassword: 'Por favor, introduzca su contraseña',
      passwordErrorEmpty: 'Por favor, confirme su contraseña',
      passwordErrorMatch: 'Las contraseñas no coinciden',
      changePwd: 'Cambiar la contraseña de',
      newPassword: 'Nueva contraseña',
      forgotPassword: 'Olvidé mi contraseña',
      generalError: 'Correo electrónico y la contraseña no coinciden',
      passwordResetEmail: 'correo electrónico de restablecimiento de contraseña ha sido enviada a su buzón de correo. Por favor, compruebe su buzón de nuevas medidas.',
      userNameRegexErr:'Please use ENGLISH characters only',
    },
    footer: {
      home: 'Casa',
      blog: 'Blog',
      careers: 'carreras',
      faq: 'Preguntas más frecuentes',
      howItWorks: 'Cómo funciona',
      policies: 'políticas',
      subscribe: 'Suscribir',
      getNewsletter: 'Recibe nuestro boletín',
      submit: 'Enviar'
    },
    newListing: {
      listingType: '¿Qué tipo de anuncio ¿Quieres subir?',
      apartmentText: 'Un apartamento es un edificio que incluye los espacios comunes como cocinas y baños, y salas MÚLTIPLES que otros estudiantes pueden alquilar.',
      createApartment: 'crear Apartamento',
      studioText: 'Un estudio es una lista que incluye una sala, cocina y baño, y un solo estudiante es la única persona en la habitación.',
      createStudio: 'crear estudio',
      roomText: 'Las habitaciones pueden ser creada si ya tiene un apartamento que se puede acceder a través de su tablero de instrumentos',
      viewDashboard: 'Ver cuadro de mandos',
      apartmentType: 'contratos de las habitaciones',
      roomContractText: 'Permitir a los inquilinos para alquilar una habitación individual en tu piso. Esto significa que cada sala se puede alquilar para diferentes períodos. Sus anuncios se mostrarán tanto como habitaciones individuales en un pisos compartidos y apartamentos como en su conjunto.',
      completeApartmentText: 'Alquilar el apartamento en su conjunto solamente. Esto no permitirá una habitación para alquilar separadamente. La lista sólo mostrará cuando los usuarios buscan un apartamento completo, no en la sala de búsqueda general.',
      roomContracts: 'contratos de las habitaciones',
      wholeFlat: 'Apartamento entero',
      addTenant: 'Añadir un inquilino',
      howToAddApt: "How to add an apartment?",
      howToAddRoom:"How to add a room?",
      howToAddStudio:"How to add a studio?"
    },
    hiw: {
      hiw: 'Cómo funciona',
      title1: '1. Encontrar la habitación perfecta.',
      text11: 'My Room Abroad simplifica el proceso de búsqueda de la habitación perfecta para su intercambio con su fácil de usar sitio web. Todas las habitaciones están descritos de una manera estructurada. Esto hace que sea fácil comparar ellos basados ​​en la ubicación, precio, características, etc.',
      text12: 'Cada habitación en la plataforma cuenta con una descripción detallada, fotos y muchos incluso tienen videos para mostrar el desgaste de la casa.',
      text13: 'Para garantizar la fiabilidad de la lista que aparece, ARM ha llegado con un sistema de verificación. Existen',
      link1: '4 niveles de verificación',
      text14: '. Usted puede encontrar el nivel de verificación de la habitación en la sección de descripción.',
      text15: 'My Room Abroad tiene cuatro niveles diferentes de verificación:',
      li1: 'sin embargo, no ha sido verificada: Significa que todavía estamos buscando confirmar la información personal del propietario. No llamar ni cumplimos con este propietario. No podemos asegurar su confiabilidad.',
      li2: 'Verificado: Significa que ya hemos verificado dirección de correo electrónico del propietario, número de teléfono y de identificación emitida por el gobierno. Los hemos llamado ni ellos conocido en persona y consideramos que sean dignos de confianza',
      li3: 'Visitado: Significa que alguien del equipo de la My Room Abroad ha visitado personalmente y fotografiado y / o tomó un vídeo de la vivienda',
      li4: 'Exclusivo: Significa un lugar es totalmente administrada por My Room Abroad y sólo está disponible en nuestra plataforma.',
      text16: 'Puede comprobar el nivel de verificación de cada departamento en cada presentación en la sección Descripción.',
      title2: 'Solicitar la habitación',
      text21: 'Una vez que encontramos la habitación ideal para su estancia en el extranjero, puede reservarlo haciendo clic en el botón de “libro” en la esquina inferior derecha.',
      text22: 'Una vez que al hacer clic en este botón, tendrá que configurar su movimiento en y salir fechas. Prestar atención, algunos propietarios establecen limitaciones en cuanto a duración del contrato. La plataforma permite generar automáticamente fechas de inicio del contrato y. Estas fechas pueden variar de un movimiento de entrada / salida fechas que ha establecido. Después, tendrá que ponerse de acuerdo sobre las reglas de la casa, rellene sus datos personales y las informaciones del pago.',
      text23: 'Una vez que haya llenado toda la información, la petición de reserva se envía al propietario. A la espera de la aceptación propietario, no se puede hacer otra reserva.',
      text24: 'El propietario tiene 48H para responder a su petición.',
      text25: 'Su pago sólo se cargará una vez que la solicitud es aceptada.',
      text26: 'Cuando la solicitud es aceptada la habitación es suya!',
      title3: 'Hacer el pago',
      text31: 'Una vez que su reserva ha sido aceptada, a continuación, puede proceder el primer mes de alquiler y My Room Abroad pago Cargo por servicio de una sola vez. Puede segura hacer el pago con tarjeta de crédito o transferencia bancaria a My Room Abroad, una empresa europea, a través de herramientas de certificados en su propia moneda.',
      text32: 'My Room Abroad luego transferir el alquiler al propietario en su moneda.',
      text33: 'Tienes 48 horas para hacer este pago.',
      title4: 'Póngase en contacto con el propietario',
      text41: 'Una vez realizado el pago, todo está en orden. De haber conseguido con éxito su habitación en el extranjero. Usted recibirá las informaciones de contacto arrendadores. Póngase en contacto con el propietario para arreglar detalles de su llegada.',
      text42: 'Ahora usted puede preparar su estancia en el extranjero sabiendo que va a tener un buen lugar para dormir cuando llegue! Por otra parte, usted es ahora parte de la comunidad My Room Abroad. Siéntase libre de unirse a nuestro grupo de Facebook donde los eventos de partes frescas a actividades al aire libre o de campamento en las montañas, se organizan para usted. Puedes conocer a personas afines, que en última instancia formar una familia en el extranjero en esta aventura que cambia la vida.',
      title5: 'Firma el contrato',
      text51: 'El día de su llegada al apartamento (exacto cumplimiento de lugar y horario a convenir con el propietario de antemano), el propietario le dará la bienvenida. El contrato de arrendamiento, siempre traducida Inglés, se firmará entre el usuario y el propietario.',
      text52: 'Después de firmar el contrato, recibirá las llaves. A continuación, puede proceder al pago del depósito. Este pago se puede hacer con tarjeta de crédito o transferencia bancaria en su propia moneda o en efectivo.',
      title6: 'Al final del contrato',
      text61: 'El depósito es reembolsado por el propietario.',
      learnMore: 'Aprende más',
      q1: '¿Qué pasa si la habitación no es como se anuncia en la plataforma ??',
      a1: 'Si se produjera alguna diferencia entre el listado en ARM y la realidad, tienes 24 horas, a partir de su movimiento en la fecha, para notificar a ARM.',
      a2: 'Un miembro del equipo evaluará su queja lo más rápido posible.',
      a3: 'Si la queja es válida, se le reembolsará My Room Abroad y le ayudará a encontrar otra habitación (no se cobrará ninguna cuota de servicio).'
    },
    aboutUs: {
      title: 'Sobre nosotros',
      subTitle: 'ARM es una plataforma de ayudar a los internacionales a reservar su habitación en el extranjero.',
      t1: 'Todo comenzó en 2015. Los destinos del semestre de intercambio se acaba de publicar en la universidad. Sebastien y Vincent, estaban encantados de vivir en un país extranjero por un semestre. Tenían un destino diferente, pero ambos tenían el mismo momento doloroso para encontrar una habitación en el extranjero. Es entonces cuando se dieron cuenta de lo complicado de un proceso que era. Se enfrentaron a muchos propietarios falsos que tratan de timarles, página web en un lenguaje descodificable y por lo general no estructurados. Al final encontraron un bonito apartamento compartido cuando estaban en el país, pero no fue sin mucho dolor y el estrés.',
      t2: 'Es decir, cuando decidieron que querían ayudar Internacionales futuros estudiantes que van a soñar destinos. Ellos querían simplificar el proceso de búsqueda de alojamiento para ellos para que puedan vivir su experiencia en el extranjero al máximo sin preocupaciones de vivienda.',
      t3: 'Casi dos años más tarde, después de graduarse, decidieron llevar el proyecto al siguiente nivel. Ellos desarrollaron la plataforma para conectar a los estudiantes internacionales con los propietarios de confianza. El primer ensayo se realizó en Taipei, destino del intercambio de Sebastien, con 150 habitaciones y sus asociaciones con universidades anteriores en Taipei y en Europa. Fue muy exitoso y My Room Abroad nació en febrero de 2017.',
      t4: 'Durante el próximo semestre, la compañía ha aumentado a una velocidad alucinante, ayudando cada vez más estudiantes a encontrar vivienda en el extranjero. La plataforma se elevó a más de 850 salas de la lista, 9 asociaciones con las más grandes universidades y escuelas de idiomas en Taipei y una red de 12 universidades europeas asociadas.',
      t5: 'Hoy en día, la ARM es más que una plataforma de vivienda, se trata de una familia en el extranjero. My Room Abroad hace que su misión de ayudar a los estudiantes en cada paso del camino asegurándose de que gastan una experiencia inolvidable en el extranjero sin ningún problema de vivienda. Los estudiantes internacionales están acompañados de la reserva de su habitación a su movimiento salida. Por otra parte, se benefician de la comunidad de los estudiantes internacionales de My Room Abroad. A través de esta comunidad, el derecho de los grupos de Facebook y eventos, que puedan cumplir con personas de ideas afines, que en última instancia formar una familia en el extranjero en esta aventura que cambia la vida. Esto permitirá que todos los estudiantes para crear su personal mezcla de aventura, fiestas, viajes y trabajo en red.',
      t6: 'En My Room Abroad, estamos convencidos de que nada es más enriquecedor que una experiencia en el extranjero y estamos aquí para ayudarle a obtener el máximo provecho de ella!'
    },
    refund:{
      title:"REFUND POLICY",
      subtitle11:"Our refund policy applies if one of the 3 following options occurs:",
      t111:"A User decides to cancel the booking (after the Booking Request is accepted by the Accommodation Provider). The Refund Policy depends on the Cancellation Policy applicable to the booked Accommodation, displayed on each listing, as stated in our Terms of Service;",
      t112:"An Accommodation Provider decides to cancel the Booking (after Booking Request is accepted by the Accommodation Provider): the User is entitled to a full refund, in this case 100%, of the 1st Rental Payment, One-time Service Fee and Holding Deposit (if applicable); or",
      t113:"A User submits a complaint and after assessment by My Room Abroad, it is deemed to be valid.",
      subtitle12:"For a complaint to be valid under 1.1 (c) of our Refund Policy, the User must ensure that all the following conditions are adhered to:",
      t121:"The complaint falls within one or more of the 'Covered Circumstances' (as defined in 1.3);",
      t122:"The User paid for their accommodation on the My Room Abroad Website. For the avoidance of doubt My Room Abroad is not liable to the User in respect of any payments made outside of the My Room Abroad Website;",
      t123:"The User took all the appropriate steps to resolve the issue with the Accommodation Provider;",
      t124:"The complaint is submitted by the User that made the Booking Request and not by a third party;",
      t125:"The User notifies My Room Abroad within 24 hours after the Move In Date of such complaint and cooperates fully with My Room Abroad;",
      t126:"The user has not directly or indirectly caused the circumstance which is the basis of your claim (including but not limited to action, omission or negligence on the part of the User);",
      t127:"The User provides us with compelling evidence (photographs, video, or other evidence) that their claim falls within the 'Covered Circumstances' (as defined in 1.3);",
      t128:"The User sends a written claim to My Room Abroad with proof of identification and a detailed description of the circumstances surrounding the claim;",
      t129:"The complaint is submitted via email to Hello@myroomabroad.com.",
      subtitle13:"In respect of clause 1.2 the following situations are deemed to constitute “Covered Circumstances”:",
      t131:" Invalid Advertisement: it is not legal for the Accommodation Provider to enter into a Tenancy Agreement in respect of the Accommodation;",
      t132:"Inaccessible Accommodation:The User has paid for the Accommodation in accordance with the Terms of Service, and has complied with the Accommodation Provider’s requirements as stipulated at the time of the Booking Request, but the User has been denied entry to the Accommodation as a result of intentional and wrongful conduct of the Accommodation Provider (which is deemed to include Accommodation Providers bankruptcy, insolvency or fraudulent activity), or the Accommodation is not available for rent. The User will not be compensated by My Room Abroad for any delay in accessing the Accommodation during the rental period;",
      t133:"Misrepresented Accommodation: the Accommodation has material differences or defects (as defined in Section 1.3) compared with the advertised Accommodation, and :the user is unable to rent the Accommodation as intended. The meaning of “material differences or defects” shall be determined in My Room Abroad sole discretion and, among other exceptions, it shall not cover minor differences in the location of the Property advertised and the actual location of the Property; the presence or availability of local attractions or specific means of transportation; or any other external factors related to the surroundings of the property.",
      t134:"In respect of a complaint, My Room Abroad shall complete the assessment of its validity in a reasonable time frame and will keep the User informed of any decisions or additional documentation required to process the claim. Where additional documentation is requested from the User, this must be provided without undue delay.",
      t135:"My Room Abroad has sole discretion to make decisions regarding the claim (including the amount to be refunded to the User if applicable) and these decisions shall be final and binding on the User. For the avoidance of doubt, My Room Abroad will not consider making a refund where the User is in material breach of the Terms of Service.",
    },

    privacy: {
      privacyPolicy: "Privacy policy",
      heading: "Introduction",
      intro1: "This page is designed to help you understand why and how we use personal data.",
      intro2: "By personal data we mean information that relates to a living individual and can identify or be identified with that individual.",
      intro3: "My Room Abroad provides an online accommodation booking service, which allows them to browse through thousands of properties around the world.",
      intro4: "To do so, we have relationships with tenants, landlords, affiliates (referrers and/or partner organisations) and our suppliers. We process different personal data for each of those categories of person, and we do so for different purposes.",
      title1: "Who operates My Room Abroad and how do I contact them?",
      t11: "The data controller of your information is Lime Tech SPRL, a company with headquarters in Brussels.",
      t12: "We are limited company registered in Belgium with the VAT number BE.0537.421.768 and our registered address is Lime tech SPRL, Marius Renardlaan 27A b327, 1070, Brussels, Belgium. ",
      t13: "If you have any questions or concerns about the information on this page, or about what we do with personal data, you should email hello@myroomabroad.com.",
      title2: "How does My Room Abroad use my personal data as a tenant?",
      t21: "In summary, we use personal data relating to tenants to:",
      l201: "To allow prospective tenants to easily search for flats matching their criteria on return visits.",
      l202: "To provide the landlord with information about a prospective tenant to help them determine whether the prospective tenant’s eligibility to rent their property.",
      l203: "To provide management information to My Room Abroad's partners (e.g. partnered universities or employers).",
      l204: "To ensure the prospective tenant is only offered properties that meet their access requirements.",
      l205: "To process payments from the tenant.",
      l206: "To follow up on bookings which are not able to proceed with, e.g. because the landlord rejects the application or there is an availability issue.",
      l207: "To send marketing to you.",
      l208: "To run its business",
      l209: "To ensure the completeness of a listing on the platform",
      subtitle21: "How does My Room Abroad use personal data to allow prospective tenants to easily search for properties?",
      t211: "As you use your My Room Abroad account to book accommodation you will provide us with your desired location, the dates on which you wish to reside in the property and what your price range will be. My Room Abroad will keep a record of this information and we will be able to present you with suggested properties that it believes match your criteria.",
      subtitle211: "What personal data is used and where is it obtained from?",
      t2111: "We obtain your preferred location(s), your budget and your preferred dates when you first sign up for the service and/or when you change your search criteria on the service.",
      subtitle212: "What is our legal basis?",
      t2121: "Our legal basis for retaining this information is our legitimate interest in ensuring that you are able to easily repeat your search for properties.",
      subtitle213: "Who do we share personal data with for this purpose?",
      t2131: "We do not share this data with anyone for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle22: "How does My Room Abroad use personal data to check whether the tenant is eligible to rent a property?",
      t220: "Landlords seeking to let their properties will have their own discretionary eligibility criteria for tenants.",
      subtitle221: "What personal data is used and where is it obtained from?",
      t2211: "When you submit your booking request, we ask for your full name, email address, passport number, your nationality, age, occupation, gender and an introduction about yourself.",
      subtitle222: "What is our legal basis?",
      t2221: "Our legal basis is that this process is a necessary preparatory step for the landlord to enter into a tenancy agreement with you.",
      t2222: "Without this information, the landlord may not be able to consider entering into a tenancy agreement with you.",
      subtitle223: "Who do we share personal data with for this purpose?",
      t2231: "This information will be shared with the relevant landlord so that they can decide whether you meet their tenant criteria.",
      t2232: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle23: "How does My Room Abroad use personal data to provide management information to its partners?",
      t230: "Our affiliate programme allows our partners to refer prospective tenants to My Room Abroad and potentially receive a commission payment for each tenant they introduce that goes on to make a booking. We therefore provide them with information relating to the number of people they have referred that have gone on to make a booking with My Room Abroad.",
      subtitle231: "What personal data is used and where is it obtained from?",
      t2311: "When you make your booking, we ask for your place of work and/or study.",
      subtitle232: "What is our legal basis?",
      t2321: "Our legal basis is our legitimate interest in providing our affiliates with accurate information to support the commission payment due to them.",
      subtitle233: "Who do we share personal data with for this purpose?",
      t2331: "Your personal data will not be shared with affiliates, however aggregated data (e.g. a number of students) that does not identify you will be.",
      t2332: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle24: "How does My Room Abroad use personal data to ensure that the tenant is only offered properties that meet their access needs?",
      t240: "We understand that some of our tenants may have certain access requirements. Where provided, we use these details to help you find an appropriate property.",
      subtitle241: " What personal data is used and where is it obtained from?",
      t2411: "At the time of booking, we ask you to provide us with any information about any access requirements you have.",
      subtitle242: "What is our legal basis?",
      t2421: "Our legal basis is that this is a necessary preparatory step to you entering into a contract with the relevant landlord. Without this information, the landlord would not be able to confirm that the property meets your requirements. To the extent that this data is a special category of personal data (in that it may imply a medical condition), we rely on is explicit consent.",
      subtitle243: "Who do we share personal data with for this purpose?",
      t2431: "This information will be shared with the relevant landlord so that they can confirm whether or not the property meets your requirements.",
      t2432: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle25: "How does My Room Abroad use personal data to process payments from the tenant?",
      subtitle251: "What personal data is used and where is it obtained from?",
      t2511: "When you finalise a booking with us, and make an online payment via credit card, we will collect payment details include name, billing address, credit card number, credit card expiry date and their card verification value number.",
      t2512: "My Room Abroad itself does not have access to this data as it is passed straight to our payment provider",
      subtitle252: "What is our legal basis?",
      t2521: "Our legal basis is that this processing is necessary for the performance of the contract between you and the landlord. Without this information it would not be possible to finalise your booking.",
      subtitle253: "Who do we share personal data with for this purpose?",
      t2531: "This data will be sent directly to our payment processor, (e.g. Stripe) to ensure that monies are withdrawn from your account and deposited into ours, and then the landlord’s.",
      subtitle26: "How does My Room Abroad use personal data to follow-up on bookings which have been unable to proceed?",
      t260: "Where you booking does not go through, for instance because the landlord no longer has availability, we might contact you to help you find an alternative.",
      subtitle261: "What personal data is used and where is it obtained from?",
      t2611: "If we contact you, we may use your name, email address, location, budget, preferred dates, date of birth, occupation, gender, details of nationality, place of work and/or study, biography and details of access requirements, if any.",
      t2612: "All of this information is obtained from your original booking request.",
      subtitle262: "What is our legal basis?",
      t2621: "Our legal basis is our legitimate interest in ensuring tenants are able to find an alternative property when a booking falls through.",
      subtitle263: "Who do we share personal data with for this purpose?",
      t2631: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle27: "How does My Room Abroad use personal data for marketing?",
      t270: "Please see the 'How does My Room Abroad use personal data for marketing' section below.",
      subtitle28: "How does My Room Abroad otherwise use personal data in running its business?",
      t280: "Please see the 'How does My Room Abroad otherwise use personal data in running its business' section below.",
      subtitle29: "How does My Room Abroad use personal data to ensure to ensure the completeness of a listing on the platform?",
      t290: "Each listing contains a 'Current tenants' section. We use ‘data from previous and current tenants (user of the My Room Abroad platform) of a specific flat, for new users to be able to know with who they would live if they book this flat.",
      subtitle291: "What personal data is used and where is it obtained from?",
      t2911: "When successfully making a booking, your gender, nationality and occupation will be shown on the specific listing for other users to see who else rents this flat.",
      subtitle292: "What is our legal basis?",
      t2921: "Our legal basis is our legitimate interest in providing our affiliates with accurate information to support the commission payment due to them.",
      subtitle293: "Who do we share personal data with for this purpose?",
      t2931: "This information will be shared with any user checking the listing of the specific flat.",
      title3: "How does My Room Abroad use my personal data as a landlord?",
      t30: "In summary we use personal data relating to landlords:",
      l301: "To process payments to landlords.",
      l302: "To administer bookings, including populating the advert for the landlord's room, listing it when it is available, processing bookings, etc.",
      l303: "To verify the accuracy of the information provided by the landlord.",
      l304: "To send marketing to you.",
      l305: "To run its business.",
      t31: "You can find out more about how we do so by clicking on the relevant heading below.",
      subtitle31: "How does My Room Abroad use personal data to process payments to landlords?",
      t310: "When a tenant pays us for a booking, we pay the landlord amounts due, less our fees, by bank transfer.",
      subtitle311: "What personal data is used and where is it obtained from?",
      t3110: "We use the bank account details and billing address provided in your profile as a landlord on the My Room Abroad platform.",
      subtitle312: " What is our legal basis?",
      t3120: "Our legal basis is that this processing is necessary to perform our contract with you. Without this information we would not be able to pay you.",
      subtitle313: "Who do we share personal data with for this purpose?",
      t3130: "We will share this information with our bank in order to process monies owed to you.",
      t3131: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle32: "How does My Room Abroad use personal data to administer bookings?",
      subtitle321: "What personal data is used and where is it obtained from?",
      t3210: "We will use the details provided by you when you created your account and/or added a property listing, including: name, email address, room/property details, property address, amenities, contract details, house rules, whether you are a resident landlord, tenant preferences, property availability and any images, video or free text volunteered by you.",
      subtitle322: "What is our legal basis?",
      t3220: "Our legal basis is that this processing is necessary to perform our contract with you. Without this information we would not be able to publish your property listing.",
      subtitle323: "Who do we share personal data with for this purpose?",
      t3230: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle33: "How does My Room Abroad use personal data to verify the accuracy of information provided by the landlord?",
      t330: "We may, where we arrange a visit to take photographs of your property, provide personal data to the photographer to allow them to verify the accuracy of your listing.",
      subtitle331: "What personal data is used and where is it obtained from?",
      t3310: "We will use the details provided by you when you created your account and/or added the relevant property listing, including: name, email address, room/property details, property address, amenities, house rules, whether you are a resident landlord, tenant preferences, property availability and any images, video or free text volunteered by you.",
      subtitle332: "What is our legal basis?",
      t3320: "Our legal basis is our legitimate interest in verifying that listings displayed on My Room Abroad are accurate.",
      subtitle333: "Who do we share personal data with for this purpose?",
      t3330: "We share this data with the photographer who visits your property.",
      subtitle34: "How does My Room Abroad use personal data for marketing?",
      t340: "Please see the 'How does My Room Abroad use personal data for marketing' section below.",
      subtitle35: "How does My Room Abroad otherwise use personal data in running its business?",
      t350: "Please see the 'How does My Room Abroad otherwise use personal data in running its business' section below.",
      title4: "How does My Room Abroad use personal data for marketing?",
      t40: "In summary, regardless of whether you are a landlord or tenant, we use personal data:",
      l401: "To market our service to tenants or landlords via email, including sending our newsletters.",
      l402: "To tailor marketing to the recipient’s likely interests.",
      l403: "To target display advertising to particular demographics of person on advertising platforms (e.g. Facebook, Google).",
      l404: "To target display adverts on other websites to particular people based on the pages of our website that they have viewed (re-targeting).",
      l405: "To create 'look-a-like' audiences on the advertising networks that we use.",
      t401: "You can find out more about how we do so by clicking on the relevant heading below.",
      subtitle41: "How does My Room Abroad use personal data to market its services to tenants or landlords via email, including sending its newsletter?",
      subtitle411: "What personal data is used and where is it obtained from?",
      t4110: "Where you are a tenant or landlord, we use the name and email address you provided when you signed up with My Room Abroad.",
      subtitle412: "What is our legal basis?",
      t4120: "When you are a tenant or landlord, our legal basis is our legitimate interest in marketing our service. We will only market similar services and we always offer the opportunity for you to opt-out at the time we collected your email address, and on each email we send thereafter.",
      subtitle413: "Who do we share personal data with for this purpose?",
      t4130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle42: "How does My Room Abroad use personal data to tailor marketing to the recipient’s likely interests?",
      t420: "We will use your personal data to send particular marketing campaigns to you based on attributes you have. For instance, if you are a tenant we might send a particular marketing campaign to a subset of our registered tenants based on their location preferences and budget.",
      subtitle421: "What personal data is used and where is it obtained from?",
      t4210: "We may use any of the information we hold about you for this purpose, but most typically we target our marketing based on your preferred location (if a tenant), the location of your properties (if a landlord).",
      subtitle422: "What is our legal basis?",
      t4220: "Our legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with our services, therefore reducing our cost conversion when marketing our service.",
      subtitle423: "Who do we share personal data with for this purpose?",
      t4230: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle43: "How does My Room Abroad use personal data to display advertising for particular demographics on advertising platforms?",
      t430: "Advertising platforms such as Google and Facebook allow us to target our advertisements to particular types of user, for instance based on their age, location and interests.",
      subtitle431: "What personal data is used and where is it obtained from?",
      t4310: "Adverts are targeted based on the personal data held by the relevant advertising network about you.The advertising networks do not give us access this data, they only give us the ability to target our advertisements based on characteristics of a group such as age bracket or location.",
      subtitle432: "What is our legal basis?",
      t4320: "Our legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with our service, therefore reducing our cost conversion when marketing our service.",
      t4321: "You can object to this processing by changing your privacy settings on the relevant advertising network.",
      subtitle433: "Who do we share personal data with for this purpose?",
      t4330: "My Room Abroad has no access to your demographic information, this is held by the relevant advertising platform (Facebook or Google).",
      subtitle44: "How does My Room Abroad use personal data to display adverts to particular data subjects?",
      t440: "As you use our service, our advertising networks (Google and Facebook) will monitor and record the pages you visit on our website via a tracking pixel or cookie so that we can ask the advertising networks to show you advertisements based on the pages you visited on our website.",
      t441: "For instance, we might ask Facebook to show a particular advert to users who have recently searched for accommodation in a particular city on our website.",
      subtitle441: "What personal data is used and where is it obtained from?",
      t4410: "We will include code within our website provided by the advertising networks that either places a cookie on your device, or that includes an invisible image, both of which allow the advertising network to display adverts to you based on the pages of our website that you have visited.",
      subtitle442: "What is our legal basis?",
      t4420: "Our legal basis is consent.",
      t4421: "You can withdraw your consent at anytime by contacting us, or by changing your privacy settings on the relevant advertising network.",
      subtitle443: "Who do we share personal data with for this purpose?",
      t4430: "As the cookie or tracking pixel is set using the advertising networks’ code, this data is shared directly with them, and My Room Abroad does not have direct access to it.",
      subtitle45: "How does My Room Abroad use personal data to create 'look-a-like' audiences on advertising networks?",
      t450: "My Room Abroad’s advertising networks (e.g. Google and Facebook) allow it to upload customer email addresses, which the advertising network will match with its own database.",
      t451: "The advertising network will look at the demographics of those people based on the data it holds, and allow My Room Abroad to show adverts to people that share similar characteristics.",
      t452: "My Room Abroad will always ask the advertising platform to exclude the people used to create the 'look-a-like' audience from being displayed an advert targeted in this way.",
      subtitle451: "What personal data is used and where is it obtained from?",
      t4510: "We will use the email address you provided to us when signing up on My Room Abroad.",
      subtitle452: "What is our legal basis?",
      t4520: "Or legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with a booking, therefore reducing our cost conversion when marketing our service.",
      t4521: "You can object to this processing by changing your privacy settings on the relevant advertising network.",
      subtitle453: "Who do we share personal data with for this purpose?",
      t4530: "This data is shared with our advertising networks (Google and Facebook) for this purpose.",
      title5: "How does My Room Abroad use my personal data as a supplier?",
      subtitle51: "How does My Room Abroad use personal data to procure and manage services provided by suppliers?",
      subtitle511: "What personal data is used and where is it obtained from?",
      t5110: "We may use all personal data we receive from you, for instance name, address, email address, telephone number and, except where an employee of corporate suppliers: bank account details, details of payments made and received and contracts.",
      t5111: "We will also generate personal data about you, for instance internal correspondence about your performance.",
      subtitle512: "What is our legal basis?",
      t5120: "Our legal basis is our legitimate interest in managing our relationship with you as a supplier.",
      subtitle513: "Who do we share personal data with for this purpose?",
      t5130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      title6: "How does My Room Abroad use my personal data as a job applicant?",
      t60: "If you apply for a job at My Room Abroad, we may use your personal information:",
      l601: "To correspond with you in relation to your application.",
      l602: "To assess your suitability for the role you have applied for.",
      l603: "To confirm your eligibility to work.",
      t61: "You can find out more about how we do so by clicking on the relevant heading below.",
      subtitle61: "How does My Room Abroad use personal data correspond with you in relation to your application?",
      subtitle611: "What personal data is used and where is it obtained from?",
      t6110: "We will keep letters, CV and emails sent to and received from you.",
      subtitle612: "What is our legal basis?",
      t6120: "Our legal basis is that this processing is a necessary preparatory step to entering into a contract of employment with you.",
      subtitle613: "Who do we share personal data with for this purpose?",
      t6130: "This information has no intended recipient. We will retain this information.",
      t6131: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle62: "How does My Room Abroad use personal data to assess your suitability for the role you have applied for?",
      t620: "My Room Abroad keeps a record of your application, including your application itself, interview notes, references and other information collected during the application process in order to consider your application.",
      subtitle621: "What personal data is used and where is it obtained from?",
      t6210: "My Room Abroad keeps a record of your application, including your application itself (obtained from you or through an agent, interview notes (created by our staff), references (obtained from the referees listed in your application) and other information collected during the application process.",
      subtitle622: "What is our legal basis?",
      t6220: "The processing of this personal data is a necessary preparatory step to possibly entering into a contract of employment with you. Without this processing, we would not be able to proceed with your application.",
      t6221: "Where your application file contains special categories of personal data, our legal basis is explicit consent.",
      subtitle623: "Who do we share personal data with for this purpose?",
      t6230: "We may share this data to the extent necessary to request references from the referees you listed in your application.",
      t6231: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle63: "How does My Room Abroad use personal data to confirm your eligibility to work?",
      subtitle631: "What personal data is used and where is it obtained from?",
      t6310: "A photocopy of your ID document, including a visa if relevant, taken from an original you have provided to us.",
      subtitle632: "What is our legal basis?",
      t6320: "Our legal basis is that the processing is necessary to comply with legal obligations imposed on us.",
      t6321: "To the extent that the data includes special categories of data, our legal basis is that the processing is necessary to comply with a legal obligation in the context of employment law.",
      subtitle633: "Who do we share personal data with for this purpose?",
      t6330: "We do not generally share this data for this purpose unless requested by a relevant authority and with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      title7: "How does My room Abroad otherwise use personal data in running its business?",
      t70: "In summary, regardless of whether you are a landlord or tenant, we use personal data:",
      l701: "To allow tenants and landlords to login to the My Room Abroad website.",
      l702: "To inform the tenant or landlord (as the case may be) of the other party's identity, so that each can confirm the other's identity on check-in.",
      l703: "To manage bookings, including keeping the tenant/landlord up to date about their bookings.",
      l704: "To obtain feedback from tenants and landlords about the My Room Abroad service.",
      l705: "To ask tenants and landlords to complete surveys for market research purposes.",
      l706: "To maintain financial records.",
      l707: "To train our staff.",
      l708: "To deal with queries and complaints.",
      l709: "To bring or defend legal claims.",
      t71: "You can find out more about how we do so by clicking on the relevant heading below.",
      subtitle71: "How does My Room Abroad use personal data to allow you to log in to the My Room Abroad website?",
      subtitle711: "What personal data is used and where is it obtained from?",
      t7110: "We will use the email address and password you provided when you signed up for My Room Abroad.",
      subtitle712: "What is our legal basis?",
      t7120: "Our legal basis is our legitimate interest in authenticating our users.",
      subtitle713: "Who do we share personal data with for this purpose?",
      t7130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle72: "How does My Room Abroad use personal data to allow you to verify the identity of either the landlord or tenant?",
      subtitle721: "What personal data is used and where is it obtained from?",
      t7210: "We will use the name you provided to us when you signed up for My Room Abroad.",
      subtitle722: "What is our legal basis?",
      t7220: "Our legal basis is our, the tenant’s and the landlord’s legitimate interest in ensuring the tenant and landlord are dealing with the correct person on check in.",
      subtitle723: "Who do we share personal data with for this purpose?",
      t7230: "We will share the tenant’s name with the landlord, and the landlord’s name with the tenant.",
      t7231: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle73: "How does My Room Abroad use personal data to follow-up on abandoned bookings and listings?",
      subtitle731: "What personal data is used and where is it obtained from?",
      t7310: "Where you only partially complete a booking or listing.",
      subtitle732: "What is our legal basis?",
      t7320: "Our legal basis is our legitimate interest in reducing the number of abandoned bookings or listings.",
      subtitle733: "Who do we share personal data with for this purpose?",
      t7330: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle74: "How does My Room Abroad use personal data to obtain feedback from tenants and landlords about its services?",
      t740: "We collect your feedback via email or during face to face meeting.",
      subtitle741: "What personal data is used and where is it obtained from?",
      t7410: "We will use the name and email address you provided when signing up for My Room Abroad.",
      subtitle742: "What is our legal basis?",
      t7420: "Our legal basis is our legitimate interest in receiving feedback and improving our service.",
      subtitle743: "Who do we share personal data with for this purpose?",
      t7430: "My Room Abroad will share this information on its platform.",
      subtitle75: "How does My Room Abroad use personal data to ask tenants and landlords to complete surveys for market research purposes?",
      subtitle751: "What personal data is used and where is it obtained from?",
      t7510: "We use the name and email address provided to us when you signed up on My Room Abroad.",
      subtitle752: "What is our legal basis?",
      t7520: "Our legal basis is our legitimate interest of obtaining feedback from our users in order to improve the service we offer.",
      subtitle753: "Who do we share personal data with for this purpose?",
      t7530: "My Room Abroad will share this information with its customer survey partner, who will send you an invitation to complete a review on our behalf.",
      t7531: "Our survey partner will then collect responses and share aggregated information with us that does not identify you.",
      subtitle76: "How does My Room Abroad use personal data to maintain its financial records?",
      subtitle761: "What personal data is used and where is it obtained from?",
      t7610: "We generate records of payments made to and from you.",
      subtitle762: "What is our legal basis?",
      t7620: "Our legal basis is that the processing is required to comply with legal obligations imposed on us by law and by government agencies.",
      subtitle763: "Who do we share personal data with for this purpose?",
      t7630: "We may share this information with our financial software provider, Waves, but otherwise we will only share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle77: "How does My Room Abroad use personal data to deal with queries and complaints?",
      subtitle771: "What personal data is used and where is it obtained from?",
      t7710: "We may use any personal data we hold about you to deal with queries and complaints, but typically this will include booking details, correspondence between us, and information we generate in investigating your query or complaint.",
      subtitle772: "What is our legal basis?",
      t7720: "Our legal basis is our legitimate interest in ensuring queries and complaints are resolved.",
      t7721: "Without this processing, we would not be able to address your query or complaint.",
      subtitle773: "Who do we share personal data with for this purpose?",
      t7730: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
      subtitle78: "How does My Room Abroad use personal data to bring or defend legal claims?",
      subtitle781: "What personal data is used and where is it obtained from?",
      t7810: "We may use any of the personal data we hold about you to bring or defend legal claims, including correspondence, payment records and details of services we supplied to you or that you supplied to us.",
      subtitle782: "What is our legal basis?",
      t7820: "Our legal basis is our legitimate interest in bringing and defending potential legal claims.",
      subtitle783: "Who do we share personal data with for this purpose?",
      t7830: "We may share this data with external advisors for the purposes of obtaining advice from them in relation to a claim.",
      title8: "Who does My Room Abroad share personal data with?",
      t80: "Internally, My Room Abroad may grant access to personal data for the purposes of staff training so that they may carry out their role.",
      t81: "Externally, My Room Abroad may share personal data with third parties for specific purposes, as set out in the sections above. It may also from time to time share personal data with the following categories of recipients:",
      t82: "its service providers, for instance:",
      t83: "the companies that host and manage our IT infrastructure",
      t84: "companies that provide us with cloud based IT systems, such as our CRM system or email delivery system",
      t85: "its external advisors, for instance IT consultants, accountants and lawyers.",
      t86: "Where we share personal data with service providers we will always ensure that the service provider is committed contractually to only use personal data in compliance with My Room Abroad’s instructions and data protection law",
      t87: "its regulators, law enforcement, intelligence services and other government authorities, where they require us to do so",
      t88: "potential buyers of or investors in our business where necessary in connection with a due diligence exercise, under appropriate confidentiality agreements (such as NDA).",
      title9: "What are my rights in relation to My Room Abroad processing my personal data and how do I exercise them?",
      t90: "The law gives you certain rights in respect of the information that we hold about you. Below is a short overview of those rights.",
      subtitle91: "Access.",
      t910: "You have the right to a copy of the personal data that we hold about you. We may make a reasonable charge for additional copies of that data beyond the first copy, based on our administrative costs. Where the data is data that you have given to us, you have the right to receive your copy of it in a common electronic format, and to provide copies of it to other people if you wish.",
      subtitle92: "Correction.",
      t920: "You have the right to have the personal data we hold about you corrected if it is factually inaccurate. Note that this does not extend to matters of opinion, such as feedback received on you as a landlord or tenant, or decisions as to suitability.",
      subtitle93: "Deletion.",
      t930: "In some limited circumstances, you have the right to have personal data that we hold about you erased (the right to be forgotten'). This right is not generally available where we still have a valid legal reason to keep the data.",
      subtitle94: "Objection.",
      t940: "You have the right to object to our processing of your personal data where we rely on 'legitimate interests' as our legal basis for processing, but we may be able to continue processing if our interest outweighs your objection.",
      subtitle95: "Opting out of marketing.",
      t950: "You have the right to require us to stop using your personal data to send you marketing information. If you want us to stop sending you marketing information, you can contact us at hello@myroomabroad.com.",
      subtitle96: "Temporary Restriction.",
      t960: "You also have the right in some circumstances to request that temporary restrictions are placed on how we process your personal data, For example if you contest its accuracy or where we are processing it on the basis of our legitimate interest and you contest our assessment that our interest overrides your rights.",
      subtitle97: "Withdrawing Consent",
      t970: "If we are processing your personal data on the basis of your consent, you have the right to withdraw that consent at any time, in which case we will stop that processing unless we have another legal basis on which to continue.",
      t971: "You can exercise your right by:",
      l972: "emailing us on hello@myroomabroad.com",
      l973: "writing to us at Lime tech SPRL, Marius Renardlaan 27A b327, 1070, Brussels, Belgium.",
      t974: "Please note that in order to protect your privacy, we may ask you to prove your identity before we take any steps in response to a request you have made.",
      title10: "How and when will My room Abroad make changes to this guide?",
      t101: "We may update this privacy guide from time to time by updating this web page.",
      t102: "Where we update this guide to include entirely new personal data, or use of existing personal data in an entirely new way, we will notify you that changes have been made using the email address we hold for you, if any.",
      t103: "This guide was last updated on October 18th 2018.",
    },
    terms: {
      headline:"terms of service",
      t000:"Please read these Terms of Service carefully as they contain important information about your legal rights, remedies and obligations. By accessing or using the My Room Abroad Platform, you agree to comply with and be bound by these Terms of Service.",
      t001:"Last updated: October 12, 2018",
      t002:"These Terms of Service ('Terms') constitute a legally binding agreement ('Agreement') between you and My Room Abroad (as defined below) governing your access to and use of the My Room Abroad website, including any subdomains thereof, and any other websites through which My Room Abroad makes its services available (collectively, 'Site'), and all associated services (collectively, 'My Room Abroad Services'). The Site, Application and My Room Abroad Services together are hereinafter collectively referred to as the 'My Room Abroad Platform'. Our  ",
      link001:"Refund Policy",
      link002:"Privacy Policy",
      t003:"and other Policies applicable to your use of the My Room Abroad Platform are incorporated by reference into this Agreement.",
      t004:"Note that Any translated versions of these Terms of Service and the Terms of Use shall be for convenience only. If there is an inconsistency between any of the provisions of this English language version and a translated version, the provisions of this English language version shall prevail.",
      title0:"Overview",
      t10:"My Room Abroad provides an online marketplace for booking accommodation. As such it provides booking services for both Users (who are seeking accommodation) and Accommodation Provider (who wish to offer their accommodation to users). Please note that My Room Abroad is an online marketplace – it does not itself let or rent accommodation to users directly.",
      title1:"ABOUT THESE TERMS OF SERVICE",
      subtitle11:"In these Terms of Service:",
      t110:"The terms 'we', 'us' and 'our' refer to My Room Abroad. My Room Abroad offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here.",
      t111:"Other terms used in these Terms of Service:",
      b112:"1st Rent Payment",
      t112:"means the amount of the 1st rental payment due to be paid by the User to My Room Abroad (on behalf of the Accommodation Provider), as advised on the Website from time to time;",
      b113:"Accommodation",
      t113:"means a room, studio or other house or apartment offered through the Website by an Accommodation Provider;",
      b114:"Accommodation Provider",
      t114:"means the provider of Accommodation listed on the Website",
      b115:"Booking Accepted",
      t115:"refers to the email sent by My Room Abroad to the User as soon as a Booking Request has been accepted by the relevant Accommodation Provider;",
      b116:"Booking Confirmed",
      t116:"means a request to book Accommodation that was accepted by the Accommodation Provider and paid by the User.",
      b117:"'Booking Confirmation'",
      t117:"refers to the email sent by My Room Abroad to the User and Accommodation Provider as soon as the Booking is paid by the User",
      b118:"Booking Request",
      t118:"means a request to book Accommodation submitted by a User;",
      b119:"Booking Value",
      t119:"means the amount corresponding to the sum of all the rental payments (including the 1st Rent Payment and Future Payments, but excluding the One-time Service Fee and Deposit ;",
      b1110:"Business Day",
      t1110:"means any day other than a Saturday, Sunday or public holiday in Taiwan ROC;",
      b1111:"Contract Start Date",
      t1111:"means the date of commencement of the booking Term as specified in the relevant Booking Confirmation;",
      b1112:"Deposit Payment",
      t1112:"means the amount that the User has to pay to the Accommodation Provider to cover any damages that occur during his occupation of the Accommodation or any other breach by the User of the Tenancy Agreement. The Deposit is refunded after move-out at the end of the Tenancy Agreement by the Accommodation Provider subject to deduction of any fee for damages. The Deposit is a matter between User and the Accommodation Provider and My Room Abroad is not involved in, and shall not be liable for the management of, any such deposits;",
      b1113:"Fully Verified Accommodation",
      t1113:" refers to Accommodation where the Accommodation itself and the Accommodation Provider have successfully completed all the possible verification steps as set out on the My Room Abroad website.",
      b1114:"Future Payments",
      t1114:"means all the future payments the User has to make to My Room Abroad on behalf of the Accommodation provider or directly to the Accommodation Provider (in addition to the 1st Rent Payment), which include any rental payments payable by the User to the Accommodation Provider and the Security Deposit;",
      b1115:"Locations",
      t1115:" means the cities and countries as set out on our Website;",
      b1116:"Move In Date",
      t1116:" means the date when the User moves into the Accommodation as specified in the relevant Booking Confirmation.",
      b1117:"My Room Abroad Commission",
      t1117:"is the fee (inclusive of VAT) that My Room Abroad charges the Accommodation Provider when a booking occurs in consideration of the Services provided by My Room Abroad to such Accommodation Provider by means of the Website. It is calculated as a percentage of the Booking Value plus any relevant VAT charged on this percentage of the rent. It is then deducted from the amount that My Room Abroad transfers to the Accommodation Provider. The My Room Abroad Commission can be paid at once. In this case, it is deducted from the 1st Rent Payment transfer. Or the commission can be paid monthly. In this case, part of the commission is deducted from the monthly rent transfers made each month; The part of the commission deducted each month is equal to the total My Room Abroad Commission divided by the Term of the Tenancy;",
      b1118:"One-time Service Fee",
      t1118:"means the amount (inclusive of VAT) the User pays to My Room Abroad in respect of its fees when a Booking is accepted in consideration of the Services provided by My Room Abroad to such User. It is calculated as a percentage of the 1st Rent Payment (as advertised on the website from time to time). This fee applies to the specific Accommodation displayed on the Website, so that if such Accommodation is a single room in a larger flat or house, this fee applies to the specific room you will be occupying and not the entire flat or house;",
      b1119:"Rent Payment",
      t1119:"means the rental payment in accordance with the relevant Tenancy Agreement, the User has to make each month to My Room Abroad on behalf of the Accommodation provider or directly to the Accommodation Provider (in addition to the 1st Rent Payment);",
      b1120:"Services",
      t1120:"means the facilitation of bookings of Accommodation by Users with Accommodation Providers by means of the Website;",
      b1121:"Tenancy Agreement",
      t1121:"means the binding tenancy agreement, contract, lease or licence (whether or not formal and whether or not in writing) between the Accommodation Provider and the User in respect of the tenancy of the Accommodation;",
      b1122:"Term",
      t1122:" means the lesser of: (i) the term of the tenancy based on the “Contract Start” and “Contract End” date (as specified in the relevant Booking Confirmation); ",
      b1123:"User",
      t1123:"means a user of the Website seeking Accommodation;",
      b1124:"Website",
      t1124:"means any website maintained by My Room Abroad including without limitation the websites at www.myroomabroad.com, and its and their subdomains, any associated social networking profiles and any applications for mobile devices that My Room Abroad may publish from time to time; and",
      b1125:"You",
      t1125:"means a User or an Accommodation Provider (as applicable).",
      t1126:"These Terms of Service set out the terms on which we offer the Services and provide the Website and on which you agree to use the Services provided by us and the Website maintained by us.",
      title2:"Acceptance of Terms of Service ",
      t21:"By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein (link to other policies) and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content.",
      t22:"Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.",
      t23:"Any new features or tools which are added to the current platform shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.",
      title3:"What is the Website?",
      t31:"The Website is an electronic platform enabling Users to browse, search and book Accommodation at the Locations and enables Accommodation Providers to advertise Accommodation to Users. Bookings can be made for long terms ranging from 28 days upwards.",
      t32:"My Room Abroad provides booking services to Users and Accommodation Providers, but it does not offer Accommodation to Users on its behalf. Any Tenancy Agreement shall be entered into between the User and the Accommodation Provider directly. My Room Abroad  will not be a party to such Tenancy Agreement and will have no rights or obligations arising out of it.",
      title4:"Information Displayed on the Website",
      t41:"The Website contains information, images and in some cases videos, prices of Accommodation as well as information relating to the Location of the Accommodation.",
      t42:"My Room Abroad  tries hard to ensure that all information published on the Website is clear, accurate, complete and not misleading. We also try to ensure that only Accommodation that meets certain standards of cleanliness, decor, building safety and finish, facilities and customer service are listed on the Website. However, as we do not provide the Accommodation, we do not guarantee this.",
      t43:"My Room Abroad relies on Accommodation Providers to provide appropriate accurate information and to keep such information updated. We do not verify the accuracy or currency of any information uploaded or provided to us by Accommodation Providers unless we have expressly stated that we do. If you are an Accommodation Provider, you acknowledge and agree that you are solely responsible for the content of the information uploaded or provided to us in respect of any Accommodation offered on the Website.",
      t44:"If you are an Accommodation Provider, you must check the relevant listing information of any Accommodation offered by you on the Website including (but not limited to) its price, availability, and facilities before accepting any Booking Request sent to you via My Room Abroad.",
      t45:"If you are a User, you acknowledge and agree that we will have no liability in respect of any information uploaded to the Website or provided to us by an Accommodation Provider about the Accommodation and that your proper remedy in respect of any loss or damage you may suffer arising out of or in connection with the written information displayed in respect of the Accommodation is against the relevant Accommodation Provider. If the Accommodation has been verified by My Room Abroad, My Room Abroad shall be responsible for the accuracy of the verified information only. However, My Room Abroad shall not be liable for any changes to the Accommodation after its verification.",
      t46:"We reserve the right to remove without notice all or any part of any Accommodation listed on the Website in respect of which we receive a complaint (or otherwise conclude) that it is out of date, inaccurate or breaches the rights of a third party. We will use reasonable efforts to process and act on such complaints reasonably and promptly.",
      t47:"Users are advised to read any documentation carefully and ensure that they understand the cost of the Accommodation (i.e. the rent and any other costs) and the terms and duration of the Tenancy Agreement and any restrictions on the usage to which the Accommodation can be put. Photographs and videos of the Accommodation on the Website should be used by Users only as a guide of what the Accommodation may look like and not as an exact representation.",
      title5:"Booking Accommodation",
      t51:"Users are able to submit a Booking Request for Accommodation through the Website. The User must select the dates and Accommodation that he wishes to book and then click the “Request to Book” button. Note that some landlords set limitations regarding contract Term. The platform will generate allowed contract start and end dates which may vary from dates you selected.",
      t52:"The User warrants that he is of legal age to enter into a contract such as a Tenancy Agreement in the country where the Accommodation is located and acknowledges that, if the User is in breach of this warranty, then My Room Abroad shall be entitled to retain any Service Fee that has been paid by the User as compensation for breach of this warranty by the User. It is the User’s responsibility to check that he can legally contract with the Accommodation Provider.",
      t53:"The User will be asked to provide payment details for the 1st Rent Payment and One-time Service Fee in respect of the Booking Request. After the Booking Request is accepted by the Accommodation Provider payment will be taken. If the payment is successful the User will receive an email confirming that the Booking was paid and confirmed.",
      t54:"Upon Payment of the Booking Request, the system creates a payment request for the 1st Rent Payment and One-time Service Fee. The User has 48 hours to transfer the funds. The payment can be made by Bank Transfer on My Room Abroad account or via credit card through a secure online tool.",
      t55:"After payment by the User, the booking is confirmed and My Room Abroad makes an introduction of the User to the Accommodation Provider selected by the User.",
      title6:"Confirmation of a Booking",
      t61:"The Accommodation Provider can accept or decline the Booking Request. If the Booking Request is rejected, the user will be notified on the website and via email. The booking is automatically cancelled and the User can make a new booking request for another room. When declining a request, the Accommodation Provider has the choice to suggest other of his Accommodation listed on the Website to the User. If the Accommodation Provider accepts the Booking Request, payment will be taken in respect of the 1st Rent Payment and the One-time Service Fee. After the payment is completed My Room Abroad will promptly notify both parties setting out the next steps.",
      t62:"Once a booking has been confirmed, it is the User’s responsibility to:",
      l621:"comply with any further requirements that the Accommodation Provider may have. This may include (but not be limited to) the provision of character references and/or guarantor information. This information can also be required by the Accommodation Provider before accepting the Booking Request, in such cases the User will be informed that such information will be required; and",
      l622:"stay in the relevant Accommodation throughout the Term, comply with the Tenancy Agreement, and to make, promptly and in full, all payments to My Room Abroad and/ or to the Accommodation Provider as may be required by these Terms of Service or the Tenancy Agreement, as the case may be.",
      t63:"The Accommodation Provider agrees that once it accepts a Booking Request and the User pays a booking is confirmed, and so the Accommodation Provider is bound to offer the relevant Accommodation for the dates, and at the price stated on the Booking Request and, further, that the property details, condition and facilities of the Accommodation are not materially different from those stated on the My Room Abroad listing. Failure to do so may result in complaints and cancellation by the User (who may be entitled to a full refund).",
      title7:"Consumer Contracts (Information, Cancellation and Additional Charges)",
      t71:"Subject to clauses 7.3 and 7.4, if you are a consumer, you have a legal right to cancel your agreement with us to provide the Services at any time during the 14 days after you have first accepted these Terms of Service in accordance with clause 2.",
      t72:"To cancel your agreement with us to provide the Services, you just need to let My Room Abroad know that you have decided to cancel. You may send us an email at hello@myroomabroad.com. My Room Abroad will email you back to confirm it has received your cancellation.",
      t73:"If you make or accept a Booking Request within the 14 day cancellation period set out above, then you have requested My Room Abroad to provide its Services during such 14 day cancellation period and accordingly you will lose your statutory right to cancel your agreement with My Room Abroad to provide the Services once the Booking Request is accepted in accordance with clause 6, at which point the One-time Service Fee (if applicable) will be payable in accordance with these Terms of Service. Your contractual rights to cancel, as set out in clause 12, will still apply.",
      t74:"In any event, cancellation of the agreement with My Room Abroad to provide the Services shall have no effect on any Tenancy Agreement that may have been or will be entered into.",
      t75:"Any payment that is paid by the User to My Room Abroad on behalf of the Accommodation Provider under, or in connection with, a Tenancy Agreement (including the 1st Rent Payment) will be held on trust by My Room Abroad for the Accommodation Provider and will only be repaid to the User in accordance with clause 12.",
      title8:"The 1st Rent, Deposit and Rent Payment.",
      t81:"The 1st Rent Payment is a contractual reservation fee payable by the User to My Room Abroad, which will receive it on behalf of the Accommodation Provider and hold it on trust for the Accommodation Provider. ",
      t82:"My Room Abroad shall pay to the Accommodation Provider, subject to deductions for the My Room Abroad Commission, the 1st Rent Payment. Transfers will be made once between the 1st and the 3rd day of each month for the all funds received during the previous month. The payment will be made via T/T transfer through the means of a bank intermediary. The delay for the reception of funds is on average 1 week but may vary from bank to bank. My Room Abroad shall not be responsible for any delay of reception of the funds after a proof of payment has been provided to the Accommodation Provider.",
      t83:"When booking a room on My Room Abroad, the User has the choice to also make Future Payment through the website (in the User’s currency if receivable by My Room Abroad) or directly to the Accommodation provider (in the Accommodation Provider’s currency).",
      t831:"In the case the User wishes to make the Deposit Payment in his own currency,  the User shall pay the Deposit to My Room Abroad, which will receive it on behalf of the Accommodation Provider, on the Move In Date. ",
      t832:"My Room Abroad will transfer the deposit to the relevant Accommodation Provider within the next Business Day of receiving the funds from the User.",
      t833:"In the case the User wishes to make each Rent Payment in his own currency,  The User shall pay each monthly rent to My Room Abroad, which will receive it on behalf of the Accommodation Provider. Unless agreed otherwise, the Rent Payment has to be made between the 17th and the 20th day of the previous month (so the rent for October is due by the 20th of September). The rent in the User’s Currency will vary each month depending on the relevant exchange rate (see Section 9. Foreign exchange for more info)",
      t834:"Each 25th day of the month My Room Abroad shall transfer all monthly rent received on behalf of the Accommodation Provider to this Accommodation Provider. The payment will be made via T/T transfer through the means of a bank intermediary. The delay for the reception of funds is on average 1 week but may vary from bank to bank. My Room Abroad shall not be responsible for any delay of reception of the funds after a proof of payment has been provided to the Accommodation Provider.",
      t84:"My Room Abroad acts as an intermediary and is not responsible for any late payments by the User. If the User fails to provide a proof of Rent Payment to My Room Abroad:",
      l841:"by the 25th of the month: a reminder will be sent by My Room Abroad to the User.",
      l842:"By the last day of the month: The Accommodation Provider shall be notified and will be responsible for taking the necessary actions with late paying Users in accordance to their Tenancy Agreement.",
      title9:"Foreign Exchange",
      t91:"This section concerns any payment made by a User in a different currency than the one accepted in the country of the Accommodation Provider of the Accommodation the User booked.",
      t92:"The amount due by the User (for 1st Rent Payment or Future Payment ) shall be calculated using the daily exchange rates provided by My Room Abroad’s foreign exchange provider.",
      title10:"Tenancy Agreement",
      t101:"The Tenancy Agreement only comes into effect when the Accommodation Provider and the User agree the terms for the occupation of the Accommodation. The Tenancy Agreement shall be entered between a User and an Accommodation Provider and for the avoidance of doubt, My Room Abroad shall not be a party to such agreement.",
      t102:"My Room Abroad shall not be responsible or liable for any failure or deficiency in the performance of the Tenancy Agreement by the Accommodation Provider and/or for any defect in any Accommodation provided by the Accommodation Provider.",
      t103:"My Room Abroad shall not be responsible or liable for any failure or deficiency in performance of any contract by the User, including, but not limited to, changes in Move In Date and/or move-out dates. The Accommodation Provider has the mechanisms to enforce any pre-agreed details (including Move In Date and move-out date) with the User and thus, no additional payment or refund shall be or ever become due from My Room Abroad to the Accommodation Provider.",
      title11:"Move in Date",
      t111:"On the date set out in the relevant Booking Confirmation, the User will move into the Accommodation. All Future Payments will be made directly to the Accommodation Provider or to My Room Abroad on behalf of the Accommodation Provider. The listing for the Accommodation sets out what the Future Payments constitute. For any further clarification, the user should contact the Accommodation Provider.",
      title12:"Cancellations",
      t121:"In addition to and subject to the User’s rights to cancel under clause 7, a User may also cancel the booking in accordance with the cancellation policy chosen by the Accommodation Provider and as set out below.",
      t122:"Accommodation Providers may select ONE of the following cancellation policies. Once they have made a selection and notified My Room Abroad, this policy will be in effect for all future bookings. If no selection is made, the “Moderate Cancellation Policy” will be applied by default. The Accommodation Provider gives My Room Abroad irrevocable authority to commit the Accommodation Provider to the selected (or default) cancellation policy, to process (and if necessary refund) any 1st Rental Payment, and apply My Room Abroad’s Commission, in the manner set out below.",
      t123:"A User is permitted to cancel in the following circumstances and according to the following terms:",
      l1231:"after a booking has been made and a Tenancy Agreement has been entered into in accordance with clause 10.1 and/or the User moved into the Accommodation, it cannot be cancelled except pursuant to the termination provisions contained in such Tenancy Agreement.",
      l1232:"Cancellation Policy: Flexible: before the Tenancy Agreement has been entered into by the User and Accommodation Provider but after the Accommodation Provider has accepted the Booking Request and after the User has made the 1st Rent Payment if the User notifies My Room Abroad that he wishes to cancel:",
      ll12321:"less than 15 days prior to the Contract Start Date, no refund will be due in respect of the 1st Rent Payment (and the booking will be cancelled);",
      ll12322:"between 15 days and 30 days prior to the Contract Start Date, My Room Abroad will refund 50% of the 1st Rent Payment (and the booking will be cancelled); and,",
      ll12323:"more than 30 days prior to the Contract Start Date, My Room Abroad will refund 100% of the 1st Rent Payment (and the booking will be cancelled).",
      l1232a:"With respect to clause 12.3(b)(i), the Accommodation Provider shall, in case of cancellation, receive the balance of the 1st Rental Payment after the My Room Abroad Commission has been deducted and with respect to clause 12.3(b)(ii), the Accommodation Provider shall, in case of cancellation, receive 50% of the 1st Rental Payment after My Room Abroad’s Commission has been deducted.",

      l1233:"Cancellation Policy: Moderate: before the Tenancy Agreement has been entered into by the User and Accommodation Provider but after the Accommodation Provider has accepted the Booking Request and after the User has made the 1st Rental Payment if the User notifies My Room Abroad that he wishes to cancel:",
      ll12331:"less than 30 days prior to the Contract Start Date, no refund will be due in respect of the 1st Rental Payment (and the booking will be cancelled);",
      ll12332:"between 30 days and 60 days prior to the Contract Start Date, My Room Abroad will refund 50% of the 1st Rental Payment (and the booking will be cancelled); and",
      ll12333:"more than 60 days prior to the Contract Start Date, My Room Abroad will refund 100% of the 1st Rental Payment (and the booking will be cancelled).",
      l1233a:"With respect to clause 12.3.(c)(i), the Accommodation Provider shall, in case of cancellation, receive the 1st Rental Payment that is left after the My Room Abroad Commission has been deducted and with respect to clause 12.3.(c)(ii), the Accommodation Provider shall, in case of cancellation, receive 50% of the 1st Rental Payment that is left after My Room Abroad’s Commission has been deducted.",

      l1234:"Cancellation Policy: Strict: before the Tenancy Agreement has been entered into by the User and Accommodation Provider but after the Accommodation Provider has accepted the Booking Request and after the User has made the 1st Rental Payment if the User notifies My Room Abroad that it wishes to cancel:",
      ll12341:"less than 30 days prior to the Contract Start Date, no refund will be due in respect of the 1st Rental Payment (and the booking will be cancelled);",
      ll12342:"between 30 days and 90 days prior to the Contract Start Date, My Room Abroad will refund 50% of the 1st Rental Payment (and the booking will be cancelled); and",
      ll12343:"more than 90 days prior to the Contract Start Date, My Room Abroad will refund 100% of the 1st Rental Payment (and the booking will be cancelled).",
      l1234a:"With respect to clause 12.3.(d)(i), the Accommodation Provider shall, in case of cancellation, receive the 1st Rental Payment that is left after the My Room Abroad commission has been deducted and with respect to clause 12.3.(d)(ii), the Accommodation Provider shall, in case of cancellation, receive 50% of the 1st Rental Payment that is left after the My Room Abroad’s Commission has been deducted.",
      l1235:"Cancellation Policy: Non-refundable: before the Tenancy Agreement has been entered into by the User and Accommodation Provider but after the Accommodation Provider has accepted the Booking Request and after the User has made the 1st Rental Payment if the User notifies My Room Abroad that it wishes to cancel no refund will be due in respect of the 1st Rental Payment (and the booking will be cancelled).",
      l1235a:"With respect to clause 12.3.(e), the Accommodation Provider shall, in case of cancellation, receive the 1st Rental Payment that is left after the My Room Abroad’s Commission has been deducted.",
      t124:"In case the 1st Rent Payment has already been received by the Accommodation Provider, The Accommodation Provider shall reimburse any payment made by My Room Abroad to the Accommodation Provider with respect to clauses 12.3 (b), (c) or (d).",
      t125:"For the avoidance of doubt, if a booking is cancelled by a User in accordance with this clause 12, My Room Abroad will not refund the One-time Service Fee.",
      title13:"Re-booking My Room Abroad Guarantee",
      t131:"My Room Abroad has confidence that the Users who use its Services will remain in occupation of their Accommodation for the duration of their Term. This confidence stems from the fact My Room Abroad undertakes various background checks on the Users, which include, but are not limited to, My Room Abroad:",
      l1311:"obtaining the User’s full name, phone number, and country of residence. However, My Room Abroad does not conduct checks on the immigration status of Users as part of these background checks and Accommodation Providers are advised to undertake whatever checks as to the immigration status of any User are required in the country that the Accommodation is located in;",
      l1312:"obtaining additional information about the User purpose of stay, for example, whether the User is a student or a worker and, on an optional basis, what university or company the User will be attending; and",
      t132:"As a result of the confidence we derive from carrying out such checks, and our confidence in our ability to re-book vacant Accommodation swiftly, we are willing to offer the following re-booking guarantee to certain Accommodation Providers. In order for an Accommodation Provider to receive the benefit of such guarantee, My Room Abroad will need to specifically agree to provide such service to the Accommodation Provider which My Room Abroad will communicate to the Accommodation Provider via email. If My Room Abroad does not do so, then this clause 13 shall not apply. The re-booking guarantee takes effect from the User’s “booking start date” (as specified by My Room Abroad) and ends one month prior to the end of the relevant Term and is as follows:",
      l1321:"following the Move In Date, if a User permanently ceases to occupy Accommodation that has been booked through My Room Abroad, then the Accommodation Provider authorises My Room Abroad (subject to these Terms of Service) to, and My Room Abroad shall, immediately re-book the Accommodation (for a period equal to or longer than the remainder of the Term);",
      l1322:"provided that the Accommodation Provider has complied with the remainder of this clause 13, until the earlier of such time as My Room Abroad re-lets the Accommodation or the expiry of the Term, My Room Abroad shall pay to the Accommodation Provider (as the Accommodation Provider’s sole remedy for My Room Abroad being unable to re-let the Accommodation) an amount equivalent to the daily rent (after the exhaustion of any credit held by the Accommodation Provider relating to the 1st Rental Payment, advance rent and/or deposit) that from time to time falls due to the Accommodation Provider under the Tenancy Agreement; and",
      l1323:"My Room Abroad shall have sole discretion to re-let the Accommodation to such person and with such a discount to the current rent as My Room Abroad considers reasonable and commercially appropriate to obtain a prompt re-letting of the Accommodation for the remainder of the Term. However, if My Room Abroad re-lets the Accommodation with a discount to:” the monthly rent of in excess of five percent of the rent paid by the previous User, then My Room Abroad shall continue to pay the excess only (in accordance with clause 13.3) for the remainder of the Term.",
      t133:"The first payment under clause 13.2(b) or (c) shall be made on or around the first Business Day of the calendar month that falls following the expiry of the period of 60 days that starts on the date that My Room Abroad receives a valid and appropriately substantiated claim for such a payment from the Accommodation Provider. Thereafter, My Room Abroad shall make such payment monthly in arrears on or around the first Business Day of each month.",
      t134:"My Room Abroad shall have no obligation to reimburse any rent under clause 13.2(b) or (c) to the extent that such rent is in excess of the market rate for a property of the size and quality of the Accommodation in the location in which it is located, in each case as reasonably determined by My Room Abroad.",
      t135:"If the Accommodation Provider:",
      l1351:"re-lets the Accommodation without using My Room Abroad;",
      l1352:"otherwise hinders or fails to facilitate the re-letting of the Accommodation by My Room Abroad (including by keeping the Accommodation in the condition considered in clauses 13.6(h) (v) to (vii) below);",
      l1353:"receives payment of the rent by the User’s guarantor under the Tenancy Agreement; or",
      l1354:"fails to notify My Room Abroad when the Accommodation has been re-let, then no sum shall be payable to the Accommodation Provider under clause 13.2(b) or (c).",
      t136:"In addition, no sum shall be payable under clause 13.2(b) or (c):",
      l1361:"in respect of any 1st Rental Payment, advance rent and/ or deposit that the Accommodation Provider collected or any initial rental payment that the Accommodation Provider failed to collect at the start of the Tenancy Agreement;",
      l1362:" as a result of any agreement between the Accommodation Provider and the User for the User to pay less rent or to cease or suspend paying rent as set out in the listing for the Accommodation;",
      l1363:"as a result of the User moving to another accommodation of the Accommodation Provider (or of any person or entity directly or indirectly connected to the Accommodation Provider) during the Term;",
      l1364:"unless the Accommodation Provider notifies My Room Abroad in writing at hello@myroomabroad.com of the User ceasing to occupy the Accommodation and to pay rent within 14 days of such event occurring;",
      l1365:"unless the Accommodation Provider has first demanded in writing (which may include (but not be limited to) by email) payment of the relevant rent from the User and such rent remains outstanding more than seven days after the date that such demand was delivered to the User;",
      l1366:"in respect of:",
      ll13661:"insurance premia, outgoings or utilities charges payable by the Accommodation Provider;",
      ll13662:"any sums that the Accommodation Provider is itself obliged to pay as rent or service charge to a superior Accommodation Provider or management company;",
      ll13663:"mortgage premia, interest, fees, or penalties payable by the Accommodation Provider to the Accommodation Provider’s funders; or",
      ll13664:"any rent that is agreed in a Tenancy Agreement that is in excess of the rent set out in the listing of the Accommodation;",
      l1367:"in respect of unpaid service charges, outgoings, fees, utilities charges, fines payable by the User under the Tenancy Agreement or the cost of any dilapidations or Accommodation repairs required by the Accommodation Provider at the termination or conclusion of any Tenancy Agreement;",
      l1368:"if the User ceases to occupy the Accommodation or cancels or terminates a Booking or Tenancy Agreement as a result of:",
      ll13681:"the Accommodation Provider being in breach of these Terms of Service or the Tenancy Agreement;",
      ll13682:"the Accommodation Provider providing incorrect or misleading information about the Accommodation, or otherwise misrepresenting the Accommodation, to the User (whether through www.myroomabroad.com or otherwise) prior to the start of the Tenancy Agreement;",
      ll13683:"the User cancelling the booking in accordance with the cancellation policy set out in these Terms of Service;",
      ll13684:"the original booking having been accepted by the Accommodation Provider by mistake or in error (and such mistake or error not being communicated in writing on our hello@myroomabroad.com);",
      ll13685:"the Accommodation not being available for occupancy by, or being inaccessible to, the User;",
      ll13686:"the Accommodation being: (i) in an unsafe or uninhabitable condition; or (ii) being in such a state of disrepair as to hinder the User’s use of the Accommodation; or (iii) due to any circumstance not within a party’s reasonable control",
      ll13687:"the Accommodation Provider failing to remedy any defect in the Accommodation, or maintenance issue, that arises during the Term in such a manner as to hinder the User’s enjoyment of the Accommodation;",
      ll13688:"the Accommodation Provider encouraging, persuading, coercing or otherwise procuring the User to do so;",
      ll13689:"any joint action, arrangement or agreement with the Accommodation Provider that was made or entered into with the intent of enabling the Accommodation Provider to benefit, unjustly, from the payment provisions in clause 13.2(b) and (c); or",
      ll136810:"if the User ceases to occupy the Accommodation, or cancels or terminates a booking or Tenancy Agreement, where the User has complained to My Room Abroad, within 24 hours of the start of the Move In Date, regarding the condition of the Accommodation.",
      ll136811:"the Tenancy Agreement comprises any clauses in disrespect of the local laws and regulations",
      t137:"No sum shall be payable under this clause if the User or the Accommodation Provider has knowingly provided false information to My Room Abroad at any time.",
      t138:"No sum shall be payable under this clause if the User does not move into the Accommodation on, or shortly after, the Move In Date (a “no show”).",
      t139:"My Room Abroad shall be entitled, at any time and without notice, to inspect or permit a third party to inspect any Accommodation to which this clause 13 applies.",
      t1310:"The User acknowledges that the re-booking service (and the accompanying obligations on My Room Abroad under this clause 13) are intended to give the Accommodation Provider comfort that Accommodation will be occupied for the duration of the relevant Term:",
      l13101:"accordingly, this clause 13 does not excuse the User from performing its obligations under these terms or any Tenancy Agreement entered into with the Accommodation Provider, and the User shall reimburse My Room Abroad for any and all sums paid by My Room Abroad to an Accommodation Provider under or in connection with this clause 13 as a result of the User ceasing to (or purporting to cease to) permanently occupy any Accommodation during the Term;",
      l13102:"if My Room Abroad makes any payments, or payment is demanded from My Room Abroad under this clause 13, the User shall pay to My Room Abroad immediately on written demand by My Room Abroad an amount (in the corresponding currency) equal to the full amount of such demand; and",
      l13103:"a certificate of My Room Abroad as to the amount of any such payment shall be conclusive evidence (except in the case of fraud) that such payment was made by or demanded from My Room Abroad and is accordingly payable in accordance with this clause 13 by the User. All payments by the User are to be made to My Room Abroad without any deduction, set off or withholding whatsoever.",
      title14:"Your Conduct",
      t141:"In making any Booking payment after being accepted by the Accommodation Provider and authorising the One-time Service Fee, 1st Rental Payment (and the Holding Deposit, if applicable) in respect of selected Accommodation, the User agrees that it shall not seek to circumvent My Room Abroad by trying to make (or in fact making) a booking with the Accommodation Provider directly and notifying us that you have decided not to make a booking.",
      t142:"In using the Website and/or the Services and/or accepting any introduction of a User in respect of selected Accommodation, the Accommodation Provider agrees that it shall not seek to circumvent My Room Abroad by trying to make (or in fact making) a booking with the User directly and notifying us that the User has decided not to make a booking.",
      t143:"By downloading and/or using the Website, you agree not to upload, post, email or otherwise send or transmit any material that contains viruses, Trojan horses, worms or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Services or Website.",
      t144:"You agree and undertake to keep your username and password confidential, not to disclose your password to any other person and not to permit any other person to log in to the Website using your username and password.",
      t145:"You agree not to interfere with the servers or networks connected to the Website or to violate any of the procedures, policies or regulations of networks connected to the Website, including these Terms of Service. You also agree not to:",
      l1451:"attempt to reverse engineer or sell, export, license, modify, copy, distribute or transmit the Website to any third party, or jeopardize the correct functioning of the Website, or otherwise attempt to derive the source code of the software (including the tools, methods, processes and infrastructure) that enables or underlies the Website;",
      l1452:"attempt to gain access to secured portions of the Website to which you do not possess access rights;",
      l1453:"impersonate any other person while using the Services or Website;",
      l1454:"conduct yourself in a vulgar, offensive, harassing or objectionable manner while using the Website;",
      l1455:"resell or export the software associated with the Website;",
      l1456:"use the Website to generate unsolicited advertisements or spam; or",
      l1457:"use any automatic or manual process to search or harvest information from the Website, or to interfere in any way with the proper functioning of the Website.",
      title15:"My Room Abroad's rights",
      t151:"In providing you with access to the Website, My Room Abroad reserves the following rights, and in accessing, browsing or otherwise using the Website you grant to My Room Abroad and agree that My Room Abroad shall have the following rights:",
      l1511:"the right to refuse or withdraw your access to the Website in accordance with applicable laws for any reason at any time (with or without notice) if in My Room Abroad’s sole and absolute discretion you violate or breach any of these Terms of Service;",
      l1512:"the right to suspend, amend or disable your profile without giving you notice or any reason;",
      l1513:"the right to amend or update the Website, fees, billing methods or these Terms of Service from time to time; and",
      l1514:"the right to report you to the police or other judicial body if My Room Abroad believes in its sole and absolute discretion that your conduct (whether in using the Website, our Services or otherwise) is unlawful.",
      title16:"Intellectual Property",
      t161:"My Room Abroad and/or its licensor(s) are the sole owners of the Website, which includes any software, domains, and content made available through the Website. The Website is protected by International copyright and other intellectual property laws.",
      t162:"Without limitation, this means that you may not sell, export, license, modify, copy, distribute or transmit the Website (or any part of it) or any material provided through the Website without My Room Abroad’s prior express written consent.",
      t163:"We grant you a royalty free, non-exclusive, non-transferable, revocable, worldwide licence to use our intellectual property rights in the Services and the Website solely to the extent necessary to allow you to participate in the Service. Any unauthorised use of the Website will result in the automatic termination of the limited licence granted by us. My Room Abroad reserves the right to terminate the limited licence without notice at any time following an unauthorised use by you of the Website.",
      t164:"My Room Abroad and its graphics, logos, icons and service names related to the Website are registered and unregistered trademarks or trade dress of My Room Abroad. They may not be used without My Room Abroad’s prior express written permission.",
      t165:"You agree that My Room Abroad owns the copyright for any content, whether textual, graphic, photographic or video, that it creates and publishes on its Website and you must not sell, export, license, modify, copy, distribute or transmit this content to any third party.",
      t166:"You grant My Room Abroad a royalty free, non-exclusive, non-transferable, irrevocable, worldwide licence to use your intellectual property rights solely to the extent necessary to allow My Room Abroad to provide the Services to you. For clarity, such use might include such matters as displaying your name and logo next to an Accommodation listing, or providing a link to your website.",
      t167:"Additionally, you grant us a royalty free, non-exclusive, non-transferable, irrevocable during the term of this Agreement, worldwide licence to use your intellectual property rights in your name, logo and brand get-up (your “Brand”) for the purposes of promoting the Service by means of referring on the Website and in our public statements and publicity material to the fact of your participation in the Service, provided that in doing so we use reasonable efforts to ensure that we do not do anything which damages the goodwill in your Brand.",
      t168:"You shall reimburse us on demand in respect of any costs, damages or expenses that might arise as a result of, or in connection with, any alleged or actual infringement of any third party's intellectual property or other rights, arising out of My Room Abroad’s use of information submitted by you on the Website.",
      title17:"Security",
      t171:"Whilst we have implemented commercially reasonable technical and organisational measures to secure your personal information and user content from unauthorised use, we cannot guarantee that unauthorised third parties will never be able to defeat those measures. You acknowledge that you provide your personal information and user content at your own risk.",
      title18:"Electronic Communications",
      t181:"By downloading and/or using the Website and/or material provided through the Website, you consent to receiving electronic communications and notices from My Room Abroad. You agree that any notice, agreement, disclosure or other communications that we send to you electronically will satisfy any legal communication requirements, including that such communications be in writing. You have the right to ask us not to process your personal data for such marketing purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at hello@myroomabroad.com.",
      title19:"Privacy",
      t191:"You provide us with information when you register on the Website. We also collect information both relating to you (for example on your usage history and your preferences for certain kinds of offer) and to users of the Website and the Services in general. Any information that you submit or that we collect when you are using the Website or Services is subject to our Cookie Policy, the terms of which are hereby incorporated into these Terms of Service.",
      title20:"Use of Information Submitted",
      t201:"You agree that My Room Abroad is free to use comments, information (except personal information) or ideas contained in any communication you may send to My Room Abroad without compensation, acknowledgement or payment to you for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products and services and creating, modifying or improving the Services or Website or other products or services. For the avoidance of doubt, all such information will be deemed by My Room Abroad to be non-confidential and non-proprietary, and you agree that such information may be used by us without any limitation whatsoever.",
      t202:"You agree that any information published by you on the Website may be public information and that other users of the Website will be able to view and comment on such information. You can classify some information as public information and some information as private information. Public information will appear on your profile page.",
      t203:"You agree that My Room Abroad is free to republish and make use of any information, including but not limited to, text and images, that you publish on the Website. Our use may include, but is not limited to, reposting your Accommodation listing on other websites. You agree that My Room Abroad has the right not to acknowledge you as the source of such information and/ or creator of such content. In general however, we will make an attempt to provide a link back to your page when posting content on other third party sites. You hereby irrevocably waive all your moral rights to any content posted by you on the Website. You confirm that you have all necessary rights and consents to share any such information that may be owned by a third party which you may post on the Website.",
      title21:"No Warranty & Liability Limit",
      t211:" My Room Abroad provides the Services and Website 'as is' and without any warranty or condition, whether express, implied or statutory. Unless you are a consumer, My Room Abroad specifically disclaims any implied warranties of title, merchantability, or fitness for a particular purpose. My Room Abroad specifically disclaims any implied warranties for non-infringement. My Room Abroad assumes no liability or responsibility for any errors or omissions in the Website or provision of the Services; any failures, delays or interruptions in the Website; any losses or damages arising from the use of the Services or Website; any conduct by users of the Services or Website; or any liability of an Accommodation Provider under the Immigration Act 2114 arising from the immigration status of any User. We reserve the right to deliver the Services and Website in our sole and absolute discretion.",
      t212:"This clause 21.2 only applies if you are a business and not a consumer. Subject to clause 21.4, My Room Abroad, its shareholders, directors, officers, employees or agents shall not be liable (jointly or severally) to you for:",
      l2121:"loss of profits, business or revenue;",
      l2122:"loss or corruption of data, information or software;",
      l2123:"loss of business opportunity;",
      l2124:"loss of anticipated savings;",
      l2125:"loss of goodwill; or",
      l2126:"any indirect or consequential loss, arising out of or in connection with the Services and/or Website or these Terms of Service, and whether or not advised of the possibility of damage.",
      t213:"This clause 21.3 only applies if you are a consumer. If My Room Abroad fails to comply with these Terms of Service, My Room Abroad is responsible for loss or damage you suffer that is a foreseeable result of My Room Abroad’s breach of these Terms of Service or our negligence, but My Room Abroad is not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if it is an obvious consequence of our breach or if it was contemplated by you and My Room Abroad at the time we entered into this contract. My Room Abroad provides the Services for domestic and private use. My Room Abroad has no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.",
      t214:"My Room Abroad does not seek to exclude liability for death or personal injury caused by our negligence, or for fraud or fraudulent misrepresentation on the part of My Room Abroad.",
      t215:"From time to time you may use or access services, promotions and websites of third parties (including without limitation Accommodation Providers). In using or accessing third party services, promotions and websites, you agree to be bound by the terms of service of such third parties governing their services, promotions and websites and hereby acknowledge that we shall not be responsible for the provision of services, accuracy of promotions or content of websites belonging or operated by third parties.",
      t216:"From time to time My Room Abroad may run promotions or incentives for the benefit of our Website visitors, potential customers and customers. My Room Abroad reserves the right to modify or remove these promotions at any time and may cancel any benefits or monetary payments owed to people who have not yet redeemed part or all of the benefit owed to them under the promotion. ",
      t217:"You acknowledge and agree that My Room Abroad is not responsible for the accuracy of any information published on the Website by users and does not warrant that any information appearing on the Website is accurate, true or complete. My Room Abroad specifically excludes liability for any loss, harm, distress or damage suffered by you or any third party as a result of inaccurate information appearing on the Website.",
      t218:"If any applicable authority holds any portion of this section to be unenforceable, then liability will be limited to the fullest possible extent permitted by applicable law.",
      title22:"Indemnity",
      t221:"If you are a business, you agree to indemnify and hold My Room Abroad and its related companies, and each of their respective shareholders, directors, officers, employees, agents, merchant partners and professional affiliates harmless from and against any third-party claim or cause of action, including reasonable attorneys' fees and court costs, arising, directly or indirectly, out of your use of the Services and/or Website or your violation of any law or the rights of any third party.",
      title23:"Disputes",
      t231:"You agree that these Terms of Service and any claim, dispute or controversy arising out of in connection with these Terms of Service or their subject matter or formation (including non-contractual disputes or claims), the Services, the Website, My Room Abroad’s advertising or any related transaction between you and My Room Abroad shall be governed by and construed in accordance with Republic of China’s law.",
      t232:"Any dispute or claim arising out of or in connection with such matters (including non-contractual disputes or claims) will be subject to the exclusive jurisdiction of the courts of Republic of China.",
      t233:"If you are a consumer and reside outside of Republic of China, you will be given no less protection than that afforded to you by your local jurisdiction.",
      title24:"Termination",
      t241:"My Room Abroad may change or discontinue the Services and/or Website at any time without prior notice. We reserve the right to terminate these Terms of Service for any reason, without notice, and these Terms of Service shall automatically terminate in the event that you violate any of the Terms of Service set forth herein (without prejudice to our accumulated rights against you). In the event of any termination, you will immediately cease use of the Services and Website.",
      title25:"General",
      t251:"These Terms of Service are agreed between you and us. No person shall have any rights under or in connection with these Terms of Service under the Contracts (Rights of Third Parties) Act 1999.",
      t252:"If any court or competent authority decides that any term of these Terms of Service is held to be invalid, unlawful or unenforceable to any extent, such term shall, to that extent only, be severed from the remaining terms, which shall continue to be valid to the fullest extent permitted by law.",
      t253:"Headings are for reference purposes only and in no way define, limit, construe or describe the extent or scope of such section.",
      t254:"Our failure to enforce any provision of these Terms of Service shall not constitute a waiver of that or any other provision and will not relieve you from the obligation to comply with such provision.",
      t255:"You are not permitted to assign, transfer, charge, sub-contract or deal in any other manner with all or any of your rights under these Terms of Service without our prior express written consent.",
      t256:"These Terms of Service and Privacy Policy and Cookie Policy constitute the entire agreement between you and My Room Abroad and supersede and extinguish all previous agreements, promises, assurances, warranties, representations and understanding between us, whether written or oral, relating to its subject matter.",
      title26:"Contact us",
      t261:"My Room Abroad is a brand of Lime Tech SPRL, A Belgian PLLC operating with VAT number BE.0537.421.768.",
      t262:"adress:",
      t263:"Lime tech SPRL",
      t264:"Marius Renardlaan 27A b327",
      t265:"1070 Brussels",
      t266:"Belgium",
      t267:"Email:hello@myroomabroad.com"
    },
    tenantFaq:{
      tOrl:"Are you a tenant or landlord?",
      faqT:"FAQ for tenants",
      faqLL:"FAQ for Landlords",
      mainTitle:"Help Center Tenants",
      title1:"About My Room Abroad",
      s11:"What is My Room Abroad?",
      t111:"A platform that connects internationals with certified landlords in dream destinations. This platform makes the painful process of finding and booking a room abroad safe and easy for foreigners. It simplifies the search for accommodation abroad and allows up front booking of rooms for a 1 to 12 months period.",
      l111:"Learn More",
      s12:"What are the dream destinations?",
      t121:"A dream destination is defined as an exotic destination for Westerners, where they want to go back once they left. Asia Pacific and South America hold most of the dream destinations.",
      s13:"What are the levels of verification?",
      t131:"My Room Abroad has four different verification levels:",
      l132:"1. Not yet verified: It means that we are still looking to confirm the landlord's personal information. We didn’t call nor meet this landlord. We can’t insure his trustworthiness.",
      l133:"2. Verified: It means we've already verified the landlord's email address, phone number and government-issued identification. We have called them or met them in person and we consider them to be trustworthy.",
      l134:"3. Visited: It means that someone from the My Room Abroad’s team has personally visited and photographed and/or took a video of the apartment.",
      l135:"4. Exclusive: It means a place is fully managed by My Room Abroad and is only available on our platform.",
      t136:"You can check each apartment's verification level in every listing under the Description section.",
      s14:"Can I visit the room before booking it?",
      t141:"Usually, My Room Abroad does not provide the in person visit services. We believe online visits are a gain of time for both landlords and tenants. Each room on our platform has a detailed description and many even have videos to show the wear and tear of the house. If the room is not like advertised in the description on the website, there's a 24h guarantee. Refer to the cancellation policy section: “What if the room is not like advertised on the platform?”for more info on the guarantee. However, if you really want to visit the apartment physically, we can usually arrange this for you, however you will be charged a visit fee for this service",
      title2:"Booking with My Room Abroad",
      s21:"Can I change a booking I just made to another room?",
      t211:"Once you have made a booking, the platform does not allow you to make another one. However, if your booking is not yet accepted by the landlord, you can cancel it. Once you cancel your booking, you can make a new booking on the platform.",
      s22:"Do I have to be a student to book a room through MRA?",
      t221:"Not at all! You can book a room with My Room Abroad even if you are not a student! Anybody willing to rent a room for at least a 1 to 12-month period can do it with My Room Abroad.",
      s23:"With whom do I sign a contract?",
      t231:"The tenant signs a lease contract with the landlord.",
      s24:"What is in it for the me?",
      t241:"The platform is a safe and easy way to get in contact with certified landlords. You can book your room abroad from your home country in only a few days. You sign an English contract and you (or your parents) can make online rent payments which solves the problem of withdrawals limits and leaves you a bigger cash budget for other fun activities. Furthermore, you can relax and camly prepare your stay abroad knowing that you will have a place to sleep when you arrive in this faraway country.",
      title3:"Fee and payment",
      s31:"To whom shall I pay the rent, deposit and and other fees?",
      t311:"All the payments will be processed through My Room Abroad. My Room Abroad acts as an intermediary between the tenant and the landlord. All payments received from the tenant are transferred to the landlord in his own currency.",
      s32:"How do I pay the rent, deposit and other rental fees?",
      t321:"We currently have three payment options for the rent, the deposit and the service fee:",
      l3221:"Online via credit card",
      l3222:"Local bank transfer (in host's country currency)",
      l3223:"cash deposit at the bank in your host's country currency.",
      s33:"Who pays the deposit back to me?",
      t331:"As the deposit have been transferred by My Room Abroad to the Landlord, it will be paid back to you by the landlord (most likely in cash in the local currency). You will recover the total amount of the deposit only if everything is in order and nothing was broken as stipulated in your contract.",
      s34:"When do I recover the deposit?",
      t341:"You will recover the deposit at the end of the contract period. If you wish to recover your deposit on your credit card, this is possible if you paid the total amount with the last 60 days with your credit card. Please discuss this with your landlord and check with us whether you are eligible for a Credit Card refund",
      s35:"What is the one-time service fee?",
      t351:"The one-time service fee is a one time 20% fee My Room Abroad charges to cover the platform maintenance cost. Since we want to help you, we do not overcharge you. We are actually a lot cheaper than other agents in Asia who charge 50% to 200% of the monthly rent for their service.",
      title4:"During my stay",
      s41:"Can my friends visit during my stay?",
      t411:"This depends on your landlord's policy. Most of them allow visitors but some put conditions to it that are clearly stipulated in the contract. For more information about your house rules, ask your MRA housing expert or contact us at hello@myroomabroad.com",
      s42:"If there are any arguments with the landlord, will you help me?",
      t421:"We are of course always available to give you advice! You can contact us to help resolve the problem as an impartial intermediary. If no settlement is possible and legal action is needed, this will be between the landlord and you. My Room Abroad is the platform connecting both parties. The team will help you the best they can. However, in case of legal dispute, they will not intervene. This nevertheless rarely happens as we only work with trustworthy landlords.",
      title5:"Cancellation",
      s51:"Is it possible to cancel my booking?",
      t511:"You can always cancel a booking you have just made as long as it has not been accepted by the landlord. To do so, click on the “cancel my booking” button in your booking request. However, once the booking has been accepted by the landlord, it is not possible to cancel it without notice anymore. The cancelation policy is proper to each apartment/owner. Therefore, it is always explained in the house rules. Ask your MRA housing expert for more information on your landlord's cancelation policy.",
      s52:"What if the room is not like advertised on the platform?",
      t521:"If there were to be any differences between the listing on My Room Abroad and the reality, you have 24 hours, starting from your move in date, to notify My Room Abroad. A member of the team will evaluate your complaint as fast as possible. If the complaint is deemed valid, you will be reimbursed and My Room Abroad will help you find another room (no service fee will be charged).",
      s53:"What happens if my booking is canceled?",
      t531:"Once you have made a booking request, the landlord has the right to decline it either because you do not fit his tenant profile or because the room has already been booked by someone else. In this case, you are able to make a new booking request on the platform. In some rare cases, booking might be cancelled by the landlord after being accepted if an unexpected event happens to the property or its landlord. If it is the case, you will be notified immediately and everything you paid will be fully refunded to you. As you will need to find a new place, we will give you other available options.",
      title6:"After your stay",
      s61:"What if my lease period is longer than my stay?",
      t611:"Some people only stay for 4.5 months while most lease contract have a minimum length of 5 months. You can vacate the room before the end of your contract and receive your full deposit back only if you have paid the rent for the total period of your lease. If the lease period greatly exceed your stay (for instance you have a 1 year contract but only stay 5 months), you have two solutions. Either you break your contract and the landlord then has the right to keep your deposit. We do not recommend this option! Most of the landlords will allow you to find a tenant to take over your lease contract (please verify the landlord's subleasing policy before signing a contract with a lease period exceeding your stay!) The new tenants will then sign a new contract with the landlord for the remaining period of your contract. We can help you find your next tenant by posting your room back online.",
      s62:"What if I want to extend my contract?",
      t621:"Usually landlords give the priority to the current tenant to extend their contract. You need to notify your landlord if you want to extend your lease and the details of the arrangement are to be discussed between the both of you. Most landlords will ask to be notified one month before the contract end, but some might only want to be notified 2 weeks in advance. To know when you should tell your landlord you want to stay longer in the room, feel free to ask you MRA housing expert or your landlord directly.",
    },

    areas:{
      title:"The district",
      ratings:"Ratings",
      dyn:"Dynamism",
      price:"Price",
      comm:"Nice to live",
      mrt:"MRT stations in the area",
      description:"Description",

    }
}
