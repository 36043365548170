<template>
  <div class="pa-2">

  <!-- <v-container id="footer" fluid grid-list-sm  > -->
    <v-layout wrap justify-space-between>
      <v-flex sm3 xs6>
        <v-list class="links-list footer-list" dark>
          <v-list-tile  href="/" target="_blank">
            <v-list-tile-content>
              <v-list-tile-title style="padding:0px!important" class="list-title" v-text="$t('footer.home')"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile :href="'https://blog.myroomabroad.com'" target="_blank">
            <v-list-tile-content>
              <v-list-tile-title style="padding:0px!important" class="list-title" v-text="$t('footer.blog')"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile  v-for="(link, index) in links" :key="index" :href="link.name" target="_blank">
            <v-list-tile-content>
              <v-list-tile-title style="padding:0px!important" class="list-title" v-text="link.display"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>
      <v-flex sm3 xs6>
        <v-list class="links-list footer-list" dark>
          <v-list-tile v-for="(elem, index) in contactArr" :key="index">
            <v-list-tile-content>
              <v-list-tile-title class="list-title" v-text="elem"></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile>
            <v-list-tile-content>
              <a href="https://m.me/myroomabroad" class="list-title"> <v-list-tile-title class="list-title" v-text="'Live Chat'" ></v-list-tile-title></a>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-flex>

      <v-flex sm3 class="pa-2 hidden-xs-only subscribe">
        <v-layout  wrap>

          <v-flex xs12>
            <a href="https://facebook.com/myroomabroad" class="pa-2">
              <img src="@/assets/facebook.svg" class="social-icon" alt="Facebook">
            </a>
            <a href="https://www.instagram.com/myroomabroad/"class="pa-2">
              <img src="@/assets/instagram.svg" class="social-icon" alt="Instagram">
            </a>
            <a href="https://www.youtube.com/channel/UCuoI2_N5SyVxWKxWve_aWVw/videos" class="pa-2">
              <img src="@/assets/youtube.svg" class="social-icon" alt="YouTube" >
            </a>
            <a href="https://twitter.com/myroomabroad" class="pa-2">
              <img src="@/assets/twitter.svg" class="social-icon" alt="Twitter">
            </a>
            <a href="https://www.linkedin.com/company/my-room-abroad/" class="pa-2">
              <img src="@/assets/linkedin.svg" class="social-icon" alt="linkedIn">
            </a>
          </v-flex>
          <v-flex xs12>

            <h3 class="subscribe">{{$t('footer.subscribe')}}</h3>
            <p class="subscribe">{{$t('footer.getNewsletter')}}</p>
            <v-text-field
              :label="$t('tenants.email')"
              v-model="email"
              solo
              hide-details
              clearable
            ></v-text-field>
            <v-btn class="submit-button" color="light" @click="addToNewsletter">{{$t('footer.submit')}}</v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout  wrap pa-2>

      <v-flex xs12 class="hidden-sm-and-up">
        <a href="https://facebook.com/myroomabroad" class="pa-2">
          <img src="@/assets/facebook.svg" class="social-icon" alt="Facebook">
        </a>
        <a href="https://www.instagram.com/myroomabroad/" class="pa-2">
          <img src="@/assets/instagram.svg" class="social-icon" alt="Instagram">
        </a>
        <a href="https://www.youtube.com/channel/UCuoI2_N5SyVxWKxWve_aWVw/videos" class="pa-2">
          <img src="@/assets/youtube.svg" class="social-icon" alt="YouTube">
        </a>
        <a href="https://twitter.com/myroomabroad" class="pa-2">
          <img src="@/assets/twitter.svg" class="social-icon" alt="Twitter">
        </a>
        <a href="https://www.linkedin.com/company/my-room-abroad/" class="pa-2">
          <img src="@/assets/linkedin.svg" class="social-icon" alt="linkedIn">
        </a>
      </v-flex>
    </v-layout>
    <v-layout  wrap justify-center class="copyright">
      <p>&copy; Copyright 2018 My Room Abroad - All rights reserved.</p>
    </v-layout>
  <!-- </v-container> -->
  </div>

</template>
<script>
import axios from 'axios'

export default {
  name: "",
  data: () => ({
    contactArr: [
      'hello@myroomabroad.com',
    ],
    email: ""

  }),
  methods: {
    addToNewsletter() {
      if (this.email != "") {
        this.disabled = true
        axios.post('/newsletterSubscribers.json', {
            email: this.email
          })
          .then(() => {
            alert('Thanks for subscribing! :D')
            // console.log('email added')
            this.email = ""
            this.disabled = false
          })
          .catch(err => {
            this.disabled = false
            console.log(err)
            alert('something happened')
          })
      }
    }
  },
  computed:{
    links(){
      let t=[{
        display: 'Events',
        name: '/events',
      },{
        display: this.$t('homepage.about'),
        name: '/aboutUs',
      },
      {
        display: this.$t('footer.careers'),
        name: '/careers/',
      },
      {
        display: this.$t('footer.faq'),
        name: '/faq/'
      },
      {
        display: this.$t('footer.howItWorks'),
        name: '/hiw/',
      },
      {
        display: this.$t('footer.policies'),
        name: '/policies/'
      },
      {
        display: 'Referral Program',
        name: 'referral'
      }
    ]
    return t
    }
  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.text-center {
    padding: 48px 20px;
}

#footer {
    background-color: rgba(0,0,0,.6);
    text-align: left;
}
.md-list {
    background-color: transparent;
}
.subscribe {
    text-align: left;
}
.subscribe h3,p {
    color: #ffffff;
}
.footer-list {
    background-color: transparent;
    .list-title {
        color: #ffffff;
        text-decoration: none;
    }
}
.links-list a {
    color: rgb(255,255,255);
}
.copyright p {
    text-align: center;
}
.footer-link {
    color: #ffffff !important;
    text-decoration: none !important;
}
.footer-text {
    min-height: 24px;
    color: rgb(255,255,255)!important;
}
.subscribe{
  > h3{
    font-size: 1.6em;
  }
  > p{
    font-size: 1em;
  }
}
.social-icon{
  // font-size:2em;
  height:1.5em;
}
.submit-button {
    margin-left: 0;
    margin-top: 1em;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.6) !important;
}
</style>
