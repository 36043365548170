export default {
  AWS_LAMBDA_GETSIGNEDURL_ENDPOINT: 'https://fa0e3g61n3.execute-api.us-east-1.amazonaws.com/prod/S3-dropzone',
  AWS_S3_BUCKET_URL: 'https://s3-eu-west-1.amazonaws.com/my-room-abroad-rooms/',
  GOOGLE_MAPS_API_KEY: 'AIzaSyDm9exHI8VyWu6M80-WSUOM8kbybI_sGkI',
  AWS_ACCESS_KEY_ID: "AKIAJCTJOUZ3K6KYFTSA",
  AWS_SECRET_ACCESS_KEY: "a3zOpl87hBkZVj7HzFL/Q7spaqpTZIgSRq5TjNac",
  FIREBASE_API_KEY: "AIzaSyAF88Igm-MbSWaUwX_pbjnIWeuoTn2CQwk",
  TITLE: "My Room Abroad - Book your new home in Asia's best cities!",
  TAGLINE: "Book your new home in Asia's best cities!",
  SITENAME: "My Room Abroad",
  TWITTERACCOUNT: "@MyRoomAbroad",
  PHOTOURL:'https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto/v1539854631/',
  CARDURL:"https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,w_600/v1539854631/",
  SEARCHCARDURL:"https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,h_333/v1539854631/",
  THUMBURL:"https://res.cloudinary.com/my-room-abroad/image/upload/f_auto,q_auto,c_scale,w_600/v1539854631/",
}
