export default {
  homepage: {
    title: '在亞洲最棒的城市裡找到溫暖的家',
    enterLocation: '輸入地點',
    search: '搜尋',
    trust: '與以下機構有官方合作',
    ntnu: '台師大',
    ntust: '台科大',
    nccu: '政大',
    reachToTeach: 'REACH TO TEACH',
    taipeiTech:"Taipei Tech",
    whyBook: '為什麼要在My Room Abroad訂房？',
    whyBookText: '在國外找到適合的居所是一個很大的挑戰，我們也曾遇到這項難題。這也是為什麼我們希望能為您創造一個既安全又方便的平台，讓您可以輕鬆地瀏覽上千個在臺北並被我們認證過的房間。',
    whyBookStep1: '瀏覽上千間房間，並預訂最喜歡的那一間',
    whyBookStep2: '在48小時內收到房東的確認',
    whyBookStep3: '完成！期待您的入住',
    moreThan: '超過',
    students: '250位學生',
    fromOver: '來自',
    countries: '超過20個國家',
    book: '預訂',
    places: '2000間認證房間',
    teamTitle: '我們擁有最棒的團隊',
    teamText: '一週七天不間斷的服務',
    about: '關於我們',
    landlordTitle: '您是一位優良的房東嗎？',
    landlordText: '每年亞洲有超過一百萬個留學生在異鄉找尋適合自己的房間，他們在尋找像您一樣是接受中短期租約的好房東。您有房間在出租嗎？',
    landlordBtn: '創辦帳號'
  },
  dropzone: {
    upload: '上傳',
    drag: '拖曳以更換展示順序',
    uploadMore: '上傳更多照片',
    remove: '移除',
    dropText: '點選此處以上傳照片',
    removeText: '移除照片',
    selectRoom: '選擇房間',
    commonArea: '公共區域',
    kitchen: '廚房',
    wet: '乾濕無分離',
    dry: '乾濕分離'
  },
  userRequest: {
    reqText: '房東有48小時可以決定是否將房間出租給您。一旦他接受，房間即歸您所有，而您也會立刻收到付款請求。',
    cancel: '取消訂單',
    pastText: '再次預訂或選擇其他房間',
    acceptText: '您的訂房要求已被接受，請繼續完成付款程序。',
    invoice: '查看賬單',
    paidText: '這些是房東的聯絡資訊：',
    llName: '房東姓名：',
    llEmail: '房東電子信箱：',
    declTitle: '您的訂房要求已被婉拒',
    declText2: '沒關係，還有其他更棒的房間！',
    declText3: '請參考其他房間',
    declText4: '或臉書私訊我們，讓我們幫助您訂房',
    declText5: '很抱歉，有人比您更早預訂這間房間',
    declText6: '房東推薦這間房間給您：',
    declBtn1: '查看',
    declBtn2: '查看其他房間',
    cancelTitle: '取消訂單？',
    cancelText: '您確定要取消請求嗎？',
    cancelKeep: '保留房間請求',
    minLength: '最短租期',
    contractType: '合約類型',
    contractDates: '可入住日期',
    mid: '入住',
    mod: '遷出',
    midError: '請輸入入住日期',
    modError: '請輸入遷出日期',
    genderError: '請輸入性別',
    payment: '付款明細',
    toMRA: '您將會向My Room Abroad支付',
    firstRent: '第一個月租金',
    couplesSurcharge: '一人以上入住額外費用',
    fee: '一次性的服務費',
    feeHelp1: 'My Room Abroad將會對每筆訂單額外收取一次性的服務費，費用依據每月租金而有所不同。',
    feeHelp2: '這包含銀行費用、手續費，也讓我們能在未來提供消費者更多保障。',
    feeHelp3: '保障房客可在24小時內對房間廣告不實作出申訴並要求退款。',
    feeHelp4: '客戶服務全年無休。',
    discount: '折扣',
    total: '總共',
    charge: '您只有在訂房請求被房東接受後才會收到付款請求。',
    reqStatus: '等待房東確認中',
    declStatus: '房東拒絕了您的訂房請求',
    acceptStatus: '房東接受了您的訂房請求',
    paidStatus: '我們已收到您的匯款',
    pastStatus: '房東未能在48小時內回覆您',
    couple: '雙人',
    single: '單人',
    directions: '在Google地圖上獲取路線',
    invoice: '查看付款明細'
  },
  paymentPage: {
    invoice: '付款明細',
    invoiceFor: '付款人',
    dueDate: '付款期限',
    payableTo: '收款人',
    mra: 'My Room Abroad',
    accountHolder: '帳戶持有人',
    accountNumber: '銀行帳號',
    accountName: "收款人戶名",
    branchCode: "分行代碼",
    description: '費用項目',
    firstRent: '首月房租',
    fee: '服務費+手續費',
    amount: '金額',
    discount: '折扣',
    subtotal: '總共',
    currencyTotal: '總共（依照喜好貨幣）',
    totalToPay: '總共'
  },
  tenants: {
    tenants: '房客',
    search: '搜尋',
    noResults: '無結果',
    room: '房間',
    name: '姓名',
    email: '電子郵件',
    nationality: '國籍',
    mid: '入住日',
    mod: '遷出日',
    rent: '首月租金',
    cmid: '合約開始日',
    cmod: '合約結束日',
    waiting: '等待付款中',
    paid: '已付款'
  },
  navbar: {
    signup: '註冊',
    login: '登入',
    hi: '嗨',
    myTenants: '我的房客',
    newListing: '+ 刊登房源',
    myBooking: '我的訂單',
    incomingReqs: '房客請求',
    myListings: '我的物件',
    profile: '個人資料',
    logout: '登出',
    menu: '主選單'
  },
  loading: {
    loading: '請稍候'
  },
  datePicker: {
    clear: '清除',
    cancel: '取消',
    confirm: '確認'
  },
  trust: {
    verification: '認證',
    verificationText: 'My Room Abroad對其房東有分等級的信任制度，讓房客可以更加信任平台上的房東。一共分四個等級：',
    exclHead: '合作房東',
    exclTitle: 'My Room Abroad的合作房東',
    exclText: '合作房東為已與My Room Abroad有長期合作，並為My Room Abroad所信任。',
    visitHead: '到訪房間',
    visitTitle: 'My Room Abroad到訪過並信任的房間',
    visitText: 'My Room Abroad成員親自到訪認證，並確認其為適合學生居住的房間。',
    verifHead: '認證房東',
    verifTitle: 'My Room Abroad認證過的房東',
    verifText: 'My Room Abroad已認證這位房東，這代表My Room Abroad成員有透過電話或當面向房東暸解房間情況。',
    naHead: '尚未認證',
    naTitle: '我們仍需認證這位房東',
    naText: '我們仍需認證這位房東，目前尚未能對此房東作出評價。'
  },
  uploads: {
    studio: '獨立套房',
    room: '家庭式 套房/雅房',
    apartment: '整層公寓',
    info: '房間資訊',
    details: '房間資訊',
    features: '房間內容',
    billing: '付款細節',
    contract: '合約內容',
    tenants: '以前的房客',
    photos: '照片',
    save: '儲存並繼續',
    unlist: '取消刊登',
    unlistTitle: '您確定要取消刊登這個物件嗎？',
    unlistText: '這表示房客將無法在網頁上看到這個物件，但您仍可以看到先前的訂單，也可以選擇再次刊登這個物件。',
    keep: '保留此物件',
    waiting: '更改設定中，請稍後',
    back: '返回',
    close: '關閉',
    finish: '結束並上傳',
    snackbarMessage: '物件成功上傳',
    newTenant: '新增房客',
    startTyping: '請輸入',
    select: '物件地址（輸入地址後務必從下列建議選項選取，否則無法成功儲存）',
    newApartmentTitle: '上傳新公寓',
    newApartmentExplanation1: '您將只需要在這裡上傳“整層公寓”的公共空間',
    newApartmentExplanation2: '整層公寓上傳完畢後才能開始上傳家庭式 套房/雅房',
    newApartmentExplanation3: '請在此處輸入公寓資訊、相關合約資訊與“公共區域”的照片及影片',
    newApartmentExplanation4: '這些資訊將會在家庭式 套房/雅房的廣告中出現',
    newApartmentExplanation5: '如果您已刊登過“整層公寓”，並想新增其中的“家庭式 套房/雅房”，請在刊登房源選項中選取家庭式 套房/雅房，並在要新增的公寓照片下方選取“新增分租房間”即可，或',
    linkNewApt: '按這裡前往刊登',
    noShow: '請勿再顯示這則訊息',
    ping: '坪'
  },
  generalInfo: {
    apartmentsTitle: '整層公寓標題',
    aptTitleHint: '給您的公寓取個名稱',
    studioTitle: '套房標題',
    roomTitle: '房間標題',
    titleHint: '盡量讓物件名稱簡潔而有吸引力',
    studioName: '套房名稱',
    nameHint: '取一個自己可以辨認出房間的名稱！',
    apartmentName: '公寓名稱',
    roomNumber: '房間編號',
    numberHint: '這個編號將會顯示在公寓名稱後面',
    nextAv: '可入住日期',
    successBookings: '成功預訂次數',
    successBookingsHint: '這個物件過去成功被預訂幾次呢？',
    description: '描述',
    descriptionHint: '給您的物件做一個簡短的描述',
    floor: '樓層',
    floorHint: '您的物件在幾樓？',
    tenantsText: '告訴對方誰曾經住在這裡有助於房間出租！',
    currentTenants: '現在的房客',
    nameErrReq: '名稱不能空白',
    descriptionErrMax: '字數勿超過500字',
    descriptionErrReq: '房間描述不能留白',
    titleErrReq: '標題不能留白',
    titleErrMin: '標題至少需要{number} 字',
    numberErrReq: '請提供物件編號',
    dateErrReq: '請提供可入住日期'
  },
  features: {
    addBathroom: '增加廁所',
    bedType: '床的類型',
    bedTypeOptionNone: '無',
    bedTypeOptionSingle: '單人',
    bedTypeOptionDouble: '雙人',
    bedTypeHint: '此物件提供哪種類型的床呢？',
    size: '物件佔地面積',
    sizeHint: '請選擇符合此物件佔地面積的選項',
    kitchen: '炊煮功能',
    girlsOnly: '限女生',
    furniture: '傢具',
    commonArea: '公共空間',
    bedrooms: '房間數',
    bedroomsHint: '此物件有幾間房間呢？',
    features: '內容',
    bedsheets: '床套',
    ac: '冷氣',
    fan: '電風扇',
    curtains: '窗簾',
    mattress: '床墊',
    pillow: '枕頭',
    sheets: '床套',
    desk: '書桌',
    chair: '椅子',
    wardrobe: '衣櫥',
    openHanger: '吊衣架',
    shelve: '書櫃',
    washingMachine: '洗衣機',
    tv: '電視',
    couch: '沙發',
    coffeeTable: '茶几',
    privateBathroom: '獨立廁所',
    outsideWindow: '對外窗',
    innerWindow: '對內窗',
    petsAllowed: '可養寵物',
    wifi: '網路',
    balcony: '陽台',
    rooftop: '屋頂空間',
    elevator: '電梯',
    security: '管理員',
    cleaningService: '清潔服務',
    trashService: '收垃圾服務',
    kitchenOptions: {
      none: '無',
      basic: '基本炊煮工具',
      full: '標準廚房'
    }
  },
  billing: {
    rent: '月租金',
    rentHint: '此物件的月租金是多少？',
    deposit: '押金',
    depositHint: '此物件的押金為多少？',
    utilities: '水電費',
    rentIncluding: '租金已含水電費',
    utilitiesOptions: {
      included: '租金包含水電費',
      fixed: '每月固定金額',
      split: '房客分擔水電費',
      other: '其他'
    },
    utilitiesHint: '房客通常偏好固定水電費金額並包含在租金內',
    utilitiesAmount: '水電費金額',
    utilitiesComment: '水電費備註',
    utilitiesCommentHint: '在這向房客解釋租金包含項目，或向房客解釋水電費計算方式',
    couplesAllowed: '接受兩人',
    couplesExtraUtilities: '兩人同居額外需支付費用',
    couplesExtraUtilitiesHint: '兩人同居額外需支付租金/水電費',
    priceErrReq: '請輸入月租金金額',
    depositErrReq: '請輸入押金金額'
  },
  contractDetails: {
    minLength: '最短租期',
    minLengthHint: '短租期房間較受國際租客青睞',
    contractFlexibility: '合約起始日',
    contractFlexibilityHint: '合約起始彈性越大越容易找到租客。',
    contractLanguage: '合約語言',
    contractLanguageHint: '英文合約更受國際租客青睞，我們也提供英文制式合約。',
    rules: '房客規範',
    rulesTexts: {
      noSmoking: '禁止吸菸',
      noPets: '禁養寵物',
      girlsOnly: '限女生',
      noParties: '禁止派對',
      noGuests: '禁止客人留宿',
      extensiveIntro: '要求房客完整介紹自己',
      noSublease: '禁止轉租'
    },
    otherRules: '其他規範',
    otherRulesHint: '請在此處補充說明您所訂定的其他規範',
    minLengthOptions: {
      one: '一個月',
      three: '三個月',
      five: '五個月',
      twelve: '十二個月',
      open: '合約在房客找到下位房客後才結束'
    },
    contractFlexibilityOptions: {
      any: '合約可在任何時刻開始與結束',
      oneMonth: '合約可在任何時刻開始，並為期最少一個月',
      firstOr: '合約在每月1號或15號開始',
      first: '合約永遠在每月1號開始'
    },
    contractLanguageOptions: {
      english: '英文',
      both: '中文與英文',
      chinese: '中文'
    }
  },
  visual: {
    showTenants: '讓房客知道房間裡面的樣子',
    addVideo: '附上房間影片對房間出租非常有幫助！',
    video: 'Youtube影片連結',
    videoHint: '添加視頻會使您的公寓租出機率增加200％。'
  },
  details: {
    rooms: '房間',
    sharedBathrooms: '公用廁所',
    doubleBed: '雙人床',
    singleBed: '單人床',
    noBed: '無',
    cancelPol: '此房東的取消訂房政策',
    studio: '套房',
    contract: '合約',
    videoTour: '影片',
    featureTitle: '內容',
    small: '小',
    average: '標準',
    large: '大',
    bathroom: '廁所',
    kitchen: '廚房',
    basicKitchen: '這意味著沒有真正的廚房，只有加熱的器具，不適合實際烹飪。 有關詳細信息，請參閱圖片。',
    noKitchen: '別擔心，這在台灣非常普遍，大多數時候在外面吃飯要比自己做飯便宜。',
    minLength: '最短租期',
    minLengthLong: '通常在長期合約中，您可以在離開時轉租合約。 請檢查規則中的詳細信息以確認。',
    contractType: '合約起始日',
    contractLanguage: '合約語言',
    contractInfo: '合約資訊',
    bathroomWet: '乾濕無分離廁所',
    bathroomDry: '乾濕分離廁所',
    wetBathroom: '淋浴間和浴室其他部分之間沒有分開。 ',
    dryBathroom: '淋浴間和浴室其他部分之間有分開。 ',
    rentIncluding: '租金包含水電費',
    utilitiesSplit: '每月月底會收到水電費帳單，您將與其他房客平均分攤。',
    couplesNotAllowed: '不允許兩人同住',
    moreRules: '其他規範',
    ruleClean: '每位房客有義務維持公共空間的整潔',
    ruleDocs: '證件需求：護照/身分證',
    ruleSchedule: '房客須輪流打掃',
    otherTenants: '此公寓的其他房客',
    smokingAllowed: '允許吸煙',
    futureTenants: '未來的室友',
    noDateSet: '請在搜尋處輸入入住與遷出日期已開啟此功能',
    oneMonthContr: '租期最短1個月',
    threeMonthContr: '租期最短3個月',
    fiveMonthContr: '租期最短5個月',
    twelveMonthContr: '租期最短1年',
    openContr: '合約在找到下位房客才結束',
    viewRoom: '查看房間'
  },
  otherTenants: {
    student: '學生',
    worker: '上班族'
  },
  bottomBar: {
    detailsPrice: '付款明細',
    payToMRA: '您將會向My Room Abroad支付',
    perMonth: '/月',
    book: '訂房',
    bookingDates: '入住日'
  },
  resultsPage: {
    now: '現在',
    noData1: '很抱歉，未找到符合條件的相關結果',
    noData2: '請移除篩選條件或移動地圖以尋找更多房間',
    removeFilters: '清除篩選條件',
    dates: '日期',
    budget: '預算',
    yourBudget: '您的預算',
    budgetHint: '選擇您的預算',
    propertyType: '物件類型',
    filters: '篩選',
    orderBy: '依照',
    orderByOptions: {
      oldest: '舊物件置上',
      newest: '新物件置上',
      closestToMid: '最接近入住日置上',
      distance: '與搜尋目標的距離',
      priceLtH: '價格（低到高）',
      priceHtL: '價格（高到低）'
    },
    any: '任何',
    propertyTypes: {
      room: '家庭式 套房/雅房',
      studio: '獨立套房'
    },
    min: '分鐘',
    seeRooms: '依套房/雅房搜尋',
    seeApartments: '依整層公寓搜尋',
    numberOfRooms: '＃間房間'
  },
  requestsInbox: {
    bookingRequests: '訂房紀錄',
    addListing: '刊登其他物件',
    noCurrentTitle: '您目前沒有任何訂房請求',
    noCurrentText1: '太好了！您已處理完當前所有訂房請求',
    noCurrentText2: '如果有新的訂房請求，我們會以電子郵件通知您',
    noCurrentText3: '😄',
    noAcceptedTitle: '您目前沒有任何已接受的訂房請求',
    noAcceptedText1: '您到目前為止沒有任何已接受的訂房請求',
    noPastTitle: '您沒有任何錯過的訂房請求',
    noPastText1: '太好了！您總是能及時回覆您收到的訂房請求',
    noPastText2: '謝謝您總是能回復如此迅速！',
    noDeclinedTitle: '您沒有任何拒絕的訂房請求',
    noDeclinedText1: '您到目前為止沒有任何拒絕的訂房請求',
    noCanceledTitle: '您沒有任何被房客取消的訂房請求',
    noCanceledText1: '通常房客不會取消預訂，所以這是正常的。',
    declineTitle: '婉拒訂房請求',
    declineReasonLabel: '選擇您婉拒的原因',
    setNextAv: '設定下個可入住日期',
    setNextAvHint: '請輸入合約結束日',
    suggest: '推薦您的其他房間給這位房客',
    from: '來自',
    confirm: '確認',
    reasonBooked: '此物件已被預訂',
    reasonTenant: '房客不適合此房間',
    onePerson: '一人',
    accept: '接受',
    decline: '婉拒',
    details: '細節',
    realDates: '實際入住與遷出日期',
    aboutTenant: '關於房客',
    current: '現在',
    accepted: '已接受',
    past: '錯過',
    declined: '已拒絕',
    canceled: '房客已取消',
    warning:"注意",
    warnText:"您已經有接受了另一筆訂單，且那筆訂單跟這筆訂單的住宿日期是有重疊的! 您確定還要接受這筆訂單嗎?",
    acceptBooking:"仍要接受",
  },
  profile: {
    username: '用戶名',
    currency: '使用的貨幣',
    name: '姓名',
    nameHint: '請輸入與護照相同的姓名',
    birthday: '生日',
    gender: '性別',
    male: '男',
    female: '女',
    passport: '護照號碼',
    occupation: '職業',
    occupationHint: '您是學生還上班族？',
    school: '學校/公司名稱',
    schoolHint: '您將要去哪就讀或工作呢？',
    about: '向房東介紹一下自己',
    aboutHint: '您提供的信息越多，您獲得房間的可能性就越大！',
    basicProfile: '基本資料',
    changeEmail: '更改電子信箱',
    newEmail: '新的電子信箱',
    save: '儲存',
    currency: '貨幣別',
    officialInfo: '資訊',
    personalDescription: '個人簡述',
    aboutYou: '關於你',
    changeEmailText1: '您確定要將電子信箱更改成{newEmail}',
    changeEmailText2: '這個步驟將無法回復',
    yourProfile: '您的個人檔案',
    editInfo: '修改資訊',
    deleteAccount: '刪除帳號',
    editProfile: '修改個人檔案',
    cancelPolicy: '您的取消預訂政策類別',
    policies: '政策',
    cancelPolHint: '有關取消政策的更多信息，請參閱我們的取消政策頁面或使用條款12.3。',
    verified: '太好了！您已被成功認證',
    verificationRequested: '已送出認證請求，我們會盡快聯繫您！',
    getVerified: '認證',
    address: '地址',
    communicationInfo: '聯絡資訊',
    phone: '手機號碼',
    line: 'LineID',
    wechat: 'WeChatID',
    facebook: '臉書網址',
    preferredMethod: '偏好的聯絡方式',
    preferredMethodHint: '您偏好的聯絡方式是什麼呢？',
    bankInfo: '銀行帳戶資訊 （每個項目請務必以“英文”輸入）',
    swift: '國際轉帳代碼（Swift code）',
    bankName: '銀行名稱',
    bankCode: '銀行代碼',
    bankAddress: '分行地址',
    flexible: '寬鬆',
    moderate: '適度',
    strict: '嚴格',
    noRefund: '不退款',
    emailError: '請提供有效的電子信箱',
    password: '密碼',
    changePassword: '更改密碼',
    passwordError: '密碼最少為六個字母',
    whatsapp: 'WhatsApp',
    lineApp: 'Line',
    wechatApp: '微信',
    facebookApp: 'Facebook臉書',
    sendChinese:'希望以“中文”信件通知',
    companyId:"統一編號",
    companyName:"公司名",
    companyInv:"我要統編",

  },
  landlordDashboard: {
    apartmentsTitle: '您的公寓',
    noAptTitle: '讓我們來建立您的第一個公寓吧！',
    noAptText: '點擊下方按鈕開始',
    addApt: '新增公寓',
    studiosTitle: '您的套房',
    noStudioTitle: '讓我們來建立您的第一個套房吧！',
    addStudio: '新增套房',
    unnamedRoom: '未命名的房間',
    edit: '編輯',
    launch: '發佈',
    noTitle: '尚未命名標題',
    manageStudio: '管理套房',
    manageApt: '管理公寓',
    addRoom: '新增分租房間'
  },
  bookingRequest: {
    bookingSummary: '預訂摘要',
    midDiffText: '房東已經制定了有關合約開始日期的規定。',
    dateDiffText1: '因此，我們必須改變合約上的可入住日期。',
    dateDiffText2: '如果您對此有任何疑問，請隨時與我們聯繫。',
    modDiffText: '房東已經制定了最短租期和規則。',
    completeRequest: '完成您的訂房',
    importantInfo: '重要資訊！',
    checkRules: '請詳閱所有房間規範',
    process: '訂房程序',
    nextSteps: '下一步',
    timeline1: '房東有',
    timelineBold: '48小時',
    timeline2: '可以決定是否接受您的訂房請求。',
    afterPayment: '付款完畢後，預訂程序即完成，房間就是您的了！',
    whenArriving: '當您抵達後',
    guarantee: 'My Room Abroad ',
    refundText1: '提供您',
    refundBold: '24小時',
    refundText2: '通知我們房間廣告不實而要求退款的權利',
    arrival1: '在您入住後，您必須',
    arrival2: '支付押金',
    arrival3: '簽署租賃合約',
    departureTitle: '在您遷出時',
    departure1: '如果您保持房間良好並履行合約的所有協議，房東將退還押金。',
    departure2: '如果您提早遷出，房東可能會沒收部分押金。',
    rules1: '關於這間房子',
    agree1: '我瞭解並同意',
    agree2: '我同意',
    agreeToRulesAbove: '在進行下一步之前，請先同意最後一頁中的住房規範。',
    continue: '繼續',
    personalInfo: '個人資訊',
    coupon: '優惠券',
    validateCoupon: '驗證優惠券',
    payment1: '您現在不用支付任何款項',
    payment2: '我們將會在您的訂單被房東接受後向您發出付款請求',
    payment3: '我們目前正在準備線上支付系統，不久後就會上線了！到時您將可以更簡單地支付租金。現階段我們接受以下貨幣的匯款， 請選擇您要支付的貨幣。',
    payment4: '您希望透過哪種貨幣支付呢？',
    payment4Hint: '您使用其他貨幣支付的匯率將與您在google上看到的匯率不同。',
    payment5: '所有租金都用此貨幣支付。',
    confirmBooking: '確認我的訂房',
    diffDates1: '這位房東設定了最短租期，比您實際住宿時間長。 <br/>您的住宿日期如下：從{mid}到{mod}',
    diffDates2: '請確認您的訂房',
    sameDates: '您所要求的日期房東可以接受。 你的合約將從{mid}到{mod}',
    coupleAsk: '您是一人還是兩人入住呢？'
  },
  auth: {
    confirmPassword: '確認密碼',
    resetPassword: '重設密碼',
    accept: '我同意',
    terms: '服務使用條款',
    landlord: '我是房東',
    keepLogged: '保持登入',
    createUser: '創辦帳號',
    usernameErr: '輸入用戶名稱',
    emailErrExists: '此電子信箱已被使用過',
    noPassword: '請輸入密碼',
    passwordErrorEmpty: '請再次輸入密碼',
    passwordErrorMatch: '密碼不吻合',
    changePwd: '更改密碼',
    newPassword: '新密碼',
    forgotPassword: '忘記密碼',
    generalError: '電子信箱與密碼不吻合',
    passwordResetEmail: '密碼重置信件已寄出，請檢查您的電子信箱以繼續',
    userNameRegexErr:'僅限輸入英文',
  },
  footer: {
    home: '首頁',
    blog: '部落格',
    careers: '工作機會',
    faq: '常見問題',
    howItWorks: '如何運作',
    policies: '政策',
    subscribe: '訂閱',
    getNewsletter: '收到我們的即時訊息',
    submit: '確認'
  },
  newListing: {
    listingType: '您想刊登哪種房源呢？',
    apartmentText: '公寓，包括廚房和浴室等共用空間及其他學生可以租用的多個房間。',
    createApartment: '刊登公寓',
    studioText: '套房，包括一個房間和浴室，有時可能有廚房。',
    createStudio: '刊登套房',
    roomText: '當您已刊登公寓後，可刊登分租套房及分租雅房',
    viewDashboard: '查看房間管理頁面',
    apartmentType: '房間合約',
    roomContractText: '允許租戶在您的公寓租一間房間。 這意味著每個房間可以租用不同的時間。您的物件將會以單間房間及整間公寓的方式呈現。',
    completeApartmentText: '租戶只能一次租整間公寓，不接受以單間房間出租。您的物件將會以整間公寓的方式呈現。',
    roomContracts: '房間為單位出租',
    wholeFlat: '整間公寓為單位出租',
    addTenant: '新增房客',
    howToAddApt: "如何上傳公寓？",
    howToAddRoom:"如何上傳房間？",
    howToAddStudio:"如何上傳獨立套房?"
  },
  hiw: {
    hiw: '如何運作',
    title1: '找到喜歡的房間。',
    text11: 'My Room Abroad讓您能輕鬆在網路上挑選適合自己的房間，所有刊登物件都有非常清楚且系統化的描述。此外，也可以根據物件位置、價格、房間內容等功能進行篩選比較。',
    text12: '平台上的每個房間都有詳細的描述，除了圖片，許多物件甚至還會附上影片！讓您能更對房間一覽無遺。',
    text13: '為了確保房間的品質保證，My Room Abroad有一套',
    link1: '認證系統',
    text14: '共分為四個等級',
    text15: '',
    li1: '尚未認證: 我們仍需認證這位房東，目前尚未能對此房東作出評價。',
    li2: '認證房東: My Room Abroad已認證這位房東，這代表My Room Abroad成員有透過電話或當面向房東暸解房東與房間情況。',
    li3: '到訪房間 : My Room Abroad成員親自到訪認證，並確認其為適合學生居住的房間。',
    li4: '合作房東：合作房東為已與My Room Abroad有長期合作，並為My Room Abroad所信任。',
    text16: '',
    title2: '預訂房間',
    text21: '一旦確定了自己喜歡的房間，您可以點擊右下角的“訂房”按鈕進行預訂。',
    text22: '點擊此按鈕後，您必須輸入入住和遷出日期。注意，一些房東對最短租期設置了限制。平台將自動阻擋不符合房間最短租期的請求。之後，您必須同意所有房屋規範，填寫您的個人資訊和付款資訊。',
    text23: '填寫完所有資訊後，預訂請求將發送給房東。在等待房東接受的同時，您無法再預訂其他房間。',
    text24: '房東有48小時可以回答你的請求。',
    text25: '只有在接受請求後，我們才會向您發出付款請求與明細。',
    text26: '當請求被房東接受，且您在期限內付款，房間就是你的了！',
    title3: '付款',
    text31: '一旦您的預訂被房東接受，您需要支付第一個月租金和My Room Abroad一次性服務費以保留房間。',
    text32: '您可以通過信用卡或銀行轉賬方式使用您自己的貨幣或新台幣向My Room Abroad付款，然後My Room Abroad將以新台幣將房租轉給房東。您必須在48小時內付清款項以保留房間。',
    text33: '',
    title4: '與房東聯繫',
    text41: '付款完成後，您的房間即成功保留。您將收到房東的聯絡資訊，即可與您的房東聯繫入住事宜。',
    text42: '此外，您現在是MRA大家庭的一員，歡迎加入我們的Facebook社團。在這裡我們會組織各種活動，例如戶外郊遊、踏青或一些室內派對。您會在這裡遇到來自世界各地的夥伴，彼此分享生活經驗，讓您身處異鄉卻一點也不寂寞！',
    title5: '抵達/簽訂合約',
    text51: '您抵達房間的那天，房東將迎接您，並簽署租賃合約，My Room Abroad也會提供制式的中英文合約給房東。',
    text52: '簽訂合約後，房東遞交鑰匙。然後，您必須支付押金。您可以選擇當場支付現金或轉帳給房東，或者向My Room Abroad支付押金，My Room Abroad在幫您匯給房東。一樣，您可以通過信用卡或銀行轉帳並以您自己的貨幣或新台幣支付給My Room Abroad。',
    title6: '住宿後',
    text61: '押金由房東還給學生。',
    learnMore: '暸解更多',
    q1: '如果房間內容與平台上的廣告不相符怎麼辦？',
    a1: '如果My Room Abroad上的物件描述與現實不符，那麼在您入住24小時內，有權利通知My Room Abroad，團隊成員將盡快評估您的投訴。',
    a2: '如果您的投訴屬實，您有權利退房，My Room Abroad將幫助您找到另一個房間（不收取服務費）。',
    a3: ''
  },
  llhiw: {
    title1:"上傳您的公寓",
    t11:"My Room Abroad是一個使用方式簡單的租屋平台，您可以輕鬆在這管理所有公寓。",
    t12:"在平台註冊帳號後，您就可以直接上傳您的物件了哦。",
    t13:"不要忘記填寫您的個人資料！",
    t14:"您需要：",
    li141:"選擇取消政策",
    li142:"填寫您的基本資料",
    li143:"銀行帳戶資訊，以便我們輕鬆將您的租金匯給您",
    title2:"管理您的公寓",
    t21:"請記得隨時更新物件資訊及空房日期，以維持其準確性！如果您在平台上上傳錯誤信息，您將有責任補償已付款的房客。",
    t22:"點擊屏幕右上角下拉列表中的“我的物件”，您將來到您的管理介面。在這裡，您可以查看您的所有物件並輕鬆更新每個房間的可入住日期。",
    title3:"管理您的預訂請求",
    t31:"一旦您的物件在平台上傳，用戶就可以在My Room Abroad上挑選並預訂。",
    t32:"當用戶預訂您的某個物件時，您將會收到Email通知。",
    t33:"然後，您可以到https://myroomabroad.com/登入後，點擊屏幕右上角下拉列表中的“房客請求”查看。",
    t34:"在“現在”選項中，您將看到所有新的預訂請求。對於每個請求，您將看到職業、性別、國籍、合約日期、人數以及房客的自我介紹。",
    t35:"您有48小時的時間來回覆預訂請求，如果您未在48小時內回覆請求，預訂將自動取消（並顯示在“錯過”選項中）。",
    t36:"您接受的所有預訂請求都將顯示在“已接受”選項中。您拒絕的所有請求都將顯示在“已拒絕”選項中中。如果租戶在您接受或拒絕之前取消了他的請求，該請求將顯示在“房客已取消”選項中。接受或拒絕預訂",
    title4:"您可以接受或拒絕您收到的預訂請求。",
    t41:"拒絕後：如果您願意，可以向租客推薦您的其他物件。",
    t42:"接受後：請注意，這相當於簽訂合約！一旦您接受租客的預訂請求，您必須為他/她保留房間。",
    t43:"",
    title5:"當您接受預訂請求時",
    st51:"在租客抵達之前：",
    sst511:"租客付款",
    t5111:"一旦您接受該請求，系統將向租客發出付款要求，要求其支付首月房租以作為訂金。",
    sst512:"與租客取得聯繫",
    t5121:"當租客支付首月租金時，您將收到Email通知，同時也將取得租客的電子郵件。",
    t5122:"登錄帳號後，您將能夠在屏幕右上角下拉列表中“我的房客”看到新租客的名稱和電子郵件地址。",
    t5123:"您有責任向該租客發送電子郵件以歡迎他/她並安排入住日的會面。",
    sst513:"匯出您的款項",
    t5131:"我們每月進行兩次轉賬，在15日和當月的最後一天。我們會將首月租金轉帳給您。(1~15日收到的款項會在15號匯出; 16~31日收到的款項會在當月最後一日匯出）",
    st52:"當租客抵達時",
    t521:"您有責任歡迎租客入住您的公寓。您需要安排租客的入住細節，以確保他可以輕鬆找到公寓並領取鑰匙。",
    sst521:"與租客簽訂合約",
    t5211:"你將在你的公寓與他見面並與他簽訂合約。",
    sst521:"租客支付押金",
    t5211:"簽訂合約後，租客將直接向您支付押金。租客可以選擇直接支付押金給您，或是透過My Room Abroad支付。",
    sst522:"從My Room Abroad獲得所有月租金",
    t5221:"除非另有約定，否則我們每個月都會向您的租客收取租金（他們可以通過該平台輕鬆在線支付）。一旦我們收到租金，我們將會抽取平台佣金（月租金3%），再將扣除佣金後的租金匯給房東。(1~15日收到的款項會在15號匯出; 16~31日收到的款項會在當月最後一日匯出）",
    st53:"租客離開",
    sst531:"退還押金給租客",
    t5311:"在合約結束時，您有責任將押金退還給承租人。"
  },

  aboutUs: {
    title: '關於我們',
    subTitle: 'My Room Abroad讓外國房客可以在國外就訂到自己想要的房間',
    t1: '一切都從2015年開始。當Sebastien與Vincent得知成功錄取交換學生計畫，開心慶祝之時，他們沒有想到，迎接他們的卻是一連串痛苦的過程。初到異鄉，面對不熟悉的國外租屋市場，除了語言的隔閡，要找到接受外國房客的房東更是困難。而在歷經千辛萬苦之後，好不容易找到的房東更是仗著兩人對當地的陌生，提高房價、敲詐他們。',
    t2: '這樣不堪的經驗在Sebastien與Vincent的心中種下一顆等待發芽的種子。',
    t3: '畢業後，這顆種子開始發芽茁壯。Sebastien與Vincent不願再看到同樣的情形發生在其他交換生身上，因而創立了一個全英文的租屋平台，除了提供符合外國房客需求的租房資訊，也幫助學生過濾不良房東。因此， 2017年2月，背負著重要的使命，My Room Abroad就這樣誕生了。',
    t4: '',
    t5: 'My Room Abroad創立至今，已幫助超過200位學生在台北找到合適的住所，但我們的使命已不再只是成為一個租房網站，而是成為一個國際旅人在台灣的避風港，一個“家”的概念。透過定期舉辦的活動，各國人士互相了解他國文化，欣賞彼此的不同，並一起在台灣這塊溫暖的土地上，享受自然之美，感受台灣人的熱情。我們期許多年之後當他們再次來到台灣，下飛機的那一刻，感受到的不再是踏入異鄉冒險的不確定感，而是感覺到「家的溫暖」。',
    t6: ''
  },
  pricing: {
    flexprice: '租金依租期長短變動',
    help: '請輸入不同租期長短所對應的租金及押金',
    rent: '月租金',
    deposit: '押金',
    monthly: '一個月',
    monthlyHelp: '請輸入租期一～二個月的租金金額',
    monthlyHelpD: '請輸入租期一～二個月的押金金額',
    three: '三個月',
    threeHelp: '請輸入租期三～四個月的租金金額',
    threeHelpD: '請輸入租期三～四個月的押金金額',
    five: '五個月',
    fiveHelp: '請輸入租期五～十一個月的租金金額',
    fiveHelpD: '請輸入租期五～十一個月的押金金額',
    year: '一年',
    yearHelp: '請輸入租期長於一年的租金金額',
    yearHelpD: '請輸入租期長於一年的押金金額',
    phoneOrId: '電話號碼/LINE, WeChat or Whatsapp ID/ 個人社交軟體連結',
    contractLength: '合約長短'
  },
  titles: {
    openContract: '合約在找到下個房客後才結束',
    roomDescription: '房間描述',
    aptDescription: '公寓描述',
    roomFeature: '房間內容',
    aptFeature: '公寓內容'
  },
  refund: {
    title: "退款政策",
    subtitle11: "如果出現以下3個任一情形，皆適用我們的退款政策：",
    t111: "用戶決定取消預訂（在住宿提供方接受預訂請求後）。退款政策取決於我們的服務條款中規定的每個物件上顯示的適用取消政策。",
    t112: "住宿提供方決定取消預訂（在住宿提供方接受預訂請求後）：用戶有權獲得全額退款，包括100％的首付房租、一次性服務費和押金。",
    t113: "用戶提交投訴，經My Room Abroad評估後，視為有效。",
    subtitle12: "有關退款政策1.1（c），用戶的投訴必須符合以下所有條件才算有效：",
    t121: "投訴符合 “涵蓋情況”（如1.3中所定義）中的任意一項;",
    t122: "用戶在My Room Abroad網站上支付住宿費用。為避免疑義，My Room Abroad不對用戶在My Room Abroad網站以外的任何付款負責;",
    t123: "用戶已採取所有合適的方法與住宿提供方解決問題;",
    t124: "由提出預訂請求的用戶提交投訴，而非由第三方提交;",
    t125: "用戶在入住日算起24小時內通知我們，並完全配合My Room Abroad運作;",
    t126: "用戶未直接或間接導致其投訴的情況（包括但不限於用戶的行為及疏失）;",
    t127: "用戶向我們提供了令人信服的證據（照片、視頻或其他證據），證明其屬於“涵蓋情況”（如1.3中所定義）;",
    t128: "用戶向我們發送書面聲明，並附有身份證明和有關投訴情況的詳細說明;",
    t129: "投訴的書面聲明通過電子郵件提交至Hello@myroomabroad.com。",
    subtitle13: "下列情況被視為構成1.2中的“涵蓋情況”：",
    t131: "無效廣告：住宿提供方的廣告物件並非為其所擁有管理權;",
    t132: "無法進入的住宿：用戶已根據服務條款支付住宿費用，並且已遵守住宿提供方於預訂申請時規定的要求，但用戶仍因住宿提供方的錯誤、故意或不法行為（包括住宿提供方破產、詐欺活動或忘記空房日期等等）而被拒絕進入住宿。在租賃期間，My Room Abroad不會為任何延遲進入住宿的用戶進行補償;",
    t133: "虛假陳述：與廣告住宿相比，住宿有重大差異或缺陷（如第1.3節所定義）。 “重大差異或缺陷”的含義應由My Room Abroad自行決定，除其他例外情況外，不應包括物件所在地實際位置、當地景點、特定交通工具的細微差別或與物件周圍環境有關的任何其他外部因素。",
    t134: "My Room Abroad應在合理的時間範圍內完成對其投訴有效性的評估，並將向用戶通報處理投訴的任何決定或所需的其他文件。如果要求用戶提供其他文檔，則用戶必須提供此文檔，且不得無故拖延。",
    t135: "我的海外房間有權自行決定索賠（包括退還給用戶的金額），這些決定是最終且對用戶具有約束力。為避免爭議，如果用戶嚴重違反服務條款，My Room Abroad將不會退款。",
  },
  privacy: {
    privacyPolicy: "Privacy policy",
    heading: "Introduction",
    intro1: "This page is designed to help you understand why and how we use personal data.",
    intro2: "By personal data we mean information that relates to a living individual and can identify or be identified with that individual.",
    intro3: "My Room Abroad provides an online accommodation booking service, which allows them to browse through thousands of properties around the world.",
    intro4: "To do so, we have relationships with tenants, landlords, affiliates (referrers and/or partner organisations) and our suppliers. We process different personal data for each of those categories of person, and we do so for different purposes.",
    title1: "Who operates My Room Abroad and how do I contact them?",
    t11: "The data controller of your information is Lime Tech SPRL, a company with headquarters in Brussels.",
    t12: "We are limited company registered in Belgium with the VAT number BE.0537.421.768 and our registered address is Lime tech SPRL, Marius Renardlaan 27A b327, 1070, Brussels, Belgium. ",
    t13: "If you have any questions or concerns about the information on this page, or about what we do with personal data, you should email hello@myroomabroad.com.",
    title2: "How does My Room Abroad use my personal data as a tenant?",
    t21: "In summary, we use personal data relating to tenants to:",
    l201: "To allow prospective tenants to easily search for flats matching their criteria on return visits.",
    l202: "To provide the landlord with information about a prospective tenant to help them determine whether the prospective tenant’s eligibility to rent their property.",
    l203: "To provide management information to My Room Abroad's partners (e.g. partnered universities or employers).",
    l204: "To ensure the prospective tenant is only offered properties that meet their access requirements.",
    l205: "To process payments from the tenant.",
    l206: "To follow up on bookings which are not able to proceed with, e.g. because the landlord rejects the application or there is an availability issue.",
    l207: "To send marketing to you.",
    l208: "To run its business",
    l209: "To ensure the completeness of a listing on the platform",
    subtitle21: "How does My Room Abroad use personal data to allow prospective tenants to easily search for properties?",
    t211: "As you use your My Room Abroad account to book accommodation you will provide us with your desired location, the dates on which you wish to reside in the property and what your price range will be. My Room Abroad will keep a record of this information and we will be able to present you with suggested properties that it believes match your criteria.",
    subtitle211: "What personal data is used and where is it obtained from?",
    t2111: "We obtain your preferred location(s), your budget and your preferred dates when you first sign up for the service and/or when you change your search criteria on the service.",
    subtitle212: "What is our legal basis?",
    t2121: "Our legal basis for retaining this information is our legitimate interest in ensuring that you are able to easily repeat your search for properties.",
    subtitle213: "Who do we share personal data with for this purpose?",
    t2131: "We do not share this data with anyone for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle22: "How does My Room Abroad use personal data to check whether the tenant is eligible to rent a property?",
    t220: "Landlords seeking to let their properties will have their own discretionary eligibility criteria for tenants.",
    subtitle221: "What personal data is used and where is it obtained from?",
    t2211: "When you submit your booking request, we ask for your full name, email address, passport number, your nationality, age, occupation, gender and an introduction about yourself.",
    subtitle222: "What is our legal basis?",
    t2221: "Our legal basis is that this process is a necessary preparatory step for the landlord to enter into a tenancy agreement with you.",
    t2222: "Without this information, the landlord may not be able to consider entering into a tenancy agreement with you.",
    subtitle223: "Who do we share personal data with for this purpose?",
    t2231: "This information will be shared with the relevant landlord so that they can decide whether you meet their tenant criteria.",
    t2232: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle23: "How does My Room Abroad use personal data to provide management information to its partners?",
    t230: "Our affiliate programme allows our partners to refer prospective tenants to My Room Abroad and potentially receive a commission payment for each tenant they introduce that goes on to make a booking. We therefore provide them with information relating to the number of people they have referred that have gone on to make a booking with My Room Abroad.",
    subtitle231: "What personal data is used and where is it obtained from?",
    t2311: "When you make your booking, we ask for your place of work and/or study.",
    subtitle232: "What is our legal basis?",
    t2321: "Our legal basis is our legitimate interest in providing our affiliates with accurate information to support the commission payment due to them.",
    subtitle233: "Who do we share personal data with for this purpose?",
    t2331: "Your personal data will not be shared with affiliates, however aggregated data (e.g. a number of students) that does not identify you will be.",
    t2332: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle24: "How does My Room Abroad use personal data to ensure that the tenant is only offered properties that meet their access needs?",
    t240: "We understand that some of our tenants may have certain access requirements. Where provided, we use these details to help you find an appropriate property.",
    subtitle241: " What personal data is used and where is it obtained from?",
    t2411: "At the time of booking, we ask you to provide us with any information about any access requirements you have.",
    subtitle242: "What is our legal basis?",
    t2421: "Our legal basis is that this is a necessary preparatory step to you entering into a contract with the relevant landlord. Without this information, the landlord would not be able to confirm that the property meets your requirements. To the extent that this data is a special category of personal data (in that it may imply a medical condition), we rely on is explicit consent.",
    subtitle243: "Who do we share personal data with for this purpose?",
    t2431: "This information will be shared with the relevant landlord so that they can confirm whether or not the property meets your requirements.",
    t2432: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle25: "How does My Room Abroad use personal data to process payments from the tenant?",
    subtitle251: "What personal data is used and where is it obtained from?",
    t2511: "When you finalise a booking with us, and make an online payment via credit card, we will collect payment details include name, billing address, credit card number, credit card expiry date and their card verification value number.",
    t2512: "My Room Abroad itself does not have access to this data as it is passed straight to our payment provider",
    subtitle252: "What is our legal basis?",
    t2521: "Our legal basis is that this processing is necessary for the performance of the contract between you and the landlord. Without this information it would not be possible to finalise your booking.",
    subtitle253: "Who do we share personal data with for this purpose?",
    t2531: "This data will be sent directly to our payment processor, (e.g. Stripe) to ensure that monies are withdrawn from your account and deposited into ours, and then the landlord’s.",
    subtitle26: "How does My Room Abroad use personal data to follow-up on bookings which have been unable to proceed?",
    t260: "Where you booking does not go through, for instance because the landlord no longer has availability, we might contact you to help you find an alternative.",
    subtitle261: "What personal data is used and where is it obtained from?",
    t2611: "If we contact you, we may use your name, email address, location, budget, preferred dates, date of birth, occupation, gender, details of nationality, place of work and/or study, biography and details of access requirements, if any.",
    t2612: "All of this information is obtained from your original booking request.",
    subtitle262: "What is our legal basis?",
    t2621: "Our legal basis is our legitimate interest in ensuring tenants are able to find an alternative property when a booking falls through.",
    subtitle263: "Who do we share personal data with for this purpose?",
    t2631: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle27: "How does My Room Abroad use personal data for marketing?",
    t270: "Please see the 'How does My Room Abroad use personal data for marketing' section below.",
    subtitle28: "How does My Room Abroad otherwise use personal data in running its business?",
    t280: "Please see the 'How does My Room Abroad otherwise use personal data in running its business' section below.",
    subtitle29: "How does My Room Abroad use personal data to ensure to ensure the completeness of a listing on the platform?",
    t290: "Each listing contains a 'Current tenants' section. We use ‘data from previous and current tenants (user of the My Room Abroad platform) of a specific flat, for new users to be able to know with who they would live if they book this flat.",
    subtitle291: "What personal data is used and where is it obtained from?",
    t2911: "When successfully making a booking, your gender, nationality and occupation will be shown on the specific listing for other users to see who else rents this flat.",
    subtitle292: "What is our legal basis?",
    t2921: "Our legal basis is our legitimate interest in providing our affiliates with accurate information to support the commission payment due to them.",
    subtitle293: "Who do we share personal data with for this purpose?",
    t2931: "This information will be shared with any user checking the listing of the specific flat.",
    title3: "How does My Room Abroad use my personal data as a landlord?",
    t30: "In summary we use personal data relating to landlords:",
    l301: "To process payments to landlords.",
    l302: "To administer bookings, including populating the advert for the landlord's room, listing it when it is available, processing bookings, etc.",
    l303: "To verify the accuracy of the information provided by the landlord.",
    l304: "To send marketing to you.",
    l305: "To run its business.",
    t31: "You can find out more about how we do so by clicking on the relevant heading below.",
    subtitle31: "How does My Room Abroad use personal data to process payments to landlords?",
    t310: "When a tenant pays us for a booking, we pay the landlord amounts due, less our fees, by bank transfer.",
    subtitle311: "What personal data is used and where is it obtained from?",
    t3110: "We use the bank account details and billing address provided in your profile as a landlord on the My Room Abroad platform.",
    subtitle312: " What is our legal basis?",
    t3120: "Our legal basis is that this processing is necessary to perform our contract with you. Without this information we would not be able to pay you.",
    subtitle313: "Who do we share personal data with for this purpose?",
    t3130: "We will share this information with our bank in order to process monies owed to you.",
    t3131: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle32: "How does My Room Abroad use personal data to administer bookings?",
    subtitle321: "What personal data is used and where is it obtained from?",
    t3210: "We will use the details provided by you when you created your account and/or added a property listing, including: name, email address, room/property details, property address, amenities, contract details, house rules, whether you are a resident landlord, tenant preferences, property availability and any images, video or free text volunteered by you.",
    subtitle322: "What is our legal basis?",
    t3220: "Our legal basis is that this processing is necessary to perform our contract with you. Without this information we would not be able to publish your property listing.",
    subtitle323: "Who do we share personal data with for this purpose?",
    t3230: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle33: "How does My Room Abroad use personal data to verify the accuracy of information provided by the landlord?",
    t330: "We may, where we arrange a visit to take photographs of your property, provide personal data to the photographer to allow them to verify the accuracy of your listing.",
    subtitle331: "What personal data is used and where is it obtained from?",
    t3310: "We will use the details provided by you when you created your account and/or added the relevant property listing, including: name, email address, room/property details, property address, amenities, house rules, whether you are a resident landlord, tenant preferences, property availability and any images, video or free text volunteered by you.",
    subtitle332: "What is our legal basis?",
    t3320: "Our legal basis is our legitimate interest in verifying that listings displayed on My Room Abroad are accurate.",
    subtitle333: "Who do we share personal data with for this purpose?",
    t3330: "We share this data with the photographer who visits your property.",
    subtitle34: "How does My Room Abroad use personal data for marketing?",
    t340: "Please see the 'How does My Room Abroad use personal data for marketing' section below.",
    subtitle35: "How does My Room Abroad otherwise use personal data in running its business?",
    t350: "Please see the 'How does My Room Abroad otherwise use personal data in running its business' section below.",
    title4: "How does My Room Abroad use personal data for marketing?",
    t40: "In summary, regardless of whether you are a landlord or tenant, we use personal data:",
    l401: "To market our service to tenants or landlords via email, including sending our newsletters.",
    l402: "To tailor marketing to the recipient’s likely interests.",
    l403: "To target display advertising to particular demographics of person on advertising platforms (e.g. Facebook, Google).",
    l404: "To target display adverts on other websites to particular people based on the pages of our website that they have viewed (re-targeting).",
    l405: "To create 'look-a-like' audiences on the advertising networks that we use.",
    t401: "You can find out more about how we do so by clicking on the relevant heading below.",
    subtitle41: "How does My Room Abroad use personal data to market its services to tenants or landlords via email, including sending its newsletter?",
    subtitle411: "What personal data is used and where is it obtained from?",
    t4110: "Where you are a tenant or landlord, we use the name and email address you provided when you signed up with My Room Abroad.",
    subtitle412: "What is our legal basis?",
    t4120: "When you are a tenant or landlord, our legal basis is our legitimate interest in marketing our service. We will only market similar services and we always offer the opportunity for you to opt-out at the time we collected your email address, and on each email we send thereafter.",
    subtitle413: "Who do we share personal data with for this purpose?",
    t4130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle42: "How does My Room Abroad use personal data to tailor marketing to the recipient’s likely interests?",
    t420: "We will use your personal data to send particular marketing campaigns to you based on attributes you have. For instance, if you are a tenant we might send a particular marketing campaign to a subset of our registered tenants based on their location preferences and budget.",
    subtitle421: "What personal data is used and where is it obtained from?",
    t4210: "We may use any of the information we hold about you for this purpose, but most typically we target our marketing based on your preferred location (if a tenant), the location of your properties (if a landlord).",
    subtitle422: "What is our legal basis?",
    t4220: "Our legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with our services, therefore reducing our cost conversion when marketing our service.",
    subtitle423: "Who do we share personal data with for this purpose?",
    t4230: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle43: "How does My Room Abroad use personal data to display advertising for particular demographics on advertising platforms?",
    t430: "Advertising platforms such as Google and Facebook allow us to target our advertisements to particular types of user, for instance based on their age, location and interests.",
    subtitle431: "What personal data is used and where is it obtained from?",
    t4310: "Adverts are targeted based on the personal data held by the relevant advertising network about you.The advertising networks do not give us access this data, they only give us the ability to target our advertisements based on characteristics of a group such as age bracket or location.",
    subtitle432: "What is our legal basis?",
    t4320: "Our legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with our service, therefore reducing our cost conversion when marketing our service.",
    t4321: "You can object to this processing by changing your privacy settings on the relevant advertising network.",
    subtitle433: "Who do we share personal data with for this purpose?",
    t4330: "My Room Abroad has no access to your demographic information, this is held by the relevant advertising platform (Facebook or Google).",
    subtitle44: "How does My Room Abroad use personal data to display adverts to particular data subjects?",
    t440: "As you use our service, our advertising networks (Google and Facebook) will monitor and record the pages you visit on our website via a tracking pixel or cookie so that we can ask the advertising networks to show you advertisements based on the pages you visited on our website.",
    t441: "For instance, we might ask Facebook to show a particular advert to users who have recently searched for accommodation in a particular city on our website.",
    subtitle441: "What personal data is used and where is it obtained from?",
    t4410: "We will include code within our website provided by the advertising networks that either places a cookie on your device, or that includes an invisible image, both of which allow the advertising network to display adverts to you based on the pages of our website that you have visited.",
    subtitle442: "What is our legal basis?",
    t4420: "Our legal basis is consent.",
    t4421: "You can withdraw your consent at anytime by contacting us, or by changing your privacy settings on the relevant advertising network.",
    subtitle443: "Who do we share personal data with for this purpose?",
    t4430: "As the cookie or tracking pixel is set using the advertising networks’ code, this data is shared directly with them, and My Room Abroad does not have direct access to it.",
    subtitle45: "How does My Room Abroad use personal data to create 'look-a-like' audiences on advertising networks?",
    t450: "My Room Abroad’s advertising networks (e.g. Google and Facebook) allow it to upload customer email addresses, which the advertising network will match with its own database.",
    t451: "The advertising network will look at the demographics of those people based on the data it holds, and allow My Room Abroad to show adverts to people that share similar characteristics.",
    t452: "My Room Abroad will always ask the advertising platform to exclude the people used to create the 'look-a-like' audience from being displayed an advert targeted in this way.",
    subtitle451: "What personal data is used and where is it obtained from?",
    t4510: "We will use the email address you provided to us when signing up on My Room Abroad.",
    subtitle452: "What is our legal basis?",
    t4520: "Or legal basis is our legitimate interest in ensuring our marketing is delivered to those people most likely to follow through with a booking, therefore reducing our cost conversion when marketing our service.",
    t4521: "You can object to this processing by changing your privacy settings on the relevant advertising network.",
    subtitle453: "Who do we share personal data with for this purpose?",
    t4530: "This data is shared with our advertising networks (Google and Facebook) for this purpose.",
    title5: "How does My Room Abroad use my personal data as a supplier?",
    subtitle51: "How does My Room Abroad use personal data to procure and manage services provided by suppliers?",
    subtitle511: "What personal data is used and where is it obtained from?",
    t5110: "We may use all personal data we receive from you, for instance name, address, email address, telephone number and, except where an employee of corporate suppliers: bank account details, details of payments made and received and contracts.",
    t5111: "We will also generate personal data about you, for instance internal correspondence about your performance.",
    subtitle512: "What is our legal basis?",
    t5120: "Our legal basis is our legitimate interest in managing our relationship with you as a supplier.",
    subtitle513: "Who do we share personal data with for this purpose?",
    t5130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    title6: "How does My Room Abroad use my personal data as a job applicant?",
    t60: "If you apply for a job at My Room Abroad, we may use your personal information:",
    l601: "To correspond with you in relation to your application.",
    l602: "To assess your suitability for the role you have applied for.",
    l603: "To confirm your eligibility to work.",
    t61: "You can find out more about how we do so by clicking on the relevant heading below.",
    subtitle61: "How does My Room Abroad use personal data correspond with you in relation to your application?",
    subtitle611: "What personal data is used and where is it obtained from?",
    t6110: "We will keep letters, CV and emails sent to and received from you.",
    subtitle612: "What is our legal basis?",
    t6120: "Our legal basis is that this processing is a necessary preparatory step to entering into a contract of employment with you.",
    subtitle613: "Who do we share personal data with for this purpose?",
    t6130: "This information has no intended recipient. We will retain this information.",
    t6131: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle62: "How does My Room Abroad use personal data to assess your suitability for the role you have applied for?",
    t620: "My Room Abroad keeps a record of your application, including your application itself, interview notes, references and other information collected during the application process in order to consider your application.",
    subtitle621: "What personal data is used and where is it obtained from?",
    t6210: "My Room Abroad keeps a record of your application, including your application itself (obtained from you or through an agent, interview notes (created by our staff), references (obtained from the referees listed in your application) and other information collected during the application process.",
    subtitle622: "What is our legal basis?",
    t6220: "The processing of this personal data is a necessary preparatory step to possibly entering into a contract of employment with you. Without this processing, we would not be able to proceed with your application.",
    t6221: "Where your application file contains special categories of personal data, our legal basis is explicit consent.",
    subtitle623: "Who do we share personal data with for this purpose?",
    t6230: "We may share this data to the extent necessary to request references from the referees you listed in your application.",
    t6231: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle63: "How does My Room Abroad use personal data to confirm your eligibility to work?",
    subtitle631: "What personal data is used and where is it obtained from?",
    t6310: "A photocopy of your ID document, including a visa if relevant, taken from an original you have provided to us.",
    subtitle632: "What is our legal basis?",
    t6320: "Our legal basis is that the processing is necessary to comply with legal obligations imposed on us.",
    t6321: "To the extent that the data includes special categories of data, our legal basis is that the processing is necessary to comply with a legal obligation in the context of employment law.",
    subtitle633: "Who do we share personal data with for this purpose?",
    t6330: "We do not generally share this data for this purpose unless requested by a relevant authority and with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    title7: "How does My room Abroad otherwise use personal data in running its business?",
    t70: "In summary, regardless of whether you are a landlord or tenant, we use personal data:",
    l701: "To allow tenants and landlords to login to the My Room Abroad website.",
    l702: "To inform the tenant or landlord (as the case may be) of the other party's identity, so that each can confirm the other's identity on check-in.",
    l703: "To manage bookings, including keeping the tenant/landlord up to date about their bookings.",
    l704: "To obtain feedback from tenants and landlords about the My Room Abroad service.",
    l705: "To ask tenants and landlords to complete surveys for market research purposes.",
    l706: "To maintain financial records.",
    l707: "To train our staff.",
    l708: "To deal with queries and complaints.",
    l709: "To bring or defend legal claims.",
    t71: "You can find out more about how we do so by clicking on the relevant heading below.",
    subtitle71: "How does My Room Abroad use personal data to allow you to log in to the My Room Abroad website?",
    subtitle711: "What personal data is used and where is it obtained from?",
    t7110: "We will use the email address and password you provided when you signed up for My Room Abroad.",
    subtitle712: "What is our legal basis?",
    t7120: "Our legal basis is our legitimate interest in authenticating our users.",
    subtitle713: "Who do we share personal data with for this purpose?",
    t7130: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle72: "How does My Room Abroad use personal data to allow you to verify the identity of either the landlord or tenant?",
    subtitle721: "What personal data is used and where is it obtained from?",
    t7210: "We will use the name you provided to us when you signed up for My Room Abroad.",
    subtitle722: "What is our legal basis?",
    t7220: "Our legal basis is our, the tenant’s and the landlord’s legitimate interest in ensuring the tenant and landlord are dealing with the correct person on check in.",
    subtitle723: "Who do we share personal data with for this purpose?",
    t7230: "We will share the tenant’s name with the landlord, and the landlord’s name with the tenant.",
    t7231: "We may also share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle73: "How does My Room Abroad use personal data to follow-up on abandoned bookings and listings?",
    subtitle731: "What personal data is used and where is it obtained from?",
    t7310: "Where you only partially complete a booking or listing.",
    subtitle732: "What is our legal basis?",
    t7320: "Our legal basis is our legitimate interest in reducing the number of abandoned bookings or listings.",
    subtitle733: "Who do we share personal data with for this purpose?",
    t7330: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle74: "How does My Room Abroad use personal data to obtain feedback from tenants and landlords about its services?",
    t740: "We collect your feedback via email or during face to face meeting.",
    subtitle741: "What personal data is used and where is it obtained from?",
    t7410: "We will use the name and email address you provided when signing up for My Room Abroad.",
    subtitle742: "What is our legal basis?",
    t7420: "Our legal basis is our legitimate interest in receiving feedback and improving our service.",
    subtitle743: "Who do we share personal data with for this purpose?",
    t7430: "My Room Abroad will share this information on its platform.",
    subtitle75: "How does My Room Abroad use personal data to ask tenants and landlords to complete surveys for market research purposes?",
    subtitle751: "What personal data is used and where is it obtained from?",
    t7510: "We use the name and email address provided to us when you signed up on My Room Abroad.",
    subtitle752: "What is our legal basis?",
    t7520: "Our legal basis is our legitimate interest of obtaining feedback from our users in order to improve the service we offer.",
    subtitle753: "Who do we share personal data with for this purpose?",
    t7530: "My Room Abroad will share this information with its customer survey partner, who will send you an invitation to complete a review on our behalf.",
    t7531: "Our survey partner will then collect responses and share aggregated information with us that does not identify you.",
    subtitle76: "How does My Room Abroad use personal data to maintain its financial records?",
    subtitle761: "What personal data is used and where is it obtained from?",
    t7610: "We generate records of payments made to and from you.",
    subtitle762: "What is our legal basis?",
    t7620: "Our legal basis is that the processing is required to comply with legal obligations imposed on us by law and by government agencies.",
    subtitle763: "Who do we share personal data with for this purpose?",
    t7630: "We may share this information with our financial software provider, Waves, but otherwise we will only share this data with our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle77: "How does My Room Abroad use personal data to deal with queries and complaints?",
    subtitle771: "What personal data is used and where is it obtained from?",
    t7710: "We may use any personal data we hold about you to deal with queries and complaints, but typically this will include booking details, correspondence between us, and information we generate in investigating your query or complaint.",
    subtitle772: "What is our legal basis?",
    t7720: "Our legal basis is our legitimate interest in ensuring queries and complaints are resolved.",
    t7721: "Without this processing, we would not be able to address your query or complaint.",
    subtitle773: "Who do we share personal data with for this purpose?",
    t7730: "We do not share this data for this purpose, other than our suppliers where necessary for them to provide their service to us (see the 'Who we share personal data with' section below).",
    subtitle78: "How does My Room Abroad use personal data to bring or defend legal claims?",
    subtitle781: "What personal data is used and where is it obtained from?",
    t7810: "We may use any of the personal data we hold about you to bring or defend legal claims, including correspondence, payment records and details of services we supplied to you or that you supplied to us.",
    subtitle782: "What is our legal basis?",
    t7820: "Our legal basis is our legitimate interest in bringing and defending potential legal claims.",
    subtitle783: "Who do we share personal data with for this purpose?",
    t7830: "We may share this data with external advisors for the purposes of obtaining advice from them in relation to a claim.",
    title8: "Who does My Room Abroad share personal data with?",
    t80: "Internally, My Room Abroad may grant access to personal data for the purposes of staff training so that they may carry out their role.",
    t81: "Externally, My Room Abroad may share personal data with third parties for specific purposes, as set out in the sections above. It may also from time to time share personal data with the following categories of recipients:",
    t82: "its service providers, for instance:",
    t83: "the companies that host and manage our IT infrastructure",
    t84: "companies that provide us with cloud based IT systems, such as our CRM system or email delivery system",
    t85: "its external advisors, for instance IT consultants, accountants and lawyers.",
    t86: "Where we share personal data with service providers we will always ensure that the service provider is committed contractually to only use personal data in compliance with My Room Abroad’s instructions and data protection law",
    t87: "its regulators, law enforcement, intelligence services and other government authorities, where they require us to do so",
    t88: "potential buyers of or investors in our business where necessary in connection with a due diligence exercise, under appropriate confidentiality agreements (such as NDA).",
    title9: "What are my rights in relation to My Room Abroad processing my personal data and how do I exercise them?",
    t90: "The law gives you certain rights in respect of the information that we hold about you. Below is a short overview of those rights.",
    subtitle91: "Access.",
    t910: "You have the right to a copy of the personal data that we hold about you. We may make a reasonable charge for additional copies of that data beyond the first copy, based on our administrative costs. Where the data is data that you have given to us, you have the right to receive your copy of it in a common electronic format, and to provide copies of it to other people if you wish.",
    subtitle92: "Correction.",
    t920: "You have the right to have the personal data we hold about you corrected if it is factually inaccurate. Note that this does not extend to matters of opinion, such as feedback received on you as a landlord or tenant, or decisions as to suitability.",
    subtitle93: "Deletion.",
    t930: "In some limited circumstances, you have the right to have personal data that we hold about you erased (the right to be forgotten'). This right is not generally available where we still have a valid legal reason to keep the data.",
    subtitle94: "Objection.",
    t940: "You have the right to object to our processing of your personal data where we rely on 'legitimate interests' as our legal basis for processing, but we may be able to continue processing if our interest outweighs your objection.",
    subtitle95: "Opting out of marketing.",
    t950: "You have the right to require us to stop using your personal data to send you marketing information. If you want us to stop sending you marketing information, you can contact us at hello@myroomabroad.com.",
    subtitle96: "Temporary Restriction.",
    t960: "You also have the right in some circumstances to request that temporary restrictions are placed on how we process your personal data, For example if you contest its accuracy or where we are processing it on the basis of our legitimate interest and you contest our assessment that our interest overrides your rights.",
    subtitle97: "Withdrawing Consent",
    t970: "If we are processing your personal data on the basis of your consent, you have the right to withdraw that consent at any time, in which case we will stop that processing unless we have another legal basis on which to continue.",
    t971: "You can exercise your right by:",
    l972: "emailing us on hello@myroomabroad.com",
    l973: "writing to us at Lime tech SPRL, Marius Renardlaan 27A b327, 1070, Brussels, Belgium.",
    t974: "Please note that in order to protect your privacy, we may ask you to prove your identity before we take any steps in response to a request you have made.",
    title10: "How and when will My room Abroad make changes to this guide?",
    t101: "We may update this privacy guide from time to time by updating this web page.",
    t102: "Where we update this guide to include entirely new personal data, or use of existing personal data in an entirely new way, we will notify you that changes have been made using the email address we hold for you, if any.",
    t103: "This guide was last updated on October 18th 2018.",
  },
  terms: {
    headline: "服務條款",
    t000: "請仔細閱讀這些服務條款，因為它們包含有關您的法律權利，補救措施和義務等等的重要信息。訪問或使用My Room Abroad Platform，即表示您同意遵守這些服務條款並受其約束。",
    t001: "最後更新時間：2019年05月28日",
    t002: "這些服務條款（以下稱“條款”）構成您與My Room Abroad之間具有法律約束力的協議（以下稱“協議”），用於管理您訪問和使用My Room Abroad網站，包括其任何子網站以及通過My Room Abroad網站連接的其他網站（統稱“網站”）和所有相關服務（統稱為“我的海外服務”）。這些網站和我的海外服務在下文中統稱為“My Room Abroad Platform”。",
    link001: "我們的退款政策",
    link002: "隱私政策",
    t003: "以及適用於您使用My Room Abroad Platform的其他政策均通過引用納入本協議。",
    t004: "請注意，這些服務條款和使用條款的任何翻譯版本僅為方便起見。如果本英語版本的任何條款與翻譯版本之間存在不一致，則以英語版本的規定為準。",
    title0: "大綱",
    t10: "My Room Abroad為外國房客住宿預訂提供線上交易平台。因此，為用戶（即尋找住宿方）及住宿提供方（即將住宿提供給用戶）雙方提供預訂服務。請注意，My Room Abroad僅為在線交易平台，不向任何用戶直接提供及租賃任何住宿。",
    title1: "關於服務條款",
    subtitle11: "服務條款裡的字彙:",
    t110: "「我們」和「我們的」這兩個詞指的是我的海外房間。 My Room Abroad提供本網站，包括本網站向您（用戶）提供的所有信息、工具和服務，條件是您接受此處所述的所有條款、條件、政策和通知。",
    t111: "",
    b112: "首付房租",
    t112: "指網頁上列明的用戶須首次支付之房租金額（My Room Abroad將為住宿提供方代收）",
    b113: "房源",
    t113: "指住宿提供方通過我公司網站發佈之房間、套房或其他房屋或公寓；",
    b114: "住宿提供方",
    t114: "指在網站上房源之提供方；",
    b115: "訂單接受",
    t115: "指住宿提供方接受預訂請求;",
    b116: "訂單確認",
    t116: "指相關預訂請求在被住宿提供方接受且用戶已付款",
    b117: "訂單確認信",
    t117: "指相關預訂請求在被住宿提供方接受且用戶已付款後，由My Room Abroad公司立即向用戶及住宿提供方發送之電子郵件；",
    b118: "預訂請求",
    t118: "指用戶提交之預訂房源之請求；",
    b119: "預訂金額",
    t119: "指所有相關租房金額總和（包含首付房租及未來款項，不含服務費、及保證金）；",
    b1110: "營業日",
    t1110: "指台灣時間除週六、周日及公眾假期之外的日期；",
    b1111: "租賃合約生效日",
    t1111: "指相關訂單確認中約定的租期開始生效之日；",
    b1112: "押金款項",
    t1112: "指用戶必須向住宿提供方支付的金額，以支付其在住宿期間發生的任何損害或用戶對租賃協議的任何其他違約行為。 押金提供者在租賃協議結束時搬出後，將退還押金，但須扣除任何損害賠償費。 存款是用戶與住宿提供方之間的事項，My Room Abroad不介入任何此類款項，也不對其負責;",
    b1113: "全認證房源",
    t1113: "指房源及住宿提供方成功完成My Room Abroad網頁所有認證步驟之房源。",
    b1114: "未來款項",
    t1114: "指用戶未來須直接向My Room Abroad或住宿提供方支付的所有款項（首付之外），包含所有用戶須向住宿提供方交付的租金及保證金；",
    b1115: "位置",
    t1115: "指公司網站上所列之國家與城市；",
    b1116: "入住日期",
    t1116: "是指用戶在訂單上表明的入住時間。",
    b1117: "My Room Abroad佣金",
    t1117: "指My Room Abroad通過本網站向該住宿提供商提供服務而在預訂時向住宿提供商收取的費用（包括增值稅）。 它按預訂價值的百分比加上此租金百分比收取的任何相關增值稅計算，並從My Room Abroad轉帳到住宿提供者的金額中扣除。 My Room Abroad佣金可以一次性支付。 在這種情況下，將會從首付月租的匯款中扣除。 或者佣金可以按月支付。 在這種情況下，部分佣金將從每月的月租金中扣除; 每個月扣除的佣金等於My Room Abroad佣金總額除以租約期數;",
    b1118: "服務費",
    t1118: "指用戶在接受並確認住宿後，根據My Room Abroad公司之服務所支付予My Room Abroad之金額（已含稅）。按首付金額按房源之首月租金之百分比計算（可變動）；該服務費會根據每一個平台上列出之房源進行收取，若房源是公寓或者獨棟房屋內的單人房，服務費只適用於該指定之單人房而非整個公寓或獨棟房屋；",
    b1119: "租金款項",
    t1119: "是指根據相關租賃協議支付的租金，用戶必須向My Room Abroad匯的款項（除了第一次租金支付;",
    b1120: "服務",
    t1120: "指為方便用戶與住宿提供方通過My Room Abroad公司網站預訂房源之相關服務；",
    b1121: "租賃合約",
    t1121: "指用戶提供方與用戶根據租賃房源而訂立的租賃協議、合約、租約或特許（無論是否正式或是否存在書面形式）；",
    b1122: "租賃期間（Term）",
    t1122: "指租賃合約開始跟租賃合約結束這之間的日期",
    b1123: "用戶",
    t1123: "指在My Room Abroad公司網站尋找房源的用戶；",
    b1124: "網站",
    t1124: "指My Room Abroad公司管理維護的任意網站，包含但不僅限於www.My Room Abroad.com 及其相關子網站，任何相關社交媒體網站賬戶及任何My Room Abroad公司發佈的移動設備應用程序；",
    b1125: "您, 你",
    t1125: "指用戶或住宿提供方（視實際情況而定）。",
    t1126: "請在使用服務或網站前仔細閱讀並理解服務條款之內容。若您不同意此服務條款，請立即停止使用相關服務與網站。",
    title2: "接受服務條款的規範",
    t21: "訪問我們的網站和/或從我們這裡購買商品，即視為使用我們的“服務”並同意遵守以下條款和條件（“服務條款”，“條款”），包括那些附加條款和條件，或通過超鏈結所提供的政策。這些服務條款適用於本網站的所有用戶，不限於瀏覽器，供應商，客戶，商家和/或內容貢獻者。",
    t22: "在訪問或使用我們的網站之前，請仔細閱讀這些服務條款。訪問或使用本網站的任何部分，即表示您同意遵守這些服務條款。如果您不同意本協議的所有條款和條件，則您不得訪問本網站或使用任何服務。",
    t23: "任何添加到當前平台的新功能或工具也適用於服務條款。您可以隨時在此頁面上查看最新版本的服務條款。我們有權利發布更新或更改我們網站，也可以更改或替換這些服務條款。您有責任定期查看此頁面以了解服務條款的改變。您在我們發布任何改變後繼續使用或訪問本網站即表示接受這些改變。",
    title3: "何為公司網站？",
    t31: "My Room Abroad公司網站是讓用戶瀏覽、搜索並預訂相關位置的房源，並讓住宿提供方向用戶推薦房源的電子平台。允許用戶長租或短租，最短租期28天起。",
    t32: "My Room Abroad公司向用戶及住宿提供方提供訂單服務，但自身不向用戶提供房源。任何租賃協議都由用戶與住宿提供方直接訂立。My Room Abroad公司不構成該租賃協議的任意一方，也不享有其規定的任何權益且不履行任何義務。",
    title4: "網站所展示信息",
    t41: " 網站上包含信息、圖片、影片、房源價格及房源位置等相關信息。",
    t42: " My Room Abroad公司盡力確保網站上所發佈信息清楚、準確、完整且無誤導性。同時，公司盡力確保網站上發佈之房源滿足衛生、裝修、安全、設施設備、客戶服務等相關標準。由於公司不直接提供房源，因而無法保證房源絕對符合所有條件。",
    t43: "My Room Abroad公司依賴住宿提供方提供合適準確房源信息，並及時更新相關信息。一般情況下，公司不對住宿提供方提交的信息的準確性與及時性進行檢查認證，如果房源經過檢查認證，我公司會清晰標示。對於公司網站上的房源信息，住宿提供方全權負責上傳或者提供給我公司代為發佈。",
    t44: "住宿提供方須在接受My Room Abroad公司發送的任何預訂請求前檢查網站上相關房源信息，包括（但不僅限於）價格、房態及設備設施等。",
    t45: "作為用戶，您瞭解並同意我公司對上傳至網站或由住宿提供方提供之與房源相關信息不負任何責任，因房源信息產生的或相關的損失或損害由住宿提供方負責。若該房源得到My Room Abroad實際到訪認證，My Room Abroad僅對已認證的信息內容負責。My Room Abroad公司對認證後房源發生的任何變化不承擔任何責任。",
    t46: "在接到投訴（或推斷為投訴）相關過時、不準確或違反第三方權利的信息時，公司保留不經通知移除網站上所列房源之全部或部分信息的權利。將合理適當地對該類投訴採取合理處理努力。",
    t47: "建議用戶仔細閱讀所有文件，並瞭解房源的價格（即租金及其他花費）、租賃協議的條款與租賃期限及其他任何房源相關使用限制。網站上住宿的照片和視頻僅作為參考，而不是確切的房間內容。",
    title5: "預訂房源",
    t51: "用戶可通過網站提交對房源的預訂申請。用戶必須選擇希望預訂的日期及房源，然後點擊「預訂」鍵。網站會自動依據住宿提供方所設定的最短租期來阻擋不符合需求的訂房要求。",
    t52: "用戶必須保證自己年齡大於該國可進行此類簽約的最小年齡，若用戶違反上述陳述，My Room Abroad將有權扣留用戶所指費的服務費作為賠償。用戶將全權承擔檢查並保證其可合法與住宿服務提供商簽署租賃合約的責任。",
    t53: "為完成相關預訂申請，用戶需填寫相關個人信息，並提供首付房租、服務費、誠意金（視具體情況而定）等相關支付信息。用戶將收到郵件，確認其預訂申請已提交。",
    t54: "5.4 付款完成後，My Room Abroad 將會立即通知雙方（用戶及住宿提供者），並說明與列出後續步驟。",
    title6: "訂單確認",
    t61: "住宿提供方可接受或拒絕預訂申請。如果預訂請求被拒絕，將通過網站和電子郵件通知用戶，預訂將自動取消，用戶可以為另一個房間提出新的預訂請求。 拒絕請求時，住宿提供商可以選擇向用戶推薦網站上列出的其他住宿。若住宿提供方接受預訂申請，My Room Abroad將寄出首付房租、服務費及誠意金（視具體情況而定）的付款請求。My Room Abroad確認收到付款後，將向雙方告知後續步驟。",
    t62: "訂單被接受後，用戶應負責：",
    l621: "遵守住宿提供方的任何其他要求，包括（但不僅限於）提供品行推薦和/或擔保人聯絡信息。住宿提供方也可能在接受預訂申請前要求用戶提供此類信息，如出現此類情況，用戶將收到此類信息；及",
    l622: "在租期內入住相關房源，遵守租賃協議內容，並根據服務條款或租賃協議（視具體情況而定）及時向My Room Abroad公司及住宿提供方交納所有款項。",
    t63: "住宿提供方同意接受預訂申請即意味著接受在預定日期內，根據預訂申請中所載價格提供相關房源，且房源內各種細節、條件及設施設備與其在My Room Abroad網站上發佈信息無重大出入，並受上述內容約束。若無法遵守上述要求，則可能導致用戶投訴及要求取消協議（用戶有權要求全額退款）。",
    title7: "消費者合約（信息、取消與額外收費）規定",
    t71: "根據第7.3及7.4條內容規定，若您為消費者，您有權在根據第2條內容規定在初次接受服務條款後14天內任意時間取消與我方所訂之服務提供協議。",
    t72: "若欲取消與我方之間之服務協議，用戶僅需通知My Room Abroad公司您希望取消預訂的決定。用戶可向hello@myroomabroad.com寄發郵件。若通過此方式取消，My Room Abroad公司將通過郵件告知已收到用戶之取消預訂的要求。",
    t73: "若您在上述14天取消期內提交或接受預訂申請，即代表您要求My Room Abroad公司在此14天取消期內提供服務，根據第6條規定預訂申請接受後，您將失去取消與My Room Abroad有關我方提供服務的法定權利，因而需根據服務條款交納服務費（視情況而定）。你取消訂單的權利將根據第12條依然生效。",
    t74: "任何情況下，取消與My Room Abroad公司的服務相關協議不影響任何已經生效或者即將生效的租賃協議。",
    t75: "任何用戶根據租賃合約所支付予My Room Abroad的款項（包括首付租金），My Room Abroad將根據信任條款，代住宿提供方收取， 並只有在符合第12條款項的情況下予以退還予用戶。",
    title8: "首月租金及押金付款",
    title81:"首月租金付款方式",
    t811:"第一個月的租金，用戶會直接向平台my room abroad （出國租有限公司）支付費用，，該筆費用是由My Room Abroad公司代替住宿提供方暫定保管，確保租賃者及住宿提供方遵循合約及條款，等到確定入住後會由公司匯給住宿提供方。",
    t812:"My Room Abroad每個月會進行兩次匯款（轉帳），分別是在每月的15號及最後一天（30 或31號），在1號至14號所收到的所有費用， My Room Abroad會在15號進行轉帳，相對的，15號至最後一天的費用則會在每個月的最後一天進行匯款。此筆費用會扣除My Room Abroad的佣金在匯款給房屋管理者，付款將會以新台幣並透過台灣的銀行轉入房屋管理者提供方的銀行賬戶。延遲時間平均不會超過一天工作天，但取決於不同的銀行系統，可能會有工作時間的差異。一但轉帳成功，My Room Abroad會向住宿提供方提供付款證明，若已提供銀行付款證明，公司則不對延遲收款負責。",
    title82:"押金付款方式",
    t821:"押金由住宿提供方在簽署租賃協議時直接向用戶收取。My Room Abroad 並不會涉及也不對其負責。 在租賃合約結束時，住宿提供方再將押金予以退還給用戶，並可扣除任何損害賠償費。",
    t822:"若在某些特定情況，用戶希望使用信用卡並藉由My Room Abroad 平台支付押金，用戶應於入住日（前）直接支付押金給My Room Abroad ，由My Room Abroad代收。My Room Abroad則會在收到費用的另一個工作天轉帳給住宿提供方。",
    title9: "每月租金",
    t91: "除非另有洽談其他合作模式，否則用戶應每個月直接支付租金給My Room Abroad，該房租將由My Room Abroad代表住宿提供方收取。 關於如何以及何時支付租金的條款，My Room Abroad 將通過預訂確認書中的電子郵件向用戶說明。 用戶若同意遵守My Room Abroad的條款，該筆租賃才會成立。",
    t92: "若延遲付款，用戶將被要求支付每天100新台幣的滯納金。 如果用戶是通過銀行現金存款支付，則用戶有責任了解銀行的營業時間。",
    t93:"My Room Abroad 每個月會進行兩次匯款（轉帳），分別是在每月的15號及最後一天（30 或31號），在1號至14號所收到的所有費用， My Room Abroad會在15號進行轉帳，相對的，15號至最後一天的費用則會在每個月的最後一天進行匯款。此筆費用會扣除My Room Abroad的佣金在匯款給住宿提供方，付款將會以新台幣並透過台灣的銀行轉入住宿提供方提供方的銀行賬戶。延遲時間平均不會超過一天工作天，但取決於不同的銀行系統，可能會有工作時間的差異。一但轉帳成功，My Room Abroad會向住宿提供方提供付款證明，若已提供銀行付款證明，公司則不對延遲收款負責。",
    t94:"My Room Abroad 所扮演的是調解者（媒介）的角色，對於用戶的任何遲交付款概不負責。 如果用戶未能向My Room Abroad提供租金付款證明：",
    l941:"在當月的10日或25日，取決於用戶租金繳款的截止日期：My Room Abroad將會向用戶發送租金繳款提醒。",
    l942:"在當月的15日或每月的最後一天，取決於用戶租金繳款的截止日期：My Room Abroad 將會通知住宿提供方，住宿提供方有根據租賃合約對於租金的遲繳採取必要行動的權利與責任。",
    title95:"自動租金付款",
    t951:"為了讓用戶使用更方便，用戶可在預訂住宿時選擇自動租金付款選項。代表用戶允許My Room Abroad在其租賃期間內每個月自動從用戶的信用卡中收取相關的租金，包含水電費以及租金和相關手續交易費用。在預繳租金前5天，用戶有責任確保其賬戶金額足以支付此租金。",
    t952:"自動付款成功後，My Room Abroad 將通過電子郵件通知用戶。",
    t953:"如果因任何原因導致自動付款失敗，My Room Abroad 將通過電子郵件通知用戶。用戶有責任在繳交租金期限到期時前成功付款，以避免延遲支付費用。",
    title10: "租賃協議",
    t101: "在住宿提供方與用戶就入住房源的相關條款達成共識接受全部條款後，租賃協議隨即生效。租賃協議的簽署雙方為用戶與住宿提供方，為避免疑義，My Room Abroad不構成該協議任意一方。",
    t102: "如果住宿提供方無法履行租賃協議，或者履行中出現嚴重缺陷的情況，又或者提供的房源存在缺陷瑕疵，My Room Abroad不承擔任何責任。",
    t103: "對用戶無法履行租賃合約或履行中出現嚴重缺陷情況，包括但不僅限於更改入住和/或退房日期等，My Room Abroad不承擔任何責任。住宿提供方有相關機制強制要求用戶履行既定協議（含入住和/或退房日期），因此，My Room Abroad公司不負責代替用戶向住宿提供方支付任何額外款項或退款。",
    title11: "入住日期",
    t111: "用戶應於確認訂單內所列明的起始日期入住。除非另有約定，不然所有款項將由My Room Abroad替住宿提供方保管。房源信息已列明用戶的未來應付款項。如需進一步瞭解細則，用戶需直接聯繫住宿提供方。",
    title12: "取消",
    t121: "用戶有權根據第7條內容規定取消，也可根據住宿提供方選擇的取消政策及下文所載內容取消訂單。",
    t122: "住宿提供方可於以下預訂取消政策中（a-e）選擇其中一項。一旦他們完成選擇，並通知My Room Abroad後，此預訂取消政策將適用於所有該住宿提供者未來的所有訂單。若未做選擇，系統將默認為「適度取消政策」。住宿提供者將授予My Room Abroad不可逆轉的權利，用於應用所選（默認）的訂單取消政策於處理（退還）首付租金，以及My Room Abroad的傭金。",
    t123: "以下情況下，用戶可根據以下條款取消訂單：",
    l1231: "訂單生成且根據第10.1條之內容的租賃協議生效後，和/或用戶搬入房源後，不可取消，該租賃協議含終止條款情況除外。",
    l1232: "取消政策：寬鬆：在用戶與住宿提供方之間的租賃協議生效前，但在住宿提供方接受預訂申請且用戶已支付首付房租後，若用戶通知My Room Abroad公司希望取消訂單：",
    ll12321: "若在租賃合約生效日期前15天內，首付租金不退款（訂單將被取消）；",
    ll12322: "若在租賃合約生效日期前15至30天內，My Room Abroad將退還50%的首付租金（訂單將被取消）；及",
    ll12323: "若在租賃合約生效日期前30天外，My Room Abroad將退還100%的首付租金（訂單將被取消）。",
    l1232a: "關於12.3(b)(i)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後首付租金的剩餘款項；關於12.3(b)(ii)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後50%首付租金的剩餘款項。",

    l1233: "取消政策：適度：在用戶與住宿提供方之間的租賃協議生效前，但在住宿提供方接受預訂申請且用戶已支付首付房租後，若用戶通知My Room Abroad公司希望取消訂單：",
    ll12331: "若在租賃合約生效日期前30天內，首付租金不退款（訂單將被取消）；",
    ll12332: "若在租賃合約生效日期前30至60天內，My Room Abroad將退還50%的首付租金（訂單將被取消）；及",
    ll12333: "若在租賃合約生效日期前60天外，My Room Abroad將退還100%的首付租金（訂單將被取消）。",
    l1233a: "關於12.3(c)(i)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後首付租金的剩餘款項；關於12.3(c)(ii)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後50%首付租金的剩餘款項。",

    l1234: "取消政策：嚴格：在用戶與住宿提供方之間的租賃協議生效前，但在住宿提供方接受預訂申請且用戶已支付首付房租後，若用戶通知My Room Abroad公司希望取消訂單：",
    ll12341: "若在租賃合約生效日期前30天內，首付租金不退款（訂單將被取消）；",
    ll12342: "若在租賃合約生效日期前30至90天內，My Room Abroad將退還50%的首付租金（訂單將被取消）；及",
    ll12343: "若在租賃合約生效日期前90天外，My Room Abroad將退還100%的首付租金（訂單將被取消）。",
    l1234a: "關於12.3(d)(i)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後首付租金的剩餘款項；關於12.3(d)(ii)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後50%首付租金的剩餘款項。",
    l1235: "取消政策：不退款：前租賃協議已經被用戶和住宿提供者，但之後的住宿提供者已接受預訂請求，如果用戶的用戶後，取得了第一次支付租金進入通知My Room Abroad它希望取消沒有退款將是由於就該第一支付租金的（和預訂將被取消）。",
    l1235a: "關於12.3.(e)款，在取消後，住宿提供方將收到扣除My Room Abroad公司傭金後首付租金的剩餘款項。",
    t124: "如果住宿提供方已收到首付房租，住宿提供方應補償我們對住宿提供方就第12.3（b），（c）或（d）條所支付的任何付款。",
    t125: "為避免疑義，若用戶根據本12.3條之規定取消訂單，My Room Abroad公司不退還服務費。",
    title13: "再預訂保證",
    t131: "My Room Abroad相信使用我公司服務的用戶將在租期內使用房源，因為My Room Abroad公司採取必要用戶背景信息確認，包括：",
    l1311: "獲取用戶的全名、電話號碼、居住地信息。然而，My Room Abroad不會對用戶的移民背景進行任何調查，而因為用戶的移民情況都是需在其住宿地被列明，因此住宿提供方將有可能被告知對用戶進行相應的調查；",
    l1312: "獲取用戶的其他相關信息，例如用戶是否為本科生、研究生或就讀語言學校，以及用戶將就讀的大學及其他學校；及",
    t132: "由於我們跟用戶所進行的確認，以及相信能夠快速地預訂空置房源，我們願意為部分住宿提供方提供下列再預訂擔保。為確保住宿提供方可享受此項擔保，My Room Abroad將須單獨與住宿提供方通過電子郵件溝通，並提供次此項再預訂擔保。若My Room Abroad沒有向部分住宿提供者提供此項再預訂擔保，則此第13條將不適用。再預訂擔保從用戶的「訂單開始日期」（由My Room Abroad公司指明）起生效，在相關租期結束前一個月終止，再預訂保證如下：",
    l1321: "入住後，若用戶（出於任何原因）永久停止使用在My Room Abroad預訂房源，則住宿提供方授權My Room Abroad公司（受服務條款約束），且My Room Abroad公司必須立即將房源轉租（時間等於或長於租期剩餘時長）；",
    l1322: "若住宿提供方遵守此第13條其他內容，在My Room Abroad公司轉租該房源前或租期結束前，My Room Abroad公司應向住宿提供方（因住宿提供方單方面無法轉租房源獲取補償）支付相當於每日租金乘以用戶租賃合約剩餘天數的金額（在住宿提供方首付租金、先付資金和/或訂金相關款項已提清），該租金本應根據租賃協議由住宿提供方收取；及",
    l1323: "My Room Abroad應對將房源轉租給相關人員有單獨酌情決定權，並可以根據當前房租水平制定一個經濟合理的折扣，以利於盡快在剩餘租期內完成轉租的目的。若My Room Abroad以折扣月租價轉租房源，若該折扣超過之前用戶支付租金的百分之五，則My Room Abroad公司應在租期剩餘時間內僅繼續支付超出部分（根據第13.3款）。",
    t133: "根據第13.2(b)或 (c)款內容，首次支付應在My Room Abroad收到住宿提供方的有效且帶合理證據索賠申請日期後60天後的下一個自然月首個工作日支付。此後，My Room Abroad應在剩餘租期的每個月第一個工作日支付該類款項。",
    t134: "My Room Abroad不負責補償第13.2(b)或(c)款中任何租金，無論該租金是否高於房源所在地同等大小及質量物業市場價，兩種價格都由My Room Abroad合理確定。",
    t135: "若住宿提供方：",
    l1351: "不經過My Room Abroad轉租房源；",
    l1352: "或阻礙或不協助My Room Abroad轉租事宜（包括將房源保持以下第13.6(h) (v) 至 (vii)所載狀態；",
    l1353: "根據租賃協議收取用戶擔保人所付房租；或",
    l1354: "房源轉租後未能通知My Room Abroad，則不向住宿提供方支付13.2(b)或(c)載明之款項。",
    t136: "以下情況下，我公司不支付13.2(b)或(c)載明之款項：",
    l1361: "住宿提供方已收取首付房租；",
    l1362: "住宿提供方與用戶達成協議，用戶可少付房租或可停止或暫停支付房源信息中所列之租金；",
    l1363: "用戶在租期內入住住宿提供方（或與住宿提供方有直接或間接聯繫的人或實體）的其他房源；",
    l1364: "除非住宿提供方已在用戶不再使用房源和/或停止支付房租事情發生14天內通過郵件形式通知My Room Abroad公司，郵箱：hello@myroomabroad.com",
    l1365: "除非用戶提供方已以書面形式（包括但不僅限於郵件形式）通知用戶支付相關房租，且用戶在收到該書面通知後七天後仍未支付房租；",
    l1366: "以及：",
    ll13661: "住宿提供方的保險費、對外支出或公用設施費；",
    ll13662: " 住宿提供方應自行向上一級住宿提供方或管理公司交付的租金或服務款項；",
    ll13663: "住宿提供方向住宿提供方投資人支付的抵押貸款、利息、費用或罰金；或",
    ll13664: "任何房源網站信息之外且租賃協議中載明的其他租金；",
    l1367: "租賃協議中載明但用戶未支付的服務費、對外支出、費用、公用設施費及罰款等，及在租賃合約終止或結束時住宿提供方要求的對房源維修或修繕費用；",
    l1368: "若用戶出於以下原因停止使用房源或取消或終止訂單或租賃協議：",
    ll13681: "住宿提供方違反本服務條款或租賃協議；",
    ll13682: "住宿提供方在租賃協議開始前向用戶提供不正確或誤導性房源信息，或故意提供錯誤信息（無論通過www.myroomabroad.com或其他方式）；",
    ll13683: "用戶根據本服務條款取消政策取消訂單；",
    ll13684: "住宿提供方失誤或錯誤地接受原始訂單（該失誤或錯誤未書面與 hello@myroomabroad.com溝通）；",
    ll13685: "用戶無法使用或入住房源；",
    ll13686: "房源處於不安全或不可居住狀況，或被損壞影響用戶使用該房源；",
    ll13687: "住宿提供方未能修繕房源在租期內出現的任何瑕疵、管理維修問題，影響用戶使用房源情況；",
    ll13688: "住宿提供方鼓勵、勸說、強迫或促使用戶進行修繕工作；",
    ll13689: "與住宿提供方達成或實施任何共同行動、",
    ll136810: "若用戶由於住宿條件的問題而停止使用房源，或取消或終止訂單或租賃合約，且在使用房源起24小時內向My Room Abroad進行投訴。",
    ll136811: "租賃合約違反當地法條或法律。",
    t137: "若用戶或住宿提供方在任何時間故意向My Room Abroad提供虛假信息，不支付本條款下任何款項。",
    t138: "如果用戶未在入住日期或之後不久進入住宿，則不得根據本條款支付任何款項",
    t139: "My Room Abroad有權在任何時間不經通知，檢查或允許第三方檢查第12條適用之房源。",
    t1310: "用戶瞭解再預訂服務（及本13條下My Room Abroad的附帶責任）為緩解住宿提供方，並在相關租期內使用房源：",
    l13101: "因此，本13條並不免除用戶履行本條款及用戶與住宿提供方訂立的任何租賃合約的責任，且若用戶在租期內終止（或打算終止）永久使用房源，則用戶須My Room Abroad根據本13條向住宿提供方支付的任何款項；",
    l13102: "若My Room Abroad支付任何款項，或根據本13條須支付任何款項，用戶則須在My Room Abroad書面要求後立即支付相同貨幣下的相同金額（或者不同貨幣下的相應全部金額）；及",
    l13103: "My Room Abroad的有關該款項金額證明文件應視為My Room Abroad已支付款項或要求款項確證（欺詐情況除外），且用戶鬚根據本13條做出相應支付。用戶向My Room Abroad支付的款項金額不得出現任何扣減、抵消或留扣。",
    title14: "您的行為",
    t141: "在做出任何所選房源的預訂申請、授權服務費、首付房租（及適當情況下的誠意金）時，用戶同意不尋求繞過My Room Abroad，嘗試（或已成事實）直接與住宿提供方達成預訂，並通知我方您決定不預訂。",
    t142: "使用網站和/或服務和/或就所選房源接受用戶介紹，住宿提供方同意不尋求繞過My Room Abroad，嘗試（或已成事實）直接與用戶達成預訂，並通知我方您決定不接受預訂。",
    t143: "下載和/或使用網站，代表您同意不上傳、發表、郵件或發送或傳輸任何帶病毒、木馬、蠕蟲或其他電腦編碼、文件或程序之文件，該類文件可能幹擾、損壞或限制與我方服務或網站相關的任何電腦軟件、硬件、電訊設備等正常工作。",
    t144: "您同意並採取措施保證您的用戶名及密碼保密，不將密碼透露給其他人，不允許他人使用您的用戶名和密碼登錄我方網站。",
    t145: "您同意不干擾與公司網站相連之服務器或網絡，也不違反與本網站相關之網絡的程序、政策或規定，包括本服務條款。您也同意不進行以下行為：",
    l1451: "嘗試進行反向工程或出售、導出、許可、修改、複製、發佈或傳輸我方網站至任何第三方，或損害本網站的正常工作，或嘗試獲取本網站源自或基礎的軟件源代碼（包括工具、方法、程序及基礎架構）；",
    l1452: "嘗試獲取本網站中您無權限的安全部分；",
    l1453: "冒充他人使用公司服務或網站；",
    l1454: "在使用公司網站時有任何粗魯、冒犯性、騷擾性或令人反感的行為；",
    l1455: "轉售或導出與本網站相關之軟件；",
    l1456: "使用本網站生成未經同意的廣告或垃圾郵件；或",
    l1457: "使用任何自動或手動程序從本網站搜索或獲取信息，或以任何形式干擾本網站的正常運行。",
    title15: "My Room Abroad公司權利",
    t151: "My Room Abroad公司向您提供公司網站訪問時保留以下權利，且您在訪問、瀏覽或使用本網站時准予My Room Abroad並同意My Room Abroad有以下權利：",
    l1511: "若My Room Abroad全權判定您違反或破壞本服務條款任何內容，則My Room Abroad有權根據相關法律在任何時間出於任何原因（通知或不經通知）拒絕或收回您對本網站的訪問權限；",
    l1512: "不經通知或出於任何原因暫停、修改或關閉您的賬戶信息；",
    l1513: "不時修改或更新網站、費用、收款方式或本服務條款；及",
    l1514: "若My Room Abroad全權判定您的行為（使用公司網站、服務或其他情況下）不合法，將您舉報至警方或其他司法機構。",
    title16: "知識產權",
    t161: "My Room Abroad和/或其許可方為本網站的唯一擁有人，包括網站上可用的軟件、域名及內容。本網站受國際版權及其他知識產權法律保護。",
    t162: "若未事先取得My Room Abroad書面同意，您在任何情況下不得出售、導出、許可、修改、複製、發佈或傳輸本網站（或其任何部分）或本網站上提供的任何資料。",
    t163: "我公司向您授予免版權費、非排他的、不可轉讓、不可撤回的全球許可，以使用我方服務及網站內知識產權，但不超出允許您參與服務範圍。任何未經授權使用公司網站將造成我方授權的受限許可自動終止。在您未經授權使用本網站後，My Room Abroad保留在任何時間不經通知終止您受限許可的權利。",
    t164: "My Room Abroad及其網站相關圖片、標誌、圖標及服務為My Room Abroad已註冊及未註冊商標或商業外觀。若未事先取得My Room Abroad書面同意，則不得使用。",
    t165: "您同意My Room Abroad擁有在公司網站上創建或發佈的任何內容（無論是否為文字、圖片、照片或視頻）的版權，且您不得出售、導出、許可、修改、複製、發佈或傳輸該內容到任何第三方。",
    t166: "您向My Room Abroad公司授予免版權費、非排他的、不可轉讓、不可撤回的全球許可，以使用您的知識產權，但不超出允許My Room Abroad向您提供服務範圍。為準確起見，該使用可能包含將您的姓名及標誌展示在房源信息旁，或提供您網站的鏈接等事項。",
    t167: "此外，在本協議期間，您向My Room Abroad公司授予免版權費、非排他的、不可轉讓、不可撤回的全球許可，在公司網站、公開聲明及公眾材料中提及使用您姓名、標誌及品牌相關（您的「品牌」）知識產權推廣有您實質參與的公司服務，前提為公司做出合理努力確保不做出任何損害您品牌信譽的行為。",
    t168: "您應就任何費用，損害或費用的需求可能出現的結果是償還我們，或與任何涉嫌或實際侵犯任何第三方的知識產權或其他權利，因My Room Abroad’使用的連接在網站上提交您的相關信息。",
    title17: "安全保障",
    t171: "公司已採取商業合理技術及機構措施保證您的個人信息及用戶內容未經授權不得使用，但公司無法保證未經授權的第三方永遠無法破解此類措施。您瞭解您所提供的個人信息及用戶內容須自擔風險。",
    title18: "電子通信",
    t181: "下載或使用公司網站或使用網站提供資料即視為您同意接收My Room Abroad發出電子通信與通知。您同意任何以電子形式寄送給您的通知、協議、披露及其他溝通滿足所有法律溝通要求，包含要求溝通為書面形式。您可要求我公司不因市場推廣目的而處理您的個人信息。您可通過勾選我方收集您個人信息表格中的相關方框來行使該阻止權利。也可在任何時間寫郵件聯繫我方行使該權利，若您為用戶，請郵件至 hello@myroomabroad.com",
    title19: "隱私權",
    t191: "您在我們的網站上註冊時所提供的信息。我們會收集與您相關的信息（例如您的使用歷史紀錄和您對某些內容的個人偏好），以及與我們網站和服務的常規相關信息。任何您所提交的信息或由我們在您訪問網站時所獲取的信息，都將嚴格遵循我們的隱私政策（privacy link)及Cookie政策(cookie policy link)，其條款已納入本服務條款中。",
    title20: "提交信息之使用",
    t201: "您同意My Room Abroad免費使用您發送給My Room Abroad且My Room Abroad未出於任何原因給您補償、承諾或付款的任何交流中所含評論、信息（個人信息除外）或想法，包含但不僅限於：研發、製造或推廣產品與服務，創建、修改或改善公司服務或網站或其他產品或服務。為避免疑義，My Room Abroad視所有該類信息不含任何保密且專利性質，且您同意我們可不加限制使用該類信息。",
    t202: "您同意您在公司網站上發佈的任何信息為公開信息，網站其他用戶可查看和評論該信息。您可對信息進行分類，部分為公開信息，部分為隱私信息。公開信息將在您的個人頁面展示。",
    t203: "您同意My Room Abroad在未承認您是該信息來源和/或該內容創作者情況下免費重新發佈或使用您在公司網站上發佈之信息。一般情況下，若要將內容發佈至任何第三方站點時，我方將盡力提供返回您頁面之鏈接。您特此不可撤銷地放棄您在任何發佈至公司網站內容的精神權利。",
    title21: "免責聲明與責任限制",
    t211: " My Room Abroad公司提供的服務與網站本身不含任何明示、暗示或法定的擔保或條件。若您為消費者，My Room Abroad公司不承擔對出於任何目的對所有權、適銷性或對特定情況的適度性的責任。My Room Abroad公司不承擔對任何非侵權行為的任何暗示擔保。My Room Abroad公司對任何網站或服務中的任何錯誤或疏漏、網站的任何失誤、延遲或干擾、使用服務或網站中的任何損失或損害、公司服務或網站用戶的其他行為不承擔任何責任或義務、或住宿提供房根據2114英國《移民法》對不同移民背景的用戶所持有的責任。公司保留全權決定提供服務與網站之權利。",
    t212: "本21.2款僅在您為商業實體而非消費者時適用。根據第21.4款規定，My Room Abroad公司、其股東、董事、高級職員、普通員工或代理人均不應以下原因對您承擔任何責任（單獨或聯合）：",
    l2121: "利潤、業務或收入損失；",
    l2122: "數據、信息或軟件的損失或損壞；",
    l2123: "商業機會損失；",
    l2124: "預期存款損失；",
    l2125: "聲譽損失；或",
    l2126: "因公司服務和/或網站或本服務條款產生或相關之間接或從屬損失，以及任何提及與否的可能損失。",
    t213: "本21.3款僅在僅為消費者時適用。若My Room Abroad未能遵守本服務條款，My Room Abroad應該為違反本服務協議或疏忽導致的對您產生可預計之損失或損壞承擔責任，但對任何不可預見損失或損壞，My Room Abroad不承擔任何責任。若損失或損壞是由於我公司違反服務條款而造成的明顯結果或您與My Room Abroad雙方在締結本合約時考慮到的，則為可預見損失或損壞。My Room Abroad公司服務供國內及私人使用。My Room Abroad對任何利潤損失、業務損失、業務干擾或商業機會損失不承擔任何責任。",
    t214: "對我公司疏忽造成的人員傷亡，或對我方的欺詐或欺詐性虛偽陳述，My Room Abroad不尋求推卸責任。",
    t215: "你可能會使用或訪問第三方（包括但不僅限於住宿提供方）的服務、推廣及網站。在使用或訪問第三方服務、推廣及網站時，您同意遵守該第三方對其服務、推廣及網站管轄的服務條款，並特此承諾我公司對第三方所有或運營的服務的提供、推廣或網站內容的準確性不承擔任何責任。",
    t216: "My Room Abroad公司會不時推出推廣或鼓勵措施，以優惠公司網站訪問者、潛在消費者及消費者。My Room Abroad保留在任何時間修改或取消該類推廣活動，及取消此活動下應該支付但人們還未收取部分或全部之福利或金錢付款之權利。請查看公司有關該類推廣活動之常用問題解答，瞭解更多信息。",
    t217: "您瞭解並同意My Room Abroad對任何使用者在公司網站上發佈之信心準確性不承擔任何責任，且My Room Abroad公司不擔保網站上信息之準確性、真實性或完整性。My Room Abroad特此聲明，對因公司網站上出現的不準確信息對您或任何第三方造成的損失、傷害、不幸或損壞，公司不承擔任何責任。",
    t218: "若任何主管當局認為本部分任何內容不可執行，則承擔責任範圍不超出適用法律的最大允許限度。",
    title22: "賠償",
    t221: "若您為商業實體，您同意讓My Room Abroad公司、其相關公司、及其所有相關股東、董事、高級職員、普通員工、代理人、商業夥伴及專業附屬企業不受或免受任何第三方的索賠或法律起訴，包含因您使用服務和/或網站或您違反任何法律或第三方權利直接或間接導致的合理的律師費、訴訟費用。",
    title23: "爭議",
    t231: "您同意本服務條款及應本使用條款或其主要內容或組成（包含非合約爭議或索賠）、公司服務、公司網站、My Room Abroad公司廣告或您與My Room Abroad直接任何相關交易引起的或相關的索賠、爭議或爭論，應由中華民國法律管轄並受其解釋。",
    t232: "任何因上述原因引起的或相關的爭議或索賠（包含非合約爭議或索賠）受中華民國法院專屬管轄。",
    t233: "如果你是一個居住在中華民國外的消費者，也將獲得同等的法律保護。",
    title24: "終止",
    t241: "My Room Abroad可於任何時間變更或中斷公司服務和/或網站，且無需提前通知。公司保留以任何原因終止本服務條款的權利，並無需通知。若您違反此處所列明的服務條款中的任何內容，服務條款將自動終止（不會對您的累計權利造成侵害）。若發生終止情況，您將立即被停止使用公司服務與公司網站。",
    title25: "一般條款",
    t251: "本服務條款由您與我公司雙方同意。任何你與我們之外的第三方不享受此服務條款或與之相關的任何權利。",
    t252: "若任何法院或主管當局認定本服務條款中任何條例無效、不合法或不可實施，該條例應出於此原因與其他剩餘條例分離，剩餘部分依照法律在最大許可範圍繼續有效。",
    t253: "標題僅用於參考，不定義、限制、解釋或描述該部分的範圍與延展。",
    t254: "我公司未執行本服務條款相關規定不得視為放棄該規定或其他規定，也不構成您可以不遵守該規定。",
    t255: "無事先書面同意，您不得以任何形式分配、轉讓、收取、分包或交易您在本服務條款下全部或任何權利。",
    t256: "本服務條款及隱私政策及Cookie政策構成您與My Room Abroad公司之間完整協議，若主題相同，則代替或取消您此前與我公司之間訂立的任何書面或非書面協議、承諾、保證、擔保、交涉及非正式協議。",
    title26: "聯繫我們",
    t261: "出國租股份有限公司 (My Room Abroad)為在台灣註冊的有限責任公司。My Room Abroad公司增值稅稅號：50895192 。",
    t262: "地址:",
    t263: "出國租股份有限公司",
    t264: "臺北市大同區南京西路163號2樓之39",
    t265: "",
    t266: "",
    t267: "Email: hello@myroomabroad.com"
  },
  llFaq:{
      mainTitle:"FAQ",
      title1:"一般問題",
      st11:"My Room Abroad是什麼？",
      t111:"一個提供外籍人士在台灣找房的租屋平台。",
      t112:"這個平台使得國際租客尋找房間更為簡單又安全，除了提供多種語言的租房資訊，也幫助學生過濾不良房東，因此我們平台的物件都來自接受外國租客的友善房東。",
      t113:"讓國際租客能提早好幾個月在自己的國家就預定到國外喜歡的房間。",
      l114:"了解更多",
      st12:"房東為什麼要使用My Room Abroad？",
      t121:"1. 增加曝光度：My Room Abroad在國外擁有極高的瀏覽量，並已累積一定的名氣，因此在My Room Abroad刊登將有助於您的物件出租給外國租客。",
      t122:"2. 省去不必要問答：My Room Abroad作為房東與租客的中間橋樑，代替房東與租客溝通，並回答租客一切問題，使得房東無需回答租客瑣碎問題。",
      t123:"3. 房東後台系統：平台提供房東所有租客的清晰資料庫，房東可以在網站上輕易暸解租客的基本資料。",
      t124:"4. 線上支付系統：房東不必每月親自向租客收房租，因為一切租金都是通過平台支付的。",
      t125:"5. 省去看房流程：因為大部分租客都是在國外提早預訂房間，因此他們不需要到現場查看，房東也不需要冒著“白跑一趟”的風險，這使房東有更多的時間來照顧租客和維護公寓，從而提供更好的服務。",
      st13:"驗證等級的差別？",
      t131:"My Room Abroad對所有物件有四種不同的驗證級別：",
      l132:"尚未認證: 我們仍需認證這位房東，目前尚未能對此房東作出評價。",
      l133:"認證房東: My Room Abroad已認證這位房東，這代表My Room Abroad成員有透過電話或當面向房東暸解房東與房間情況。",
      l134:"到訪房間: My Room Abroad成員親自到訪認證，並確認其為適合學生居住的房間。",
      l135:"合作房東：合作房東為已與My Room Abroad有長期合作，並為My Room Abroad所信任。",
      t136:"",
      st14:"我怎樣才能得到驗證？",
      t141:"在您的個人資料中，您會看到“獲得驗證”，如果您點擊它，我們會通知您要進行驗證。",
      t142:"在要求驗證之前，請務必填寫您的聯繫信息。我們將使用此信息與您取得聯繫。",

      title2:"國際租客溝通問題",
      st21:"如果我與租客有租賃上的問題怎麼辦？",
      t211:"My Room Abroad可以幫助你。我們有經驗豐富的團隊，幫助您解決租賃可能遇到的任何問題。",
      st22:"我不會說英語，我該如何與租客溝通？",
      t221:"對於任何瑣碎的問題，Google翻譯是非常準確且足夠的喔！",
      t222:"對於比較重要的問題，My Room Abroad團隊將會有精通中英文的成員幫助您解決任何翻譯問題。",
      title3:"付款問題",
      st31:"我甚麼時候能拿到我的錢？",
      t311:"我們會依據您的每月收租習慣而設定您收到錢的日期，確保您有良好的體驗。我們會在您的收租日前五天就跟房客催租，並使用信用卡系統來收房客匯給我們的錢。",
      t312:"接著我們會在以下其中一個日期將租金轉給您：每個月的15號或是每個月的最後一天，依據您的需求做選擇。例如，若您的習慣是在每個月的5號收到房租，那您將會在前一個月的最後一天就從我們這裡收到房租。(例如您希望在8月5號收到當月租金，那我們會在7月31就匯給您)。又例如，您的習慣是每個月的20號收到房租，那您將會在當月15號就提前收到。(例如您希望在8月20號收到房租，那我們會在8月15號就匯給您)。換句話說，您總是可以在您設定的截止期限前就收到來自我們轉給您的房租，在每個月的15號或是每個月的最後一天。",
      t313:"此外，我們會透過台灣的銀行帳號轉帳給您，因此您將會在當天(或是最多一天工作天)就收到來自我們的匯款。您將會在每月15號或是每月最後一天就即時收到房租。",
      t314:"若想了解更多關於匯款相關事項，歡迎與我們聯繫:hello@myroomabroad.com，將有專人為您服務。",
      st32:"如果我沒有國際貨幣帳戶怎麼辦？",
      t321:"沒關係，My Room Abroad將以房東使用的貨幣將租金和押金匯入。",
      st33:"我應該向My Room Abroad支付多少錢？",
      t331:"My Room Abroad每月將收取月租金3%作為佣金。",
      t332:"例如，對於月租金＄12000的房間。如果My Room Abroad用戶預訂了5個月，您可以：每月分期支付：每月由My Room Abroad在匯款給房東時扣除＄360（12000 * 3％），並連續5個月。一次性支付：在合約開始時立即支付＄12000 * 3％* 5 = ＄1800。",
      title4:"取消訂房問題",
      st41:"房東可以取消已接受的預訂嗎？",
      t411:"可以！但在這種情況下，房東必須向租客退還所有款項。包含：1. 全額訂金（首月租金）2. 他們為預訂房間而支付My Room Abroad的一次性服務費（20％的租金）。",
      st42:"如果租客在抵達前取消預訂怎麼辦？",
      t421:"這取決於您選擇的取消政策。",
      t422:"我們提供不同的取消政策:(請注意，如需更詳細的取消政策，請參閱服務條款第12條：取消）",
      tu423:" 取消政策：寬鬆",
      t424:"如果用戶取消預訂的日期在：",
      li4241:"合約開始日期前不到15天，第一筆租金將不予退款（預訂將被取消）;",
      li4242:"合約開始日期前15天至30天之間，您必須退還第一筆租金的50％（並且預訂將被取消）",
      li4243:"合約開始日期前30天以上，您必須退還第一筆租金的100％（並且預訂將被取消）。",
      tu425:"取消政策：適度",
      t426:"如果用戶取消預訂的日期在：",
      li4261:"合約開始日期前不到30天，第一筆租將不予退款（預訂將被取消）;",
      li4262:"合約開始日期前30天至60天之間，您必須退還第一筆租金的50％（並且預訂將被取消）;",
      li4263:"合約開始日期前60天以上，您必須退還第一筆租金的100％（並且預訂將被取消）。",
      tu427:"取消政策：嚴格",
      t428:"如果用戶取消預訂的日期在：",
      li4281:"合約開始日期前不到30天，第一筆租將不予退款（預訂將被取消）;",
      li4282:"合約開始日期前30天至90天之間，您必須退還第一筆租金的50％（並且預訂將被取消）;",
      li4283:"合約開始日期前90天以上，您必須退還第一筆租金的100％（並且預訂將被取消）。",
      tu429:"取消政策：不退款: ",
      t4210:"無論租客在何時取消預訂，訂金（首月租金）都將不予退還。",
      st43:"如果到達公寓時，房客不喜歡並想取消預訂怎麼辦？",
      t431:"租客不想搬進房間的原因將決定房東是否需要補償他。",
      t432:"簡而言之，當搬進住宿時，租客有24小時通知My Room Abroad，投訴房間的刊登廣告不實。",
      t433:"然後My Room Abroad將評估投訴是否有效。",
      t434:"例如，如果房客不喜歡房間或者發現它太吵雜或比他想像的要小，但是房間內容與網站上的廣告都屬實，那麼這些投訴就不被視為有效投訴。",
      t435:"但是，如果確實是廣告不實，例如廣告宣稱房間有窗戶，但實際上沒有窗戶，或者說房間裡有一張雙人床，但實際上是單人床，這種就會被My Room Abroad視為是有效的投訴。",
      t436:"一旦My Room Abroad視為是有效的投訴，您必須退還租客訂金（首月租金）以及My Room Abroad服務費。請注意！房東有責任確保廣告的準確。",
      st44:"如果租客在合約結束前離開怎麼辦？",
      t441:"您將按照您和租客簽署的租賃協議處理。",
      t442:"如果租客未成功找到下位租客承接合約，導致房間是空的，My Room Abroad將不會繼續向房東收取任何費用。",
      t443:"",
  },
  cookies:{
      cookies:"Cookies",
      title1:"Cookies和網絡信標的使用",
      t11:"我們的網站使用cookies來區分您與我們網站的其他用戶。 Cookies是一種小型文本文件，當您訪問該網站時，網站會將該文件保存在您的電腦或移動裝置上。它使網站能夠記住您在一段時間內的操作和偏好（例如登錄，語言，字體大小和其他偏好），因此您無需在返回網站或是瀏覽不同頁面的時候再次輸入它們。",
      t12:"Cookies收集的數據是匿名的。您可以通過搜索包含「cookies」的文件並刪除它們來刪除硬體裝置上已有的所有Cookies。此外，如果要阻止cookies存儲在您的裝置上，可以編輯瀏覽器設定以阻擋cookies。然而，若您阻擋cookies，您可能無法完整地使用本網站的功能。有關cookies的更多信息，請前往：www.allaboutcookies.org。",
      t13:"當您訪問My Room Abroad時，cookies能夠識別您的身份、記住您的偏好，並根據您的設定為您提供量身定制的體驗。 Cookie還可以讓您與My Room Abroad的互動更快、更安全。最重要的是，cookies使我們能夠為您帶來My Room Abroad以外的廣告。",
      t131:"我們將cookie用於以下目的：",
      l131:"身份驗證：如果您已登錄My Room Abroad或是返回網站的用戶，Cookies會幫助我們向您顯示正確的訊息並提供與之前設定相同的體驗。",
      l132:"偏好、功能與服務：Cookies可以告訴我們您偏好哪種語言以及您的通訊偏好。他們可以幫助預先填寫My Room Abroad上的任何表格。",
      l133:"廣告：我們可能會使用cookies向您展示My Room Abroad以外的廣告。",
      l134:"績效、分析與研究：Cookie幫助我們了解我們的網站在不同地區的表現。我們還使用cookies來理解、改進和研究產品、功能和服務。 My Room Abroad需要使用到cookies的服務包括：Cloudinary、Google Analytics、My Room Abroad自有軟體、OneSignal、SmartLook與TapPay。",
      t14:"我們還使用網絡信標，它是由一小段軟體代碼所組成，代表頁面或電子郵件上的圖形圖像請求。可能存在或可能不存在與web信標相關聯的可見圖形圖像，並且通常將圖像設計成混合到頁面或電子郵件的背景中。網站信標可用於多種用途：包括網站流量報告、個別訪客數量、廣告審核和報告以及個性化。本網站使用的網站信標僅收集匿名數據。",
      t15:"為了改進我們為您提供的網站和服務，我們可能會暫時或持續地使用cookies、授權第三方使用cookies、網絡信標或其他技術來收集非個人身份識別數據。這些非個人身份識別數據有助於我們跟踪瀏覽行為、創建特定或定制的優惠或廣告，以及監控和記錄網站的訪問和使用。",
      t16:"為了了解人們如何使用本網站以及營銷分析和改進平台的服務，我們（或第三方服務提供商）可以匿名收集、記錄、處理和使用某些訊息（包括交易總數）、查看頁面、引用/退出頁面、平台類型、日期/時間戳訊息與詳細訊息，例如游標在特定頁面上的點擊次數和位置、游標的移動、滾動活動以及您在打開和使用網站時使用的搜索詞。",
      t17:"如果您繼續使用本網站，即表示您同意我們cookies的使用。",
  },
  tenantFaq:{
    tOrl:"請問您是房客還是房東呢?",
    faqT:"房客的常見問題",
    faqLL:"房東的常見問題",
    mainTitle:"房客幫助中心",
    title1:"關於MRA",
    s11:"My Room Abroad是甚麼?",
    t111:"一個連結全球房客與夢想地的認證房東的平台，透過平台可以大幅降低在國外尋找房間的難度，並且可以在入住前便提早訂下1-12個月的租房",
    l111:"瞭解更多",
    s12:"甚麼是夢想地?",
    t121:"夢想地指的是對於西方人而言，他們在異國的家，那個一旦他們離開後還會想再回去的地方。亞洲以及南美洲通常是他們的夢想地。",
    s13:"認證等級分為哪些?",
    t131:"My Room Arbaod有四種認證等級:",
    l132:"1.尚未驗證：這意味著我們尚未確認房東的個人信息。我們沒有電話聯繫或是拜訪過這個房東。我們不能保證他的可信度。",
    l133:"2.已驗證：這意味著我們已經驗證了房東的電子郵件地址，電話號碼和政府簽發的身份證明。我們已經打電話給他們或親自見過他們，我們認為他們值得信賴",
    l134:"3.參觀：這表示My Room Abroad團隊的人親自參觀並拍照/拍攝了公寓的影片；",
    l135:"4.獨家：這意味著該房間由My Room Abroad管理，並且僅在我們的平台上租得到",
    t136:"您可以在每個房間的頁面找到該房間的認證等級",
    s14:"我可以在訂房前看房嗎?",
    t141:"一般來說，在租約到期時，房東會優先給予現在的房客承租權。您必須提前告知房東您想要延長租約，而相關細節您可以跟房東一起討論。大部分房東會要求房客在合約結束前一個月提早告知是否續約，有些房東則會要求兩周前提早告知。這部分您可以直接聯繫房東做詢問，也歡迎聯絡My Room Abroad的成員諮詢。",
    title2:"在My Room Abroad訂房",
    s21:"我可以把剛訂房的房間換成另一間嗎?",
    t211:"一旦送出訂房請求後，您將無法再訂別間，因此也無法更換。不過若房東還沒接受您的訂房請求，那麼您可以取消請求，然後再重新對您想換的那間送出訂房請求。",
    s22:"我必須是一個學生才能在My Room Abroad上訂房嗎?",
    t221:"您不一定要是學生！即使您不是學生，也可以通過“My Room Abroad”預訂房間！要提醒您的是，由於我們的主要目標客群是前往夢想地的國際學生，因此網站是針對他們的需求（離大學很近、低廉的租金等）量身定制的，但是任何想在台灣租房1至12個月的人都可以在My Room Abroad訂房。",
    s23:"我應該跟誰簽約呢?",
    t231:"房客需要跟房東於入住當天簽約",
    s24:"平台對我來說有甚麼幫助?",
    t241:"透過平台，您可以提前幾天甚至提前幾個月就定到您的理想房間，以避免即將搬遷時才發現當下沒有適合的房間在招租。此外，平台上的許多房間支持一個月到半年的短租期，對於要待在台北實習/學習/工作一段時間的人們來說十分有幫助。並且，My Room Abroad的團隊成員皆擁有豐富的與房東協商的經驗，因此當您有租房相關問題時，我們很擅長協助您與房東溝通/協商，替您爭取到最好的權益。",
    title3:"費用和付款",
    s31:"我應該向誰支付租金，押金和其他費用？",
    t311:"所有付款都將通過My Room Abroad進行，除非房東或房客有提前告知My Room Abroad並且獲得My Room Abroad同意. My Room Abroad扮演房客和房東之間的中介機構. 無論房客是用信用卡或是現金轉帳，我們都會以當地的貨幣轉帳給房東",
    s32:"我該如何支付房租，押金和其他租金？",
    t321:"目前您能透過這兩種方式支付押金、租金以及服務費:",
    l3221:"信用卡線上刷卡",
    l3222:"若沒有銀行卡，您可以在銀行支付現金，請行員協助將現金轉帳到My Room Abroad的銀行帳戶",
    l3223:"透過銀行轉帳到My Room Abroad的銀行帳戶",
    s33:"到時候誰會把押金還給我？",
    t331:"無論您是否透過My Room Abroad支付押金，您的押金最終都將在房東手中。因此，當您搬出時，將由房東退還押金給您。請注意，完整退還押金的前提是房間並沒有損壞，並且您有遵照契約上的規範進行租賃行為。",
    s34:"我甚麼時候會收到押金退還?",
    t341:"在您退房時、房東確認房間無損壞後，將會退還押金給您。若您希望透過信用卡收到押金退款，前提是您必須在過去60天內有透過信用卡在MRA平台上支付房租等費用並累積至押金金額。請提前跟您的房東確認，並且也跟MRA成員確認您是可以透過信用卡收到押金退款的。",
    s35:"一次性服務費是甚麼?",
    t351:"一次性服務費是我們收取以作為平台營運的費用，這包含我們根據您的需求推薦房間的服務，以及我們協助您和房東溝通、協商直到退房的服務。相較於傳統仲介收取的半個月至一個月的費用，My Room Abroad的費用不但更便宜並且還服務更周到。",
    title4:"在我居住期間",
    s41:"我的朋友可以來拜訪我嗎?",
    t411:"每個房間關於訪客的規定不一樣，大部分房東是可以接受訪客偶爾拜訪的。具體規範請參照房間頁面中的'合約資訊'中的'房客規範'，裡面會提到是否允許訪客留宿。若希望在訂房前能事先跟房東確認，也歡迎透過臉書粉專或是hello@myroomabroad.com聯絡我們，讓我們協助您確認。",
    s42:"如果我跟房東有糾紛的話，你們會協助我溝通嗎?",
    t421:"我們當然會作為公正的第三方提供你建議並且協助你!  不過若涉及到後續房客跟房東的法律糾紛，My Room Abroad並不會涉入(畢竟我們不是相關專業的)，不過這部分也幾乎不會發生，因為我們只跟值得信任的房東合作。除了這部分以外的日常溝通、協商、談判等，我們都很樂意幫忙。",
    title5:"取消政策",
    s51:"我能夠取消訂房嗎?",
    t511:"只要房東尚未接受您的預訂，您便可以隨時取消訂房。若要取消，請在您的預訂請求中點擊“取消訂房”按鈕。但是，一旦房東接受了您的訂房，您就無法在沒有通知房東的情況下取消訂房。取消政策適用於每個公寓/房東。因此，您都可以在房間的頁面中看見。若不清楚您的房東的取消政策，歡迎向MRA的夥伴們諮詢有關房東取消政策的更多信息。",
    s52:"如果房間與平台上的廣告不符怎麼辦？",
    t521:"如果My Room Abroad網站上的房間與房間實際情況存在差異，則從您的入住日期開始，您有24小時可以通報My Room Abroad. MRA 團隊成員將盡快評估您的投訴。 如果投訴有效，您將獲得退款，並且My Room Abroad將幫助您找到另一個房間（不收取服務費）。 ",
    s53:"如果我的預訂被取消怎麼辦？",
    t531:"一旦您提出了訂房請求，房東是有權利接受或是拒絕請求的(拒絕原因可能是因為您不符合房東的預期房客條件，或是房間前幾天剛好出租了)。在這樣的情況下，您將可以再對另一個房間重新申請訂房。在某些不常見的情況下，房東有可能會在接受您的訂房後，由於一些不可預期的事情而取消您的預定。若房東在接受您的訂房後決定取消，房東和My Room Abroad將會盡快通知您，並且您支付的所有款項將會在幾個工作天之後退款。考量到您將會需要找另一個房間居住，我們會推薦另一個適合的房間給您，我們將會根據您原本訂的房間條件做推測，盡力幫您找到您喜歡的房間。",
    title6:"入住後",
    s61:"如果我的合約租期比我實際居住的期間長怎麼辦?",
    t611:"有些學生雖然只會住4.5個月，但是大部分的租約都會要求至少五個月的租期。您可以在租約到期前將房間保持空置，不過只有在您支付租約期間的所有租金後，您才有機會收回所有的押金。若租約將大幅超過您實際停留的期間(例如您簽一年約但是實際上只待五個月)，那麼您有兩個選擇: 您可以選擇提早解約，不過如此一來房東將有權利沒收您的押金，因此我們並不推薦這麼做! 大部分的房東可以讓房客自行尋找下一個繼任的房客來接住這間房間(請提前跟房東確認尋找繼任房客的規範(轉租規範)再簽下合約)，屆時下一個房客將與房東簽下您剩下的租期的合約。My Room Abroad可以協助您將房間在網站上開發，協助尋找下一個繼任房客。",
    s62:"若我想延長我的租約可以怎麼做呢?",
    t621:"一般來說，在租約到期時，房東會優先給予現在的房客承租權。您必須提前告知房東您想要延長租約，而相關細節您可以跟房東一起討論。大部分房東會要求房客在合約結束前一個月提早告知是否續約，有些房東則會要求兩周前提早告知。這部分您可以直接聯繫房東做詢問，也歡迎聯絡My Room Abroad的成員諮詢。",
  },

  areas:{
    title:"The district",
    ratings:"Ratings",
    dyn:"Dynamism",
    price:"Price",
    comm:"Nice to live",
    mrt:"MRT stations in the area",
    description:"Description",

  }
}
