<template>
<div class="toolbar mainNav">
  <v-toolbar dense :class="navbarClass">
   <v-toolbar-side-icon class="hidden-sm-and-up icon"  @click="open=!open"></v-toolbar-side-icon>
   <v-spacer class="hidden-sm-and-up"></v-spacer>
    <div>
      <router-link to="/">
        <img src="@/assets/mainLogoWhite.svg" class="navbar-logo" alt="My Room Abroad" v-if="navbarClass=='transparent'">
        <img src="@/assets/mainLogo.svg" class="navbar-logo" alt="My Room Abroad" v-else>
      </router-link>
    </div>
    <v-spacer></v-spacer>

    <v-toolbar-items class="hidden-xs-only">
      <!-- language Selector -->
      <v-select
      :items="langs"
      append-icon="translate"
      v-model="$i18n.locale"
      class="lang-select mr-2"
      :class="navbarClass"
      flat
      @change="setLanguage"
      >
        <template
             slot="selection"
             slot-scope="{ item, index }"
           >
            <span :class="navbarClass " class="text-xs-right">{{ item.text }}</span>
        </template>
      </v-select>
      <!-- if un-authed -->

        <v-btn color="primary" class="ma-1" href='/signup' v-if="!auth">
          {{$t('navbar.signup')}}
        </v-btn>
        <v-btn flat :color="navbarClass=='transparent' ? 'white' : 'primary'" class="ma-1" href='/login' v-if="!auth">
          {{$t('navbar.login')}}
        </v-btn>
        <!--  if authed  -->
        <!-- <router-link class="md-xsmall-hide toolbar-link" :href="'/admin'" v-if="auth"> -->
        <v-btn color="#ff4000" dark class="ma-1" href="/affiliateEvents" v-if="user.evCode!=undefined && user.evCode!=''">Affiliate</v-btn>
          <v-btn  class="ma-1 primary" href='/admin'  v-if="user.roleValue>79 && auth">
            Admin
          </v-btn>
        <!-- </router-link> -->
        <v-btn color="#ff4000" dark class="ma-1" href="/events" v-if="user.hasTicket || user.roleValue>79">Events</v-btn>
        <v-btn color="primary" dark class="ma-1" href="/addListing" v-if="user.roleValue>=20">{{$t('navbar.newListing')}}</v-btn>
        <v-btn color="primary" dark class="ma-1" href="/affiliate" v-if="user.roleValue==15">Affiliate</v-btn>
        <v-menu offset-y v-if="auth" class="menu">
          <v-btn
          slot="activator"
          :color="navbarClass=='transparent' ? 'white' : 'primary'"
          flat
          class="ma-1"
          >
          <v-badge right color="red" v-model="showBadge">
            <span slot="badge">{{user.tbp}}</span> <!--slot can be any component-->
            {{$t('navbar.hi')}}{{user.userName}}
            <v-icon>arrow_drop_down</v-icon>
        </v-badge>
          </v-btn>
          <v-list>
            <v-list-tile href='/admin' v-if="user.roleValue>79" class="no-show-large">
              <v-list-tile-title>admin</v-list-tile-title>
            </v-list-tile>

            <v-list-tile href='/tenants' v-if="user.roleValue>11" class="no-show-large">
              <v-list-tile-title>{{$t('navbar.myTenants')}}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/addListing' v-if="user.roleValue>11" class="no-show-large">
              <v-list-tile-title>{{$t('navbar.newListing')}}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile :href="'/'+userRequestPresent" v-if="userRequestPresent!=null" >
                <v-list-tile-title>{{$t('navbar.myBooking')}}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/visits' v-if="user.visits!=undefined" >
                <v-list-tile-title>My Visits</v-list-tile-title>
            </v-list-tile>

            <v-list-tile href='/requestsInbox' v-if="user.roleValue>11">
              <v-list-tile-title >
                <span :class="{ 'new-req':user.tbp>0}">{{$t('navbar.incomingReqs')}} <span v-if="user.tbp>0">({{user.tbp}})</span></span>
              </v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/Dashboard' v-if="user.roleValue>11">
              <v-list-tile-title >{{$t('navbar.myListings')}}</v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/favorites' v-if="user.favs!=undefined">
              <v-list-tile-title >My Favorites <v-icon color='red'>favorite</v-icon> </v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/payouts' v-if="user.roleValue>11">
              <v-list-tile-title >My Payments</v-list-tile-title>
            </v-list-tile>

            <v-list-tile href="/profile">
                <v-list-tile-title >{{$t('navbar.profile')}}</v-list-tile-title>
            </v-list-tile>

            <v-list-tile href='/myTickets' v-if="user.hasTicket">
                <v-list-tile-title >My Tickets & Events</v-list-tile-title>
            </v-list-tile>
            <v-list-tile href='/myInvoices' v-if="user.hasInvoice">
                <v-list-tile-title >My Invoices</v-list-tile-title>
            </v-list-tile>
            <v-list-tile @click="logout" color="error">
              <v-list-tile-title >{{$t('navbar.logout')}}</v-list-tile-title>
            </v-list-tile>
          </v-list>
        </v-menu>
    </v-toolbar-items>

  </v-toolbar>
  <Drawer :auth="auth" @changeOpen="open=$event" :userRequestPresent="userRequestPresent" :user="user" :open="open" class="hidden-sm-and-up"></Drawer>
</div>
</template>
<script>
import {
  mapGetters,
  mapActions
} from 'vuex'
import * as types from '../../store/types'
import Drawer from './Drawer.vue'
import moment from 'moment'



export default {
  name: "",
  props: {
    navbarClass: {
      type: String,
      default: 'navbar',
    },
    variant: {
      type: String,
      default: 'light'
    },
    type: {
      type: String,
      default: 'light'
    },
    logo: {
      type: String,
      default: 'navbar-logo.png'
    }

  },
  data: () => ({
    open:false,
    avatarLetter: "",
    langs:[
      {
        text:"EN",
        value:"en",
      },
      {
        text:'中文',
        value:"zh",
      },
      {
      //   text:'한국어',
      //   value:'ko',
      // },{
        text:"日本語",
        value:'ja'
      },
      {
        text:'FR',
        value:"fr"
      },
      // {
      //   text:'ES',
      //   value:"es",
      // },
      // {
      //   text:'IT',
      //   value:"it",
      // },
      {
        text:'NL',
        value:"nl",
      },
      // {
      //   text:'DE',
      //   value:"de",
      // },


    ]
  }),

  methods: {
    ...mapActions({
      logOutAction: types.LOG_OUT
    }),
    setLanguage(payload){
      console.log(payload,'payload');
      if(payload==""){
        // console.log('yes');
        payload='en'
        this.$i18n.locale='en'
      }
      // console.log("set langu",payload);
      let lang=""
      switch (payload) {
        case 'zh':
          lang='zh-tw'
          break;
        default:
          lang=payload
      }
      moment.locale(lang)
      this.setCookie('lang', payload, 90)
      this.$vuetify.lang.current = payload
      // console.log(moment.locale())
    },
    setCookie(cname, cvalue, exdays) {
      let d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      let expires = "expires=" + d.toUTCString()
      document.cookie = cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/"
    },

    logout() {
      try {
        this.$Tawk.$endChat()
      } catch (e) {
        console.log(e);
      }
      this.logOutAction()
    },
  },
  computed: {
    ...mapGetters({
      auth: types.IS_LOGGED,
      user: types.GET_USER,
    }),
    showBadge: function(){
       if(this.user.tbp>0){
         // console.log(' tbp: ',this.user.tbp);
         return true
       } else {
         // console.log(' tbp: ',this.user.tbp);
         return false
       }
    },
    userRequestPresent: function() {
      if (this.user.bookingRequestOut && this.user.bookingRequestOut != "") {
        if (this.user.orderPaid){
          return 'myBooking'
        } else {
          return 'userRequest'
        }
      } else {
        return null
      }
    }
  },
  watch: {
    user: function() {
      if (this.user && this.user.userName){
        this.avatarLetter = this.user.userName[0].toUpperCase()
        let tUser={
          name: this.user.userName,
          email: this.user.email,
          emailHmac: '' // see more https://www.tawk.to/javascript-api/#securemode
        }
        if (this.user.name!=undefined){
          tUser.name=this.user.name
        }
        if (this.user.bookingRequestOut!=undefined && this.user.orderPaid!=undefined){
          tUser.name=tUser.name + ' (Has CR)'
        } else if (this.user.bookingRequestOut!=undefined) {
          tUser.name=tUser.name + ' (Has BR but not confirmed)'
        } else {
          tUser.name=tUser.name + ' (Has No BR)'
        }
        console.log('send', tUser);
        console.log(window.Tawk_API);
        window.Tawk_API.setAttributes({
          'name': tUser.name,
          'email': tUser.email,
          'hash': tUser.emailHmac
        }, function (error) {
          console.log(error)
        })
      }

    }
  },
  components:{
    Drawer
  }
}
</script>
<style lang="scss" scoped>
.new-req{
  font-weight: bold;
}
.mainNav{

  .v-toolbar{
    padding: 10px 0;
    .menu{
      margin-right: 10px;
      .v-menu__activator > button{
        padding-right: 0;
      }
    }
  }

  img.navbar-logo {
      height: 30px;
  }
  .toolbar-link {
      padding: 0 25px;
  }
  .NavBarFormInput {
      padding-left: 20px;
  }
  .navbar {
      background-color: #ffffff;
  }
  .transparent a {
      color: #ffffff;
  }
  .transparent {
      color: #ffffff !important;
      border-color: white;
      box-shadow: none;
      .v-btn__content{
        color: #ffffff;
      }
      .v-btn--outline{
        border-color: #ffffff;
      }

  }
  .letter {
      margin-top: 35%;
      margin-bottom: auto;
  }
  .avatar-icon {
      color: rgba(255,255,255,1);
      background-color: rgba(0,0,0,.6);
  }
  .not-capsed {
      text-transform: capitalize;
  }
  .transparent .icon{
    color:#ffffff;
  }
  .lang-select{
    width:4.8em;
    border-style:none !important;
    border-width: 0px;
    .v-select__selection{
      direction: rtl;
      text-align: right;
    }
    .transparent{
      color:#ffffff;
      .v-select__selection{
        color:#ffffff;
      }
    }
  }

  @media only screen and (min-width: 600px) {
      .no-show-large {
          display: none;
      }
  }
}
</style>
